import React, {useState} from 'react';
import CardContainer from '../components/sabadKala/CardContainer';
import Navigation from '../components/share/Navigation';

const SabadeKala = (props) => {
  return (
    <div>
      <CardContainer />
    </div>
  );
};

export default SabadeKala;

import React from "react";
import Navigation from "../share/Navigation";
import ShowCase from "../share/ShowCase";
import UseWindowSize from "../../Sizes/UseWindowSize";
import PhoneNavigation from "../share/PhoneNavigation";
import "../../css/main.scss";
import HomeshowcaseImage from "../../img/home-first-vector.svg";
import ShowCaseItem from "./ShowCaseItem";

const HomeHeader = () => {
  const windowSize = UseWindowSize();
  const showCaseTextList = [
    { text: "ویدئو های فارسی", id: 1 },
    { text: "ادیتور انلاین", id: 2 },
    { text: "دروس پروژه محور", id: 3 },
    { text: "ارزشیابی هوشمند", id: 4 },
  ];
  return (
    <header className="header" id="header">
      <div className="container">

        <ShowCase
          titleFirstSpan="آموزش تعاملی"
          titleSecondSpan="آنلاین برنامه نویسی"
          homeShowcaseImage={HomeshowcaseImage}
        >
          <ul className=" items">
            {showCaseTextList.map((text) => (
              <ShowCaseItem key={text.id} text={text.text} className="svg" />
            ))}
          </ul>
        </ShowCase>
      </div>
      <div className="arrow__down">
      <i class="fas fa-arrow-down"></i>
      </div>
    </header>
  );
};
export default HomeHeader;

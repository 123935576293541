import React, { useState, useContext } from "react";
import { BtnSecondary } from "../share/Btn";
import { useForm } from "react-hook-form";
import logo from "../../img/logo web.svg";
import userIcon from "../../img/signlogform/user.svg";
import password from "../../img/signlogform/Icon ionic-ios-lock.svg";
import ShowHidePass from "../hooks/ShowHidePass";
import "../../css/main.scss";
import emailIcon from "../../img/signlogform/Icon material-email.svg";
import { useAuth } from "../../context/AuthContext";
import { useUser } from "../../context/UserContext";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import ForgetPass from "./ForgetPass";
import Cookies from "js-cookie";
import { GetUserInfoContext } from "../../context/GetUserInfoContext";

/**
 * return login to page
 * @param   {integer} username
 * @param   {integer} password
 *
 * @return  login to page
 *
 */
const LoginForm = (props) => {
  const [formVal, setformVal] = useState({
    username: "",
    password: "",
  });
  let history = useHistory();
  const { authDispatch, token, authrefreshDispatch, refreshtoken } = useAuth();
  const { userDispatch } = useUser();
  const [forget, setForget] = useState(false);
  const [signininfo, setSigninfo] = useState();
  let [loading, setLoading] = useState(false);
  const { userinfo, getUserInfo } = useContext(GetUserInfoContext);

  const forgotEmail = () => {
    setForget(true);
  };
  const backToLogin = () => {
    setForget(false);
  };
  const loginNotify = () => {
    toast.error("شما هنوز ثبت نام نکرده اید", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const passErrorNotify = () => {
    toast.error("  پسورد اشتباه است   ", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const erroremailverify = () => {
    toast.error("ایمیل تایید نشده است", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  const [passwordShown, togglePasswordVisiblity] = ShowHidePass(false);
  let test = `http://localhost:3000`;
  const loginpage = useAxios({
    url: `${API_URL}/login/`,
    method: "POST",
    options: {
      data: signininfo,
    },
    // trigger: resources,
    customHandler: (err, res) => {
      if (res) {
        console.log(res);
        authDispatch({ type: "LOGIN", token: res.data.access_token });
        authrefreshDispatch({
          type: "LOGIN",
          refreshtoken: res.data.refresh_token,
        });
        Cookies.set("token", res.data.access_token);
        history.push("/");
        setLoading(false);
        // getUserInfo.reFetch();
      }
      if (err) {
        if (
          err.response.data.non_field_errors.includes(
            "There is no user with that username"
          )
        ) {
          setLoading(false);

          loginNotify();
        }
        if (err.response.data.non_field_errors.includes("Wrong Password")) {
          setLoading(false);

          passErrorNotify();
        } 
        if (err.response.data.non_field_errors.includes("E-mail is not verified.")) {
          setLoading(false);

          erroremailverify();
        } else {
          console.log("error", err.response);
          setLoading(false);

        }
      }
    },
  });
  //non_field_errors: ["E-mail is not verified."]
  //res.data.access_token
  const onSubmit = (info) => {
    setLoading(true);
    setSigninfo(info);
    loginpage.reFetch();
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const handleChange = (e) => {
    setformVal({ [e.target.name]: e.target.value });
  };

  return (
    <div className={!props.fade ? "fadeOut" : "popupform__right"}>
      <img className="popupform__right--logo" src={logo} alt="logo" />
      <div class="popupform__right-login">
        <div class="popupform__right-heading heading center">
          <h3 class="h-3 popupform__right-heading">
            {" "}
            {!forget ? "ورود به کدآموز" : "فراموشی رمز عبور"}
          </h3>
        </div>

        {!forget ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-input-div field">
              <input
                type="username"
                name="username"
                id="popupform__right-login-username"
                {...register("username", { required: true })}
                required
                class={`required form-input field-input ${
                  errors.username && "error-border"
                }`}
              />
              <label
                for="popupform__right-login-username"
                className="form-label field-label"
              >
                <span>نام کاربری</span>
                <img
                  className="popupform__right-login-img"
                  src={userIcon}
                  alt="user"
                />
              </label>
            </div>
            {errors.username && (
              <p className="error-p">نام کاربری را وارد کنید</p>
            )}
            <div className="form-input-div field">
              <input
                type={passwordShown ? "text" : "password"}
                id="popupform__right-login-password "
                required
                name="password"
                class={`required form-input field-input ${
                  errors.password && "error-border"
                }`}
                {...register("password", { required: true })}
              />
              <label
                for="popupform__right-login-password"
                className="form-label  field-label"
              >
                <span>رمز عبور</span>
              </label>
              <div className="formInput_eyeBox">
                <i className="fas fa-eye" onClick={togglePasswordVisiblity}></i>
                <img
                  className="popupform__right-login-img"
                  src={password}
                  alt="password"
                />
              </div>
            </div>
            {errors.pass && <p className="error-p">رمز عبور را وارد کنید</p>}
            <div class="login-question-box">
              <p href="#" onClick={forgotEmail} style={{ cursor: "pointer" }}>
                رمز عبور خود را فراموش کرده اید؟
              </p>
            </div>

            <button
              type="submit"
              style={
                loading
                  ? { backgroundColor: "#797878", cursor: "not-allowed" }
                  : { background: "linear-gradient(#fcca46 10%, #f3722c" }
              }
              className="BtnSecondary1"
              disabled={!loading ? false : true}
            >
              {loading ? (
                <ClipLoader
                  color="#ffffff"
                  loading={loading}
                  css={override}
                  size={15}
                />
              ) : (
                " ورود "
              )}
            </button>
          </form>
        ) : (
          <ForgetPass backToLogin={backToLogin} />
        )}
      </div>
    </div>
  );
};
export default LoginForm;

import React, { useState } from "react";
import { useAuth } from "./context/AuthContext";
import { Route, Switch } from "react-router-dom";
import { API_URL } from "./constacts";
import useAxios from "@use-hooks/axios";
import SabadeKala from "./layout/SabadeKala";
import { coursese } from "./data/courses";
import CourseContent from "./layout/CourseContent";
import { GetContentInoContextProvider } from "./context/GetContentInoContext";
import MyCourse from "./layout/MyCourse";
import Codeeditor from "./layout/Codeeditor";
import { ContentSidebarProvider } from "./context/ContentSidebarContext";
import Quiz from "./layout/Quiz";
import ExampleMain from "./layout/ExampleMain";
import Myfavecourse from "./layout/Myfavecourse";

const Authenticated = () => {
  const { token, authDispatch } = useAuth();
  const [state, setState] = useState();

  return (
    <Switch>
      <Route
        exact
        path="/card"
        component={() => (
          <SabadeKala authorization={true} /> // cardCoursesinfo={cardCourse}
          // removerCard={removerCard}
        )}
      />
      <Route exact path="/mycourse" component={MyCourse} />

      <Route
        exact
        path="/courcontent"
        component={(routeProps) => <CourseContent information={coursese} key={routeProps.history.location.state.id}/>}
      />
      {/* <Route exact path="/example/:cid/:id" component={ExampleMain} /> */}

      <Route exact path="/quiz" render={() => <Quiz />} />
      <Route exact path="/codeeditor" component={Codeeditor} />
      <Route exact path="/myfavecourse" component={Myfavecourse} />
    </Switch>
  );
};

export default Authenticated;

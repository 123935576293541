import React from "react";
import ShowCase from "../share/ShowCase";
import HomeshowcaseImage from "../../img/courselist/Group 947.png";

function MyFavCourseHeader() {
  return (
    <header className="header headercourselist" id="header">
      <div className="container">
        <div className="showcase__pic1">
          <img src={HomeshowcaseImage} alt="showcase pic" />
        </div>
      </div>
      <div className="arrow__down">
        <i class="fas fa-arrow-down"></i>
      </div>
    </header>
  );
}

export default MyFavCourseHeader;

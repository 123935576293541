export const userReducer = (state, action) => {
  switch (action.type) {
    case 'REFRESH':
      window.localStorage.setItem('testtt', action.testtt);
      return action.testtt;
    case 'LOGOUTREFRESH':
      window.localStorage.removeItem('testtt');
      return null;
    default:
      return state;
  }
};

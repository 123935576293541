import React, {useState} from 'react';
import logo from '../../img/logo web.png';
import userIcon from '../../img/signlogform/user.svg';
import emailIcon from '../../img/signlogform/Icon material-email.svg';
import password from '../../img/signlogform/Icon ionic-ios-lock.svg';
import {BtnSecondary} from '../share/Btn';
import ShowHidePass from '../hooks/ShowHidePass';
import '../../css/main.scss';
import {useAuth} from '../../context/AuthContext';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {API_URL} from '../../constacts';
import useAxios from '@use-hooks/axios';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {css} from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
/**
 * return register to site
 * @param   {integer} username  
 * @param   {integer} email
 * @param   {integer} password1  
 * @param   {integer} password2  
  
 *
 * @return  send email-confirm to user email
 * 
 */ 
const SignUpForm = (props) => {
  const [formVal, setformVal] = useState({
    email: '',
    username: '',
    password: '',
    password2: '',
  });

  const handleChange = (e) => {
    setformVal({[e.target.name]: e.target.value});
  };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: {errors},
  } = useForm();
  const {authDispatch, token} = useAuth();
  const [signininfo, setSigninfo] = useState();
  let [loading, setLoading] = useState(false);
  const [activeButton, setactiveButton] = useState(false);

  const [passerror, setPassErorr] = useState(true);

  const history = useHistory();

  const emailnotify = () => {
    toast.error('این ایمیل قبلا ثیت نام شده است', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const usernamenotiy = () => {
    toast.error('این نام کاربری وجود دارد', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const registerSucessnotify = () => {
    toast.success('ایمیل تایید کاربری به ایمیلتان ارسال شد', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const commonpasswordNotify = () => {
    toast.error('پسوورد ساده است', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const DiffrentpassNotify = () => {
    toast.error('پسوورد  شبیه نیست', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const NumbericPassword = () => {
    toast.error('پسوورد باید ترکیبی از حروف و اعداد باشد ', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  //emailusernotify
  const emailusernotify = () => {
    toast.error('ایمیل و نام کاریری قبلا استفاده شده است', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const ShortPassNotify = () => {
    toast.error('پسورد کوتاه است.حداقل هشت رقم وارد کنید', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const signupuser = useAxios({
    url: `${API_URL}/register/`,
    method: 'POST',
    options: {
      data: signininfo,
      headers: {},
    },
    // trigger: resources,

    customHandler: (err, res) => {
      if (res) {
        registerSucessnotify();
        setLoading(false);
        setactiveButton(true);
        // console.log("res",res)
        reset();
        history.push('/logIn')
      }
      if (err) {
        setLoading(false);
        setactiveButton(true);

        console.log(err.response);
        if (err.response.data.email && err.response.data.username) {
          console.log('error', 'این ایمیل قبلا ثیت نام شده است');
          emailusernotify();
        }
       else if (err.response.data.email ) {
          console.log('error', 'این ایمیل قبلا ثیت نام شده است');
          emailnotify();
        }
        
        else if (err.response.data.username) {
          console.log('error', 'این نام کاربری از قبل موجود است');
          usernamenotiy();
        }
        else if (err.response.data.password1.includes('This password is too common.')) {
          console.log('error', 'پسوورد معمولی است');
          commonpasswordNotify();
        }
        else if (err.response.data.password1.includes('This password is entirely numeric.')){
          
          NumbericPassword()
        }
        else if (err.response.data.password1.includes('This password is too short. It must contain at least 8 characters.')){
          
          ShortPassNotify()
        }
        else {
          console.log(err.response)
        }
      }
    },
  });
  const onSubmit = (info) => {
    setSigninfo(info);

    if (info.password1 !== info.password2) {
      DiffrentpassNotify()
    } else {
      setLoading(true);
      setactiveButton(false);
      signupuser.reFetch();
    }

    //authDispatch({type: 'LOGIN', token: 123456});
    // history.push('/');
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  const [passwordShown, togglePasswordVisiblity] = ShowHidePass(false);
  return (
    <div class={props.fade ? 'fadeOut' : 'popupform__right'}>
      <img className="popupform__right--logo" src={logo} alt="logo" />
      <div class="popupform__right-login">
        <div class="popupform__right-heading heading center">
          <h3 class="h-3 popupform__right-heading">ثبت نام در کدآموز</h3>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-input-div field">
            <input
              type="text"
              name="username"
              id="popupform__right-login-username"
              readOnly={loading ? true : false}
              {...register('username', {required: true})}
              required
              class={`required form-input field-input ${
                errors.username && 'error-border'
              }`}
            />
            <label
              for="popupform__right-login-username"
              className="form-label field-label"
            >
              <span>نام کاربری</span>
              <img
                className="popupform__right-login-img"
                src={userIcon}
                alt="user"
              />
            </label>
          </div>
          {errors.username && (
            <p className="error-p">نام کاربری را وارد کنید</p>
          )}
          <div className="form-input-div field">
            <input
              type="email"
              name="email"
              id="popupform__right-login-email"
              readOnly={loading ? true : false}
              required
              class="required form-input field-input"
              {...register('email', {required: true})}
            />
            <label
              for="popupform__right-login-email"
              className="form-label  field-label"
            >
              <span>ایمیل</span>

              <img
                className="popupform__right-login-img"
                src={emailIcon}
                alt="user"
              />
            </label>
          </div>
          <div className="form-input-div field">
            <input
              type={passwordShown ? 'text' : 'password'}
              name="password1"
              id="popupform__right-login-password "
              readOnly={loading ? true : false}
              required
              {...register('password1', {required: true})}
              class="required form-input field-input"
            />
            <label
              for="popupform__right-login-password"
              className="form-label  field-label"
            >
              <span>رمز عبور</span>
            </label>
            <div className="formInput_eyeBox">
              <i className="fas fa-eye" onClick={togglePasswordVisiblity}></i>
              <img
                className="popupform__right-login-img"
                src={password}
                alt="password"
              />
            </div>
          </div>
          <div className="form-input-div field">
            <input
              type={passwordShown ? 'text' : 'password'}
              name="password2"
              id="popupform__right-login-password-2"
              readOnly={loading ? true : false}
              required
              {...register('password2', {required: true})}
              class="required form-input field-input"
            />
            <label
              for="popupform__right-login-password-2"
              className="form-label  field-label"
            >
              <span> تکرار رمز عبور</span>
              <div className="formInput_eyeBox">
                <i className="fas fa-eye" onClick={togglePasswordVisiblity}></i>
                <img
                  className="popupform__right-login-img"
                  src={password}
                  alt="password"
                />
              </div>
            </label>
          </div>
          {!passerror ? <p style={{color: 'red'}}>پسوور مشابه نیست </p> : null}

          <div
            style={{
              marginTop: '1rem',
              marginLeft: '6rem',
              marginBottom: '2.1rem',
            }}
          >
            
            <div class="check">
              <input
                type="checkbox"
                required
                name="khabarname"
                id="checkbox-privacy"
                onChange={() => setactiveButton(!activeButton)}
              />
              <label for="checkbox-privacy">
                با ثبت نام در کدآموز همه ی <a href="#">قوانین و مقررات </a>
                میپذیرم
              </label>
            </div>

            {/* <div class="check">
              <input type="checkbox" name="khabarname" id="khabarname" />
              <label for="khabarname">عضویت در خبرنامه کدآموز</label>
            </div> */}
          </div>
          <button
            type="submit"
            style={
              !activeButton || loading
                ? {backgroundColor: '#797878', cursor: 'not-allowed'}
                : {background: 'linear-gradient(#fcca46 10%, #f3722c'}
            }
            className="BtnSecondary1"
            disabled={activeButton ? false : true}
          >
            {loading ? (
              <ClipLoader
                color="#ffffff"
                loading={loading}
                css={override}
                size={15}
              />
            ) : (
              ' ثبت نام'
            )}
          </button>
        </form>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default SignUpForm;
// 0: "This password is too common."
// 1: "This password is entirely numeric."
//This password is too short. It must contain at least 8 characters.
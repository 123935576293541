import React from "react";
import "../../css/main.scss";

function Loader(props) {
  return (
    <div>
      <div className={props.start ? "loader1" : "loader"}></div>
    </div>
  );
}

export default Loader;

import React from 'react'
import LoginPage from '../components/LogIn/LoginPage'
import PhoneNavigation from '../components/share/PhoneNavigation';
import UseWindowSize from '../Sizes/UseWindowSize';

const LogIn = (props) => {
  const windowSize = UseWindowSize();

    return (
     <>
      {
        windowSize === "md-2" || windowSize === "sm" ? 
          <PhoneNavigation />
        :null
      }
      <LoginPage form={props.form}/>
     </>
    )
}

export default LogIn

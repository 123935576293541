import React from 'react'
import LastActivity from './LastActivity'

const MycourseSidebar = () => {
    return (
        <div className='MycourseSidebar'>
              <LastActivity />
        </div>
    )
}

export default MycourseSidebar

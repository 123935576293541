import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
} from "react";
import { withStyles } from "@material-ui/core/styles";
import { BtnDynamicPink } from "../share/Btn";
import { BtnDynamicGreen } from "../share/Btn";
import { ContentSidebarContext } from "../../context/ContentSidebarContext";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { useLocation } from "react-router";
import { useAuth } from "../../context/AuthContext";
import { useRecoilState } from "recoil";
import { textState,textState2 } from "../../Recoil/RecoilState";

const styles = {
  navDot: {
    width: "1.25rem",
    height: "1.25rem",
    borderRadius: "50%",
    position: "relative",
    border: "1px solid #656565",
  },
  nav: {
    display: "flex",
    alinItems: "center",
    flexDirection: "row",
    marginTop: ".45rem",
    direction: "rtl",
  },
  navBox: {
    display: "flex",
    flexDirection: "column",
    alinItems: "flex-start",
    marginTop: "1.5rem",
    color: " #656565",
  },
  navDescription: {
    display: "flex",
    justifyContent: "space-between",
    alinItems: "center",
    marginTop: ".75rem",
    fontSize: "1.6rem",
    color: "#656565",
  },
  navDotBox: {
    display: "flex",
    flexDirection: "row",
  },
  littelDots: {
    width: "0.2rem",
    height: "0.2rem",
    borderRadius: "50%",
    border: "1px solid #656565",
    margin: "0 .7rem",
    backgroundColor: "#656565",
  },
  littelDotsBox: {
    display: "flex",
    marginTop: "10rem",
    width: "100%",
  },
};

function CourseContentNav(props) {
  const location = useLocation();
  const { getSidebarSesons, seasonlist } = useContext(ContentSidebarContext);
  const [id, setId] = useState("");
  useEffect(() => {
    setId(location.state.id);
  }, [location]);
  const { token } = useAuth();
  const [precent, setPrecent] = useState(0);
  const Precentapi = useAxios({
    url: `${API_URL}/progress/course/${id}/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("precent", res.data);
        setPrecent(res.data.progress);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  const [navSize, setNavSize] = useState("0");
  const [state, setState] = useState([]);
  const { classes } = props;
  const count = parseInt(navSize, 10);
  // let precent = 55;
  const count1 =
    (count * precent) / 100 < 1
      ? Math.ceil((count * precent) / 100)
      : Math.floor((count * precent) / 100);
  const littelDot = Math.floor(100 / (parseInt(navSize, 10) - 1) / 2);
  const count2 = (navSize - 1) * littelDot + navSize;
  const count3 = (count2 * precent) / 100;
  const count4 = parseInt(count3, 10) / 12;
  const count5 = parseInt(count4, 10);
  const [contentid, setContentid] = useRecoilState(textState);
  const [contenttitle, setContenttitle] = useRecoilState(textState2);
  const firstUpdate = useRef(true);
  // useLayoutEffect(() => {
  //   if (firstUpdate.current) {
  //     firstUpdate.current = false;
  //     return;
  //   }
  //   console.log("here");
  //   // setLoading(true);

  //   Precentapi.reFetch();
  // }, [contentid]);
  useEffect(() => {
    if (seasonlist !== null) {
      setNavSize(seasonlist.seasons.length + 1);
    }
  }, [seasonlist]);
  useEffect(() => {
    Precentapi.reFetch();
  }, [contentid]);
  var test = [];
  var test2 = [];
  var test3 = [];

  for (let i = 0; i < count; i++) {
    test.push("0");
  }

  for (let j = 0; j < littelDot; j++) {
    test2.push("0");
  }

  for (let index = 0; index < 1; index++) {
    test3.push("0");
  }

  return (
    <div className="CourseContentNav">
      {Precentapi.response?.data && (
        <>
          {
            <div className={classes.littelDotsBox}>
              <div>
                <div className={classes.nav}>
                  <div className={classes.navBox}>
                    <div className={classes.navDotBox}>
                      {test.map((item, id) => (
                        <div className={classes.nav}>
                          {test3.map((index, idd) =>
                            id <= count5 ? (
                              <div
                                className={classes.navDot}
                                style={{
                                  backgroundColor: "#6AC24F",
                                  border: "none",
                                }}
                              ></div>
                            ) : (
                              id < navSize + 1 && (
                                <div className={classes.navDot}></div>
                              )
                            )
                          )}
                          <div className={classes.nav}>
                            {id < count5
                              ? test2.map((ind, arr) => (
                                  <div
                                    className={classes.littelDots}
                                    style={{
                                      backgroundColor: "#6AC24F",
                                      border: "1px solid #6AC24F",
                                    }}
                                  ></div>
                                ))
                              : id === count5 && count5 !== navSize - 1
                              ? test2.map((sab, ar) =>
                                  ar < count3 - count5 * littelDot - 1 ? (
                                    <div
                                      className={classes.littelDots}
                                      style={{
                                        backgroundColor: "#6AC24F",
                                        border: "1px solid #6AC24F",
                                      }}
                                    ></div>
                                  ) : ar > count3 - count5 * littelDot ? (
                                    <div className={classes.littelDots}></div>
                                  ) : (
                                    <div
                                      className="center"
                                      style={{
                                        color: "#fff",
                                        fontSize: "15px",
                                        marginTop: "-1.5rem",
                                        backgroundColor: "#6AC24F",
                                        borderRadius: "50%",
                                        width: "30px",
                                        height: "30px",
                                      }}
                                    >
                                      <p>%{precent}</p>
                                    </div>
                                  )
                                )
                              : id !== navSize - 1 &&
                                test2.map((end, iddd) => (
                                  <div className={classes.littelDots}></div>
                                ))}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className={classes.navDescription}>
                      <p>آغاز دوره</p>
                      <p>پایان دوره</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }{" "}
        </>
      )}
    </div>
  );
}

export default withStyles(styles)(CourseContentNav);

import React, { useState, lazy, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import CodeeditorBox from "../codeeditor/CodeeditorBox";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { useAuth } from "../../context/AuthContext";
import ContentExample from "./ContentExample";
import "highlight.js/styles/dracula.css";
import "../../css/main.scss";

const styles = {
  main: {
    width: "100%",
    paddingRight: "10rem",
    paddingLeft: "12rem",
    direction: "rtl",
    fontSize: "1.65rem",
    fontWeight: "normal",
    position: "relative",
  },
};

const code = `const App = props => {
  return (
    <div>
      <h1> React App </h1>
      <div>Awesome code</div>
    </div>
  );
};
`;
function ContentBody(props) {
  const { classes } = props;
  const [value, setValue] = useState(props.value);
  const [lan, setLan] = useState(props.lan);
  const [btn, setBtn] = useState(0);
  const [data, setData] = useState("");
  const [info, setInfo] = useState("");
  const [errs, setErrs] = useState("");
  const { token } = useAuth();

  return (
    <div>
      <div className="ContentBody">
        {props.contentInfo !== null
          ? props.contentInfo.codetext_set.map((index, i) => (
              <div className="ContentBody__Container">
                {index.code !== "" && index.text !== "" ? (
                  <div className="ContentBody__codtxt">
                    <div className="CodeEditotBox">
                      <CodeeditorBox
                        lan={props.lan}
                        value={index.code}
                        id={index.id}
                      />
                    </div>
                    <div className="ContentBodyText">
                      <p>{index.text}</p>
                    </div>
                  </div>
                ) : index.code === "" && index.text !== "" ? (
                  <div className="ContentBodyText1">
                    <p>{index.text}</p>
                  </div>
                ) : index.code !== "" && index.text === "" ? (
                  <div className="CodeEditotBoxcenter">
                    <CodeeditorBox
                      lan={props.lan}
                      value={index.code}
                      id={index.id}
                      small={true}
                    />
                  </div>
                ) : null}

                <ContentExample
                  example={index.example}
                  examplename={index.example_name}
                  lan={props.lan}
                />
              </div>
            ))
          : null}
      </div>
    </div>
  );
}

export default withStyles(styles)(ContentBody);

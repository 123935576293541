import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthContext";
import { ListProvider } from "./context/SeasonList";
import { UserProvider } from "./context/UserContext";
import { BrowserRouter as Router } from "react-router-dom";
import { GetUserInfoContextProvider } from "./context/GetUserInfoContext";
import { LanContextProvider } from "./context/LanContext";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <LanContextProvider>
        <GetUserInfoContextProvider>
          <ListProvider>
            <UserProvider>
              <Router>
                <App />
              </Router>
            </UserProvider>
          </ListProvider>
        </GetUserInfoContextProvider>
      </LanContextProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect, useState } from "react";
import Pishniazitem from "./PishNiazItem";
import "../../css/main.scss";
import uuid from "uuid/dist/v4";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../constacts";
function PishNiazList({ id }) {
  const [pishniaz, setPishniaz] = useState(null);
  const getSeason = useAxios({
    url: `${API_URL}/prerequisite/${id}/all/`,
    method: "GET",
    customHandler: (err, res) => {
      if (res) {
        console.log("resseason", res.data.results);
        setPishniaz(res.data.results);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });

  useEffect(() => {
    getSeason.reFetch();
  }, []);
  return (
    <div>
      <div className="corseInfo__head__propertyBox ">
        <svg
          className="PishNiazList__headFirstLine"
          viewPort="0 0 200 300"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line className="stroke" x1="40" y1="30" x2="620" y2="30"></line>
        </svg>
        <p className="corseInfo__head__propertyTitle">پیش نیازهای این دوره</p>
        <svg
          className="PishNiazList__headSecLine"
          viewPort="0 0 200 300"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line className="stroke" x1="10" y1="30" x2="620" y2="30"></line>
        </svg>
      </div>
      <div className="Pishniazlist">
       {pishniaz !== null && pishniaz !== undefined
          ? pishniaz.map((index, i) => (
                <Pishniazitem
                  first={index.pre_prop}
                  key={uuid()}
                  className="Pishniaz__Item"
                  sub={index.subprerequisites}
                />
            ))
          : null} 
      </div>
    </div>
  );
}

export default PishNiazList;


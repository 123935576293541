import React, {useState} from 'react';
import Navigation from '../share/Navigation';
import Footer from '../share/Footer';
import UserDetails from './UserDetails';
import UserPasword from './UserPasword';
import UseWindowSize from '../../Sizes/UseWindowSize';
import PhoneNavigation from '../share/PhoneNavigation';

function Usersetting() {
  const [state, setState] = useState(0);
  const windowSize = UseWindowSize();

  return (
    <div>
      {windowSize === "sm" ? <PhoneNavigation /> : <Navigation />}
      <div className="Usersetting container">
        <div className="Usersetting__content">
          {state === 0 && <UserDetails />}
          {state === 1 && <UserPasword />}
        </div>
        <div className="Usersetting__List ">
          <ul>
            <li
              className={
                state === 0
                  ? ' Usersetting__Item Usersetting__Item-active'
                  : 'Usersetting__Item'
              }
              onClick={() => setState(0)}
            >
              مشخصات فردی
            </li>
            <li
              className={
                state === 1
                  ? ' Usersetting__Item Usersetting__Item-active'
                  : 'Usersetting__Item'
              }
              onClick={() => setState(1)}
            >
              تغییر رمز ورود
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Usersetting;

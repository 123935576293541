import React, { useState, useEffect } from "react";
import SingleAccordion from "../Common/SingleAccordion";
import { BtnPrimary } from "../share/Btn";
import outputicon from "../../img/output-svgrepo-com.svg";
import inputicon from "../../img/input-svgrepo-com.svg";

import returnicon from "../../img/return-svgrepo-com.svg";
import { useRecoilState } from "recoil";
import { textState } from "../../Recoil/RecoilState";
import { NavLink, useHistory, useLocation } from "react-router-dom";

const ExampleTextContainer = ({ text, test_cases }) => {
  const [id, setId] = useState("");

  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);
  const [contentid, setContentid] = useRecoilState(textState);
  const history = useHistory();
  const backtocontent = () => {
    // setContentid(cid);
    history.push({
      pathname: `/courcontent`,
      state: {
        id: contentid,
      },
    });
  };

  return (
    <div className="ExampleTextContainer">
      <div className="container ">
        <div className="ExampleTextContainer-container">
          <div className="mycourse-defaultbox">
            <div className="mycourse-defaultheader">نمونه از مثال</div>
            <div className="mycourse-defaulbody ExampleTextContainer-defaultbody ">
              {test_cases !== null &&
                test_cases.map((i, index) => (
                  <SingleAccordion title={`${index + 1} تست کیس`}>
                    <div className="accordin--children">
                      <div className="accordin--children--box">
                        <div className="accordin--children--title">
                          <img src={inputicon} />
                          <p> ورودی نمونه :</p>
                        </div>
                        <p className="test-case">{i.input}</p>
                      </div>
                      <div className="accordin--children--box">
                        <div className="accordin--children--title">
                          <img src={outputicon} />
                          <p> خروجی نمونه :</p>
                        </div>
                        <p className="test-case">{i.output}</p>
                      </div>
                    </div>
                  </SingleAccordion>
                ))}
            </div>
          </div>
          <div className="mycourse-defaultbox ">
            <div className="mycourse-defaultheader">توضیحات</div>
            <div className="mycourse-defaulbody ExampleTextContainer-defaulbody">
              <p className="ExampleTextContainer--text">{text}</p>
              <NavLink
                to={{
                  pathname: `/courcontent`,
                  state: {
                    id: contentid,
                  },
                }}
              >
                <button
                  // onClick={backtocontent}
                  className="BtnPrimary ExampleTextContainer-btn"
                >
                  <img src={returnicon} alt={returnicon} />{" "}
                  <p> بازگشت به درس</p>
                </button>{" "}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExampleTextContainer;

import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { useAuth } from "../../context/AuthContext";
import UseWindowSize from "../../Sizes/UseWindowSize";
import UseCopyToClipboard from "../hooks/UseCopyToClipboard";
import { useLocation } from "react-router-dom";
import RiseLoader from "react-spinners/RiseLoader";
import { css } from "@emotion/react";
import Cookies from "js-cookie";
import Loader from "../Common/Loader";

import ExampleTextContainer from "./ExampleTextContainer";
import AceEditor from "react-ace";
// import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
const languages = [
  "sass",
  "mysql",
  "golang",
  "elixir",
  "csharp",
  "css",
  "d",
  "diff",
  "handlebars",
  "haskell",
  "ini",
  "java",
  "javascript",
  "json",
  "julia",
  "kotlin",
  "less",
  "lua",
  "makefile",
  "markdown",
  "nginx",
  "objectivec",
  "perl",
  "php",
  "properties",
  "python",
  "r",
  "ruby",
  "rust",
  "scala",
  "scss",
  "sql",
  "swift",
  "typescript",
  "xml",
  "yaml",
  "django",
  "c_cpp",
];

// languages.forEach((lang) => {
//   require(`ace-builds/src-noconflict/mode-${lang}`);
//   require(`ace-builds/src-noconflict/snippets/${lang}`);
// });
const override = css`
  display: block;
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

function ExampleDashboard(props) {
  languages.map(
    (lang) =>
      lang === props.lan &&
      require(`ace-builds/src-noconflict/mode-${lang}`) &&
      require(`ace-builds/src-noconflict/snippets/${lang}`)
  );
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("");
  const [test, setTest] = useState("");
  const [number, setNumber] = useState(0);
  const [res, setRes] = useState("");
  const [errs, setErrs] = useState("");
  const [info, setInfo] = useState();
  const [info1, setInfo1] = useState("");
  const [data, setData] = useState("");
  const [isCopied, handleCopy] = UseCopyToClipboard(3000);
  const [load, setLoad] = useState(false);
  const [btn, setBtn] = useState(1);
  const windowSize = UseWindowSize();
  const { token } = useAuth();
  useEffect(() => {
    Exapletest.reFetch();
  }, []);
  Cookies.set("path", window.location.href);
  const path = Cookies.get("path") ? Cookies.get("path") : null;
  const tokencookie = Cookies.get("token") ? Cookies.get("token") : null;
  const Exapletest = useAxios({
    url: `${API_URL}/example/${props.exmpid}/get/`,
    method: "GET",
    options: {
      withCredentials: true,
      headers: {
        Authorization: `JWT ${token}`,
      },
      Cookies: {
        path: path,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("Quiztest", res.data);
        setInfo(res.data);
        setValue(res.data.code);
        setLoading(false);
      }
      if (err) {
        console.log(err.response);
        setLoading(false);
      }
    },
  });

  const QuizResultplay = useAxios({
    url: `${API_URL}/compile/v2/example/playground/`,
    method: "POST",
    options: {
      data: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("QuizResult", res.data);
        setInfo1(res.data);
        res.data.compiler_stdout === null ? setBtn(2) : setBtn(1);
        setRes(res.data.compiler_stdout);
        res.data.compiler_stderr !== null &&
          res.data.compiler_stdout === null &&
          setRes("null");
        res.data.compiler_stderr !== null
          ? setErrs(res.data.compiler_stderr.replace("/n", "<br />"))
          : setErrs("null");
        setLoad(false);
      }
      if (err) {
        console.log(err.response);
        setLoad(false);
      }
    },
  });
  const QuizResultsend = useAxios({
    url: `${API_URL}/compile/v3/example/send_to_server/`,
    method: "POST",
    options: {
      data: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("QuizResult", Object.values(res.data.compiler_stderr));
        setInfo1(res.data);
        setNumber(res.data.compile_result);
        res.data.compiler_stderr !== null ? setBtn(2) : setBtn(1);
        setRes(res.data.compiler_stdout);
        setErrs(res.data.compiler_stderr);
        // setRes(
        //   JSON.stringify(Object.values(res.data.compiler_stdout))
        //     .replace(/\n/g, "")
        //     .replace(/"/g, "")
        //     .replace(",", "\n")
        //     .replace(",", "\n")
        //     .replace("[", "")
        //     .replace("]", "")
        // );
        var totn_string = "";

        // Object.values(res.data.compiler_stderr)[0] === null
        //   ? setErrs(
        //       JSON.stringify(Object.values(res.data.compiler_stderr))
        //         .replace(/\n/g, "")
        //         .replace(/"/g, "")
        //         .replace(",", "\n")
        //         .replace(",", "\n")
        //         .replace("[", "")
        //         .replace("]", "")
        //     )
        //   : setErrs(
        //       totn_string.concat(
        //         Object.values(res.data.compiler_stderr)[0],
        //         Object.values(res.data.compiler_stderr)[1],
        //         Object.values(res.data.compiler_stderr)[2]
        //       )
        //     );
        res.data.compiler_stderr !== null &&
          res.data.compiler_stdout === null &&
          setRes("null");
        res.data.compiler_stderr !== null
          ? setErrs(res.data.compiler_stderr.replace("/n", "<br />"))
          : setErrs("null");
        setLoad(false);
      }

      if (err) {
        console.log(err.response);
        setLoad(false);
      }
    },
  });

  const handleSend = () => {
    setData({
      submissions: {
        example_id: props.exmpid,
        input: test,
        source: value,
      },
    });
    setLoad(true);
    QuizResultplay.reFetch();
  };
  const handleInputSend = () => {
    setData({
      submissions: {
        example_id: props.exmpid,
        source: value,
        input: test,
      },
    });
    setLoad(true);
    QuizResultsend.reFetch();
  };
  function onChange(newValue) {
    setValue(newValue);
  }
  function onChange1(newValue) {
    setTest(newValue);
  }

  return (
    <div>
      {!loading ? (
        <div className="Example">
          {Exapletest.response?.data && props.lan && (
            <>
              <ExampleTextContainer
                test_cases={info.test_cases}
                text={info.text}
              />
              <div className="container" style={{ position: "relative " }}>
                <div className="Example__codeeditor">
                  <div>
                    <div className="Example__codeeditor-btnBox">
                      <p>{`${info.name}.${props.lan}`}</p>
                      <div className="d-flex ">
                        <button
                          className="Example__codeeditor-btncopy"
                          onClick={() => handleCopy(value)}
                        >
                          {isCopied ? (
                            <div className="d-flex">
                              <i className="fas fa-check"></i>
                              کپی شد
                            </div>
                          ) : (
                            <div className="d-flex">
                              {" "}
                              <i className="far fa-copy"></i>
                              کپی
                            </div>
                          )}
                        </button>
                        <button
                          className={
                            load
                              ? "Example__codeeditor-btndonedis"
                              : "Example__codeeditor-btndone"
                          }
                          onClick={handleSend}
                          disabled={load}
                        >
                          <i className="fas fa-play"></i>
                          اجرای کد
                        </button>
                        <button
                          className={
                            load
                              ? "Example__codeeditor-btndonedis"
                              : "Example__codeeditor-btndone Example__codeeditor1-btndone"
                          }
                          onClick={handleInputSend}
                          disabled={load}
                        >
                          <i class="fas fa-paper-plane"></i>
                          ارسال پاسخ
                        </button>
                      </div>
                    </div>
                    {windowSize === "lg" ||
                    windowSize === "md" ||
                    windowSize === "md-2" ||
                    windowSize === "sm" ? (
                      <AceEditor
                        mode={props.lan}
                        theme="monokai"
                        value={value}
                        onChange={onChange}
                        width="100%"
                        height="65.1vh"
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                          enableBasicAutocompletion: true,
                          enableLiveAutocompletion: true,
                          enableSnippets: true,
                        }}
                      />
                    ) : windowSize === "big" ? (
                      <AceEditor
                        mode={props.lan}
                        theme="monokai"
                        value={value}
                        onChange={onChange}
                        width="100%"
                        height="65.1vh"
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                          enableBasicAutocompletion: true,
                          enableLiveAutocompletion: true,
                          enableSnippets: true,
                        }}
                      />
                    ) : (
                      <AceEditor
                        mode={props.lan}
                        theme="monokai"
                        value={value}
                        onChange={onChange}
                        width="45vw"
                        height="65.1vh"
                        name="UNIQUE_ID_OF_DIV"
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                          enableBasicAutocompletion: true,
                          enableLiveAutocompletion: true,
                          enableSnippets: true,
                        }}
                      />
                    )}
                  </div>
                  <div>
                    <div className="Example__codeeditor-btnBox2">
                      <div className="d-flex">
                        <button
                          className={
                            btn === 1
                              ? "Example__activbtn"
                              : "Example__disactivbtn1"
                          }
                          value={1}
                          onClick={() => setBtn(1)}
                        >
                          Result
                        </button>
                        <button
                          className={
                            btn === 2
                              ? "Example__activbtn"
                              : "Example__disactivbtn1"
                          }
                          value={2}
                          onClick={() => setBtn(2)}
                        >
                          Errors
                        </button>
                      </div>
                      {/* <div className="d-flex quizNumber__part">
                        <p>:نمره از 100 </p>
                        <p
                          className={
                            number < 100.0
                              ? "faildquiz"
                              : number === 100.0
                              ? "goodquiz"
                              : "passquiz"
                          }
                        >
                          {number}
                        </p>
                      </div> */}
                    </div>
                    <div className="result-codeeditor">
                      {windowSize === "lg" ||
                      windowSize === "md" ||
                      windowSize === "md-2" ||
                      windowSize === "sm" ? (
                        <AceEditor
                          mode={props.lan}
                          theme="monokai"
                          value={btn === 1 ? res : errs}
                          width="100%"
                          height="30vh"
                          name="UNIQUE_ID_OF_DIV"
                          readOnly
                          editorProps={{ $blockScrolling: true }}
                          highlightActiveLine={false}
                          showGutter={false}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: false,
                          }}
                        />
                      ) : windowSize === "big" ? (
                        <AceEditor
                          mode={props.lan}
                          theme="monokai"
                          value={btn === 1 ? res : errs}
                          width="100%"
                          height="30vh"
                          name="UNIQUE_ID_OF_DIV"
                          readOnly
                          editorProps={{ $blockScrolling: true }}
                          highlightActiveLine={false}
                          showGutter={false}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: false,
                          }}
                        />
                      ) : (
                        <AceEditor
                          mode={props.lan}
                          theme="monokai"
                          value={btn === 1 ? res : errs}
                          width="45vw"
                          height="30vh"
                          name="UNIQUE_ID_OF_DIV"
                          readOnly
                          editorProps={{ $blockScrolling: true }}
                          highlightActiveLine={false}
                          showGutter={false}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: false,
                          }}
                        />
                      )}
                    </div>
                    <div className="Example__TestBox">
                      <div className="Example__codeeditor-btnBox1">
                        <p>Test</p>
                      </div>{" "}
                      {windowSize === "lg" ||
                      windowSize === "md" ||
                      windowSize === "md-2" ||
                      windowSize === "sm" ? (
                        <AceEditor
                          mode={props.lan}
                          theme="monokai"
                          value={test}
                          onChange={onChange1}
                          width="100%"
                          height="30.5vh"
                          name="UNIQUE_ID_OF_DIV"
                          editorProps={{ $blockScrolling: true }}
                          highlightActiveLine={false}
                          showGutter={false}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: false,
                          }}
                        />
                      ) : windowSize === "big" ? (
                        <AceEditor
                          mode={props.lan}
                          theme="monokai"
                          value={test}
                          onChange={onChange1}
                          width="100%"
                          height="30.5vh"
                          name="UNIQUE_ID_OF_DIV"
                          editorProps={{ $blockScrolling: true }}
                          highlightActiveLine={false}
                          showGutter={false}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: false,
                          }}
                        />
                      ) : (
                        <AceEditor
                          mode={props.lan}
                          theme="monokai"
                          value={test}
                          onChange={onChange1}
                          width="45vw"
                          height="30.5vh"
                          name="UNIQUE_ID_OF_DIV"
                          editorProps={{ $blockScrolling: true }}
                          highlightActiveLine={false}
                          showGutter={false}
                          setOptions={{
                            enableBasicAutocompletion: true,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: false,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <RiseLoader
                  color="#0dca78"
                  loading={load}
                  size={30}
                  css={override}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <Loader />
        </div>
      )}
    </div>
  );
}

export default ExampleDashboard;

import React, { useState, useEffect, useContext } from "react";
import UseCopyToClipboard from "../hooks/UseCopyToClipboard";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { useAuth } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";
import RiseLoader from "react-spinners/RiseLoader";
import { css } from "@emotion/react";
import UseWindowSize from "../../Sizes/UseWindowSize";
import { render } from "react-dom";
import AceEditor from "react-ace";
// import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
const languages = [
  "sass",
  "mysql",
  "golang",
  "elixir",
  "csharp",
  "css",
  "d",
  "diff",
  "handlebars",
  "haskell",
  "ini",
  "java",
  "javascript",
  "json",
  "julia",
  "kotlin",
  "less",
  "lua",
  "makefile",
  "markdown",
  "nginx",
  "objectivec",
  "perl",
  "php",
  "properties",
  "python",
  "r",
  "ruby",
  "rust",
  "scala",
  "scss",
  "sql",
  "swift",
  "typescript",
  "xml",
  "yaml",
  "django",
  "c_cpp",
];

const override = css`
  display: block;
  z-index: 100;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

function CodeeditorWithTest(props) {
  languages.map(
    (lang) =>
      lang === props.lan &&
      require(`ace-builds/src-noconflict/mode-${lang}`) &&
      require(`ace-builds/src-noconflict/snippets/${lang}`)
  );

  const [isCopied, handleCopy] = UseCopyToClipboard(3000);
  const windowSize = UseWindowSize();
  const [value, setValue] = useState("");
  function onChange(newValue) {
    setValue(newValue);
  }
  const [data, setData] = useState("");
  const [res, setRes] = useState("");
  const [errs, setErrs] = useState("");
  const [info1, setInfo1] = useState("");
  const [btn, setBtn] = useState(1);
  const [number, setNumber] = useState(0);
  const [test, setTest] = useState("");
  const [load, setLoad] = useState(false);
  const { token } = useAuth();
  const QuizResult = useAxios({
    url: `${API_URL}/compile/v2/quiz/playground/`,
    method: "POST",
    options: {
      data: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("QuizResult", res.data);
        setInfo1(res.data);
        res.data.compiler_stdout === null ? setBtn(2) : setBtn(1);
        setRes(res.data.compiler_stdout);
        res.data.compiler_stderr !== null
          ? setErrs(res.data.compiler_stderr.replace("/n", "<br />"))
          : setErrs(res.data.compiler_stderr);
        setLoad(false);
      }
      if (err) {
        console.log(err.response);
        setLoad(false);
      }
    },
  });
  const QuizResultfinal = useAxios({
    url: `${API_URL}/compile/v3/quiz/send_to_server/`,
    method: "POST",
    options: {
      data: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("QuizResultfinal", res.data);
        setInfo1(res.data);
        setNumber(res.data.compile_result);
        res.data.compiler_stderr !== null ? setBtn(2) : setBtn(1);
        setRes(res.data.compiler_stdout);
        setErrs(res.data.compiler_stderr);
        res.data.compiler_stderr !== null && setRes(res.data.compiler_stdout);

        res.data.compiler_stderr !== null
          ? setErrs(res.data.compiler_stderr.replace("/n", "<br />"))
          : setErrs("null");
        setLoad(false);
      }
      if (err) {
        console.log(err.response);
        setLoad(false);
      }
    },
  });
  const CodeResult = useAxios({
    url: `${API_URL}/compile/v2/code/`,
    method: "POST",
    options: {
      data: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("CodeResult", res.data);
        setInfo1(res.data);
        res.data.compiler_stdout === null ? setBtn(2) : setBtn(1);
        setRes(res.data.compiler_stdout);
        setErrs(res.data.compiler_stderr);
        res.data.compiler_stderr !== null
          ? setErrs(res.data.compiler_stderr.replace("/n", "<br />"))
          : setErrs("null");
        setLoad(false);
        setLoad(false);
      }
      if (err) {
        console.log(err.response);
        setLoad(false);
      }
    },
  });
  const handleSend = () => {
    setData({
      submissions: {
        code_id: props.id,
        input: test,
        source: value,
      },
    });

    setLoad(true);

    CodeResult.reFetch();
  };
  const handleInputSend = () => {
    setData({
      submissions: {
        question_id: props.id,
        source: value,
        input: test,
      },
    });
    setLoad(true);
    QuizResultfinal.reFetch();
  };
  function onChange1(newValue) {
    setTest(newValue);
  }
  return (
    <>
      {props.lan && (
        <div style={{ position: "relative" }} className="container">
          <div className="Example__codeeditor">
            <div>
              <div className="Example__codeeditor-btnBox">
                <p>{`${props.name}.${props.lan}`}</p>
                <div className="d-flex ">
                  <button
                    className="Example__codeeditor-btncopy"
                    onClick={() => handleCopy(value)}
                  >
                    {isCopied ? (
                      <div className="d-flex">
                        <i className="fas fa-check"></i>
                        کپی شد
                      </div>
                    ) : (
                      <div className="d-flex">
                        {" "}
                        <i className="far fa-copy"></i>
                        کپی
                      </div>
                    )}
                  </button>
                  <button
                    className={
                      load
                        ? "Example__codeeditor-btndonedis"
                        : "Example__codeeditor-btndone"
                    }
                    onClick={handleSend}
                    disabled={load}
                  >
                    <i className="fas fa-play"></i>
                    اجرای کد
                  </button>
                  {!props.code && (
                    <button
                      className={
                        load
                          ? "Example__codeeditor-btndonedis"
                          : "Example__codeeditor-btndone"
                      }
                      onClick={handleInputSend}
                      disabled={load}
                    >
                      <i className="fas fa-play"></i>
                      ارسال پاسخ
                    </button>
                  )}
                </div>
              </div>
              {windowSize === "lg" ||
              windowSize === "md" ||
              windowSize === "md-2" ||
              windowSize === "sm" ? (
                <AceEditor
                  mode={props.lan}
                  theme="monokai"
                  value={value}
                  onChange={onChange}
                  width="100%"
                  height="65.1vh"
                  name="UNIQUE_ID_OF_DIV"
                  editorProps={{ $blockScrolling: true }}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                  }}
                />
              ) : windowSize === "big" ? (
                <AceEditor
                  mode={props.lan}
                  theme="monokai"
                  value={value}
                  width="100%"
                  height="65.1vh"
                  name="UNIQUE_ID_OF_DIV"
                  onChange={onChange1}
                  editorProps={{ $blockScrolling: true }}
                  highlightActiveLine={false}
                  showGutter={false}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    // showLineNumbers: false,
                  }}
                />
              ) : (
                <AceEditor
                  mode={props.lan}
                  theme="monokai"
                  value={value}
                  onChange={onChange}
                  width="45vw"
                  height="65.1vh"
                  name="UNIQUE_ID_OF_DIV"
                  editorProps={{ $blockScrolling: true }}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                  }}
                />
              )}
            </div>
            <div>
              <div className="Example__codeeditor-btnBox2">
                <div className="d-flex">
                  <button
                    className={
                      btn === 1 ? "Example__activbtn" : "Example__disactivbtn1"
                    }
                    value={1}
                    onClick={() => setBtn(1)}
                  >
                    Result
                  </button>
                  <button
                    className={
                      btn === 2 ? "Example__activbtn" : "Example__disactivbtn1"
                    }
                    value={2}
                    onClick={() => setBtn(2)}
                  >
                    Errors
                  </button>
                </div>

                {!props.code && (
                  <div className="d-flex quizNumber__part">
                    <p>:نمره از 100 </p>
                    <p
                      className={
                        number < 100.0
                          ? "faildquiz"
                          : number === 100.0
                          ? "goodquiz"
                          : "passquiz"
                      }
                    >
                      {number}
                    </p>
                  </div>
                )}
              </div>
              {windowSize === "lg" ||
              windowSize === "md" ||
              windowSize === "md-2" ||
              windowSize === "sm" ? (
                <AceEditor
                  mode={props.lan}
                  theme="monokai"
                  value={btn === 1 ? res : errs}
                  width="100%"
                  height="30vh"
                  name="UNIQUE_ID_OF_DIV"
                  readOnly
                  editorProps={{ $blockScrolling: true }}
                  highlightActiveLine={false}
                  showGutter={false}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: false,
                  }}
                />
              ) : windowSize === "big" ? (
                <AceEditor
                  mode={props.lan}
                  theme="monokai"
                  value={btn === 1 ? res : errs}
                  width="100%"
                  height="30.5vh"
                  name="UNIQUE_ID_OF_DIV"
                  onChange={onChange1}
                  editorProps={{ $blockScrolling: true }}
                  highlightActiveLine={false}
                  showGutter={false}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    // showLineNumbers: false,
                  }}
                />
              ) : (
                <AceEditor
                  mode={props.lan}
                  theme="monokai"
                  value={btn === 1 ? res : errs}
                  width="45vw"
                  height="30vh"
                  name="UNIQUE_ID_OF_DIV"
                  readOnly
                  editorProps={{ $blockScrolling: true }}
                  highlightActiveLine={false}
                  showGutter={false}
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: false,
                  }}
                />
              )}

              <div className="Example__TestBox">
                <div className="Example__codeeditor-btnBox1">
                  <p>Test</p>
                </div>{" "}
                {windowSize === "lg" ||
                windowSize === "md" ||
                windowSize === "md-2" ||
                windowSize === "sm" ? (
                  <AceEditor
                    mode={props.lan}
                    theme="monokai"
                    value={test}
                    width="100%"
                    height="30.5vh"
                    name="UNIQUE_ID_OF_DIV"
                    onChange={onChange1}
                    editorProps={{ $blockScrolling: true }}
                    highlightActiveLine={false}
                    showGutter={false}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: true,
                      // showLineNumbers: false,
                    }}
                  />
                ) : windowSize === "big" ? (
                  <AceEditor
                    mode={props.lan}
                    theme="monokai"
                    value={test}
                    width="100%"
                    height="30.5vh"
                    name="UNIQUE_ID_OF_DIV"
                    onChange={onChange1}
                    editorProps={{ $blockScrolling: true }}
                    highlightActiveLine={false}
                    showGutter={false}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: true,
                      // showLineNumbers: false,
                    }}
                  />
                ) : (
                  <AceEditor
                    mode={props.lan}
                    theme="monokai"
                    value={test}
                    width="45vw"
                    height="30.5vh"
                    name="UNIQUE_ID_OF_DIV"
                    onChange={onChange1}
                    editorProps={{ $blockScrolling: true }}
                    highlightActiveLine={false}
                    showGutter={false}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: true,
                      // showLineNumbers: false,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <RiseLoader color="#0dca78" loading={load} size={30} css={override} />
        </div>
      )}
    </>
  );
}

export default CodeeditorWithTest;

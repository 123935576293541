import React, { useState, useEffect } from "react";
import PhoneCorseIntro from "../components/courseInfo/PhoneSize/PhoneCorseIntro";
import PhoneCourseHead from "../components/courseInfo/PhoneSize/PhoneCourseHead";
import PhoneCurseTable from "../components/courseInfo/PhoneSize/PhoneCurseTable";
import PhoneTeacherBox from "../components/courseInfo/PhoneSize/PhoneTeacherBox";
import PhoneDescription from "../components/courseInfo/PhoneSize/PhoneDescription";
import PhoneSarfasl from "../components/courseInfo/PhoneSize/PhoneSarfasl";
import PhoneComment from "../components/courseInfo/PhoneSize/PhoneComment";
import PhoneForm from "../components/courseInfo/PhoneSize/PhoneForm";
import PhoneSimilarCurse from "../components/courseInfo/PhoneSize/PhoneSimilarCurse";
import Footer from "../components/share/Footer";
import { useLocation } from "react-router-dom";
import "../css/main.scss";
import Comment from "../components/courseInfo/Comment";
import UserComment from "../components/courseInfo/UserComment";
import CourseInfoForm from "../components/courseInfo/CourseInfoForm";

function PhoneCourseInfo({ courseinfo, SarfaslContentInfo, id }) {
  // const [id, setId] = useState("");
  // const location = useLocation();
  // useEffect(() => {
  //   setId(location.state.id);
  // }, [location]);
  return (
    <div>
      <PhoneCourseHead
        title={courseinfo.title}
        properties={courseinfo.properties}
        picture={courseinfo.cover}
      />
      <PhoneCurseTable id={courseinfo.id} />
      <PhoneCorseIntro video={courseinfo.intro_video} />
      <PhoneTeacherBox
        name={courseinfo.teacher_first_name}
        last={courseinfo.teacher_last_name}
        txt={courseinfo.teacher_description}
        pic={courseinfo.teacher_picture}
      />
      <PhoneDescription intro={courseinfo.intro} />
      <PhoneSarfasl
        Coursetitle={courseinfo.title}
        id={id}
        seasons={courseinfo.seasons}
        SarfaslContentInfo={SarfaslContentInfo}
      />
      <div id="CourseInfoComment">
        { courseinfo.mean_of_participant_points.grade !== null ? (
          <Comment
            star={ courseinfo.mean_of_participant_points.grade}
            num={courseinfo.nums_of_voter}
            linepro={courseinfo.percentage_of_quality_of_course}
          />
        ) : null}

        <UserComment id={id} />
      </div>

      <CourseInfoForm />
      <PhoneSimilarCurse />
      <div style={{ position: "relative", zIndex: "10000" }}>
        <Footer />
      </div>
    </div>
  );
}

export default PhoneCourseInfo;

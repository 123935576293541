import React from "react";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";

export const textState = atom({
  key: "textState", // unique ID (with respect to other atoms/selectors)
  default: null,
});
export const textState2 = atom({
  key: "textState2", // unique ID (with respect to other atoms/selectors)
  default: null,
});
export const textState1 = atom({
  key: "textState1", // unique ID (with respect to other atoms/selectors)
  default: [],
});

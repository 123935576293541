import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from "react";
import { ContentSidebarContext } from "../../context/ContentSidebarContext";
import { GetContentInoContext } from "../../context/GetContentInoContext";
import { useUser } from "../../context/UserContext";
import { useAuth } from "../../context/AuthContext";
import Content from "./Content";
import ContentBody from "./ContentBody";
import { useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { textState, textState1, textState2 } from "../../Recoil/RecoilState";
import Loader from "../Common/Loader";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";

const ContentContainer = (props) => {
  const { getContentInfo, contentInfo } = useContext(GetContentInoContext);
  const { seasonlist, seasonlist1, seasonlist2 } = useContext(
    ContentSidebarContext
  );
  // const {list} = useList();
  // const [list, setList] = useRecoilState(textState1);
  const [contentid, setContentid] = useRecoilState(textState);
  const [contenttitle, setContenttitle] = useRecoilState(textState2);
  const [pass1, setPass1] = useState(0);
  const { testtt, userDispatch } = useUser();
  const [id, setId] = useState("");
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);
  const { token } = useAuth();

  useEffect(() => {
    setContentid(testtt);
  }, []);
  useEffect(() => {
    getContentInfo.reFetch();
    // setContentid(testtt);
  }, [contentid, contenttitle]);

  return (
    <>
      {getContentInfo.response?.data ? (
        <div>
          {seasonlist && (
            <div>
              <Content
                contentInfo={contentInfo}
                setState={props.setState}
                lan={props.lan}
                seasonlist={seasonlist}
                seasonlist2={seasonlist2}
              />

              <ContentBody lan={props.lan} contentInfo={contentInfo} />
            </div>
          )}
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default ContentContainer;

import React from "react";
import "../../css/main.scss";
import { useRecoilState } from "recoil";
import { textState, textState2 } from "../../Recoil/RecoilState";
import { useUser } from "../../context/UserContext";

const SideBarContent = (props) => {
  const [contentid, setContentid] = useRecoilState(textState);
  const [contenttitle, setContenttitle] = useRecoilState(textState2);
  const { userDispatch, testtt } = useUser();
  console.log("props.index", props.index);
  const changecontent = () => {
    if (!props.index.lockedOn) {
      setContentid(props.index.id);
      setContenttitle(props.index.unique_id);
      userDispatch({
        type: "REFRESH",
        testtt: props.index.id,
      });
    }
  };

  return (
    <div>
      <div
        ref={props.content}
        style={{ maxHeight: `${props.setHeight}`, cursor: "pointer" }}
        className="accordion__content"
        onClick={changecontent}
      >
        <div className="accordion__contentBox">
          <div className="accordion__contentTop">
            <i className="far fa-play-circle"></i>
            <div
              className="accordion__text"
              dangerouslySetInnerHTML={{ __html: props.index.title }}
            />
          </div>
          <div>
            {!props.index.lockedOn ? null : <i className="fas fa-lock"></i>}
            {props.index.is_content_passed ? (
              <i className="fas fa-check-circle"></i>
            ) : null}
          </div>
        </div>
        <p>{props.index.duration_time}</p>
      </div>
    </div>
  );
};

export default SideBarContent;

import React, { useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./layout/Home";
import Navigation from "./components/share/Navigation";
import UseWindowSize from "./Sizes/UseWindowSize";
import PhoneNavigation from "./components/share/PhoneNavigation";
import CourseInfo from "./layout/CourseInfo";
import CourseContent from "./layout/CourseContent";
import { useAuth } from "./context/AuthContext";
import Authenticated from "./Authenticated";
import UnAuthenticated from "./UnAuthenticated";
import Courselist from "./layout/Courselist";
import LogIn from "./layout/LogIn";
import { coursese } from "./data/courses";
import CardCoursesProvider from "./context/CardCourseContext";
import { GetUserInfoContext } from "./context/GetUserInfoContext";
import EmailVerify from "./components/Common/EmailVerify";
import Usersetting from "./components/User/Usersetting";
import ResetPassworld from "./components/LogIn/ResetPassworld";
import ExampleMain from "./layout/ExampleMain";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";

const App = () => {
  const { token } = useAuth();
  const windowSize = UseWindowSize();
  return (
    <RecoilRoot>
      <div>
        <CardCoursesProvider>
          {/* {windowSize === "sm" ? <PhoneNavigation /> : <Navigation />} */}
          {windowSize === "sm" ? <PhoneNavigation /> : <Navigation />}

          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/user_setting" component={Usersetting} />
            <Route exact path="/courseinfo"   
                render={(routeProps) => <CourseInfo  key={routeProps.history.location.state.id} />} />

            <Route
              exact
              path="/CourseList"
              component={() => (
                <Courselist information={coursese} authorization={true} />
              )}
            />
            <Route
              exact
              path="/account-confirm-email/:confirmedkey"
              render={(routeprops) => (
                <EmailVerify
                  confirmKey={routeprops.match.params.confirmedkey}
                />
              )}
            />
            <Route
              exact
              path="/password/reset/confirm/:uid/:token"
              render={(routeprops) => (
                <ResetPassworld
                  uid={routeprops.match.params.uid}
                  token={routeprops.match.params.token}
                />
              )}
            />
            <Route exact path="/example" component={ExampleMain} />
          </Switch>
          {token ? <Authenticated /> : <UnAuthenticated />}
        </CardCoursesProvider>
      </div>
    </RecoilRoot>
  );
};

export default App;

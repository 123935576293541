import React from 'react';

function DefaultBox(props) {
  return (
    <div
      style={{
        borderRadius: '2rem',
        position: 'relative',
      }}
      className={props.className}
    >
      {props.children}
    </div>
  );
}

export default DefaultBox;

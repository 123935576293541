import React, { useState, useEffect, useContext } from "react";
import armeedarehrangi1 from "../../../img/arme-edareh-rangi-1.png";
import user from "../../../img/svg/vectorpaint.svg";
import { API_URL } from "../../../constacts";
import useAxios from "@use-hooks/axios";
import ADDTOPackage from "../../../img/Add to package.png";
import { BtnSecondarybig } from "../../share/Btn";
import { CardCourses } from "../../../context/CardCourseContext";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../context/AuthContext";
import { useUser } from "../../../context/UserContext";
import { useList } from "../../../context/SeasonList";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "../../../css/main.scss";
import Chevron from "../../Common/Chevron";
import useFetch from "../../../components/hooks/useFetch";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { textState, textState1, textState2 } from "../../../Recoil/RecoilState";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: "flex",
    alignItems: "center",
    borderColor: "#f3722c",
    position: "relative",
    zIndex: "1000",
  },
}));

function CourseInfoTableContent(props) {
  const classes = useStyles();
  const [information, setInformation] = useState("");
  const [degree1, setDegree1] = useState(0);
  const [degreeid, setDegreeid] = useState(0);
  const [loading, setLoading] = useState(false);

  const [course, setCourse] = useState(false);

  const [isFavorite1, setIsFavorite1] = useState({ isFavorite: false });

  const {
    addToCard,
    setalredyaddedDialog1,
    setalredyaddedDialog,
    handlerefresh1,
    setAddToCardDliaog,
    cardCourse,
    setCardCourse,
    summary,
  } = useContext(CardCourses);
  const [id, setId] = useState("");
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
    SideBar.reFetch();
  }, [location]);
  const { token } = useAuth();
  const { userDispatch, testtt } = useUser();
  // const [list, setList] = useRecoilState(textState1);
  // useEffect(() => {
  //   SideBar.reFetch();
  // }, []);
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: green;
  `;
  const handleClick = () => {
    setDegree1(information.costs[degreeid].degree);
    setLoading(true);
    cardadd.reFetch();
  };

  const SideBar = useAxios({
    url: `${API_URL}/sidebar/order-sidebar/${id}/`,
    method: "GET",
    options: {
      headers: token
        ? {
            Authorization: `JWT ${token}`,
          }
        : {},
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("sidee", res.data);
        setInformation(res.data);
        res.data.costs.map((item, id) => {
          if (res.data.currentCostDegree === null) {
            if (item.name === "بدون مدرک") {
              setDegree1(item.degree);
              setDegreeid(id);
            } else {
              {
                id + 1 === res.data.costs.lenght &&
                  setDegree1(item.degree) &&
                  setDegreeid(id);
              }
            }
          } else {
            if (item.name === res.data.currentCostDegree) {
              setDegree1(item.degree);
              setDegreeid(id);
            } else {
              {
                id + 1 === res.data.costs.lenght &&
                  setDegree1(item.degree) &&
                  setDegreeid(id);
              }
            }
          }
        });
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  const autherror = () => {
    toast.error("برای خرید محصول ابتدا وارد سایت شوید", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const cardadd = useAxios({
    url: `${API_URL}/cart/add-to-cart-with-degree/${id}/${degree1}/`,
    method: "POST",
    options: {
      // data: `${id}`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        setLoading(false);
        summary.reFetch();
        setalredyaddedDialog1(false);
        addToCard(props.information, id);
        setAddToCardDliaog(true);
        setTimeout(() => {
          setAddToCardDliaog(false);
        }, 10000);
      }
      if (err) {
        setLoading(false);
        console.log(err.response);
        if (err.response.status === 403) {
          autherror();
        } else if (err.response.data.data === "course already exists") {
          // allreadyerror();
          // handlerefresh1();
          addToCard(props.course, id);
          setAddToCardDliaog(true);
          setalredyaddedDialog(true);
          setTimeout(() => {
            setAddToCardDliaog(false);
            setalredyaddedDialog(false);
          }, 10000);
        }
      }
    },
  });
  const handleChange = (event) => {
    setDegreeid(event.target.value);
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  let history = useHistory();
  const [contenttitle, setContenttitle] = useRecoilState(textState2);
  const checkUserPassSeason = useAxios({
    url: `${API_URL}/content/what_is_latest_content_id_user_passed/${id}/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        userDispatch({
          type: "REFRESH",
          testtt: res.data.latest_content_id,
        });
        setContenttitle(res.data.latest_content_title);
        contentIdCourse1.reFetch();
      }
      if (err) {
        contentIdCourse.reFetch();
      }
    },
  });
  const contentIdCourse = useAxios({
    url: `${API_URL}/sidebar/${id}/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        userDispatch({
          type: "REFRESH",
          testtt: res.data.seasons[0].contents[0].id,
        });
        setContenttitle(res.data.seasons[0].contents[0].title);
        // setList(res.data.seasons);
        history.push({
          pathname: `/courcontent`,
          state: {
            id: id,
            title: contenttitle,
          },
        });
      }
      if (err) {
        console.log(err);
      }
    },
  });
  const contentIdCourse1 = useAxios({
    url: `${API_URL}/sidebar/${id}/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        // setList(res.data);
        history.push({
          pathname: `/courcontent`,
          state: {
            id: id,
            title: contenttitle,
          },
        });
      }
      if (err) {
        console.log(err);
      }
    },
  });
  const handleCurse = () => {
    checkUserPassSeason.reFetch();
    props.setLoader(true);
  };
  const addtowishlist = () => {
    setIsFavorite1((prevState) => {
      return { isFavorite: !prevState.isFavorite };
    });

    addtowishlistrefetch.reFetch();
  };
  const addtowishlistrefetch = useFetch({
    url: `willing/${id}/post`,
    method: "POST",
    shouldlogin: true,
    data: isFavorite1,
  });
  return (
    <>
      {SideBar.response?.data ? (
        <>
          <tr>
            <th className="CourseInfoTableContent__Row  CourseInfoTableContent__firstRow">
              <p>{information.title}</p>
              {token !== null && (
                <div>
                  {information.has_user_course ? (
                    <div>
                      {/* <i className="far fa-bookmark fa-bookmark1"></i> */}
                    </div>
                  ) : !isFavorite1.isFavorite ? (
                    <i className="far fa-bookmark " onClick={addtowishlist}></i>
                  ) : (
                    <i className="fas fa-bookmark" onClick={addtowishlist}></i>
                  )}
                </div>
              )}
            </th>
          </tr>
          <tr>
            <th className="CourseInfoTableContent__Row CourseInfoTableContent__SecondRow">
              <div className="CourseInfoTableContent__SecondRow-div">
                <img
                  src={information.costs[degreeid].degree_pic}
                  alt={information.costs[degreeid].degree_pic}
                />
              </div>

              <div>
                <p>{information.costs[degreeid].info}</p>
                <p>{information.costs[degreeid].sub_info}</p>
              </div>
            </th>
          </tr>
          <tr>
            <th className="CourseInfoTableContent__Row CourseInfoTableContent__ThirdRow">
              <p>:سختی دوره</p>
              <p>{information.level}</p>
            </th>
          </tr>
          <tr>
            <th className="CourseInfoTableContent__Row CourseInfoTableContent__ForthRow">
              <div>
                <i className="fas fa-star"></i>
                <p>{information.mean_of_participant_points.grade}</p>
              </div>
              <div className="CourseInfoTableContent__Line"></div>
              <div>
                <img src={user} alt="user" />
                <p>{information.num_of_participants}</p>
              </div>
            </th>
          </tr>
          <tr>
            <th className="CourseInfoTableContent__Row CourseInfoTableContent__FifthRow">
              <i className="far fa-clock"></i>
              <p>{information.total_time_of_course} </p>
            </th>
          </tr>{" "}
          <tr>
            <>
              {information.costs[degreeid].percentage_discount !== null ? (
                <div className="course-card__details">
                  {information.costs[degreeid].percentage_discount !== null ? (
                    <div className="PriceSection PriceSection__firstRow">
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Select
                          labelId="demo-controlled-open-select-label"
                          id="demo-controlled-open-select"
                          open={open}
                          onClose={handleClose}
                          onOpen={handleOpen}
                          defaultValue={degreeid}
                          onChange={handleChange}
                          style={{ color: "#f3722c" }}
                          IconComponent={Chevron}
                        >
                          {information.costs
                            .filter(function (item) {
                              return item.degree !== null;
                            })
                            .map((item, id) => (
                              <MenuItem value={id} style={{ color: "#f3722c" }}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {information.costs[degreeid].discount_amount !== null && (
                <div className="price__box1">
                  <div className="">
                    {information.costs[degreeid].percentage_discount !== null &&
                    information.costs[degreeid].percentage_discount !== 0 ? (
                      <div className="price-Off1">
                        <button>
                          {information.costs[degreeid].percentage_discount}%
                        </button>
                      </div>
                    ) : null}
                    {information.costs[degreeid].amount !== null && (
                      <p
                        className="real__cost1"
                        style={{
                          textDecoration: "line-through",
                        }}
                      >
                        {information.costs[degreeid].percentage_discount !==
                          0 && information.costs[degreeid].amount}
                      </p>
                    )}
                  </div>

                  <div className="price-box1">
                    <div className="price">
                      <div className="PriceSection__PriceBox1">
                        <span>تومان</span>
                        {information.costs[
                          degreeid
                        ].discount_amount.toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </tr>
          {/* <tr>
            <th className="PriceSection PriceSection__firstRow">
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  defaultValue={degreeid}
                  onChange={handleChange}
                  style={{color: '#f3722c'}}
                  IconComponent={Chevron}
                >
                  {information.costs
                    .filter(function (item) {
                      return item.degree !== null;
                    })
                    .map((item, id) => (
                      <MenuItem value={id} style={{color: '#f3722c'}}>
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <div className="price__box">
                <div className="">
                  {information.costs[degreeid].percentage_discount !== null ? (
                    <div className="price-Off1">
                      <button>
                        {information.costs[degreeid].percentage_discount}%
                      </button>
                    </div>
                  ) : null}
                  {information.costs[degreeid].amount !== null && (
                    <p
                      className="real__cost"
                      style={{
                        textDecoration: 'line-through',
                      }}
                    >
                      {information.costs[degreeid].amount}
                    </p>
                  )}
                </div>
              </div>
            </th>
          </tr> */}
          {/* <tr className="PriceSection PriceSection__SecondRow">
            <th className="price-box">
              <div className="price">
                <div className="PriceSection__PriceBox">
                  <span>تومان</span>
                  {information.costs[degreeid].discount_amount}
                </div>
              </div>
            </th>
          </tr> */}
          <tr>
            <th className="PriceSection1 PriceSection__ThirdRow">
              {information.has_user_course ? (
                <div>
                  {" "}
                  <p style={{ color: "#fe7f2d" }}>
                    این دوره قبلا خریداری شده است
                  </p>
                  <button
                    style={{ margin: "10px 0" }}
                    onClick={handleCurse}
                    className="BtnSecondary"
                  >
                    ورود به دوره
                  </button>
                </div>
              ) : (
                <div className="shop" onClick={handleClick}>
                  <BtnSecondarybig className="PriceSection__Button">
                    {loading ? (
                      <ClipLoader
                        color="#ffffff"
                        loading={loading}
                        css={override}
                        size={15}
                      />
                    ) : (
                      <>
                        <img src={ADDTOPackage} alt="ADDTOPackage" />
                        <p>افزودن به سبد خرید</p>
                      </>
                    )}
                  </BtnSecondarybig>
                </div>
              )}
            </th>
          </tr>
        </>
      ) : (
        <ClipLoader color="#43aa8b" loading={true} css={override} size={60} />
        // <p>Tessstttttttttttttttt</p>
      )}
    </>
  );
}

export default CourseInfoTableContent;

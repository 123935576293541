import React, { useState } from "react";
import MycourseMainPanel from "../components/MyCourse/MycourseMainPanel";
import MycourseSidebar from "../components/MyCourse/MycourseSidebar";
import Navigation from "../components/share/Navigation";
import PhoneNavigation from "../components/share/PhoneNavigation";
import UseWindowSize from "../Sizes/UseWindowSize";

const MyCourse = () => {
  const windowSize = UseWindowSize();

  return (
    <div>
      <div className="MyCourse__background">
        {windowSize === "sm" || windowSize === "md-2" ? (
          <div className="MyCourse__container container">
            <MycourseSidebar />
            <MycourseMainPanel  />
         
          </div>
        ) : (
          <div className="MyCourse__container container">
            <MycourseMainPanel />
            <MycourseSidebar  />
          </div>
        )}
      </div>
    </div>
  );
};

export default MyCourse;

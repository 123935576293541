import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import {makeStyles} from '@material-ui/core/styles';
import '../../css/main.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

function PaginationBox(props) {
  const classes = useStyles();
  return (
    <div className="Pagination ">
      <Pagination count={props.count} shape="rounded" />
    </div>
  );
}

export default PaginationBox;

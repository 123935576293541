import React, { useContext, useEffect, useRef, useState } from "react";
import "../../css/main.scss";
import { UserCommentsection } from "./UserCommentsection";
import { AdminCommentsection } from "./UserCommentsection";
import CommentBox from "../Common/CommentBox";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../constacts";
import { useAuth } from "../../context/AuthContext";
import { DraftContext } from "../../context/DraftComment";

function UserComment(props) {
  const [page, setPage] = useState(1);
  let [loading, setLoading] = useState(true);
  const [cm, setCM] = useState([]);
  const { token } = useAuth();
  const { getDraftcomment, draftCM } = useContext(DraftContext);
  const [edittext, setEditText] = useState(false);
  useEffect(() => {
    getDraftcomment.reFetch();
    getcomment.reFetch();

  }, [edittext]);
  
  const getcomment = useAxios({
    url: `${API_URL}/comment/course_comments/${props.id}/`,
    method: "GET",
    customHandler: (err, res) => {
      if (res) {
        console.log("rescm", res.data.results);
        setCM(res.data.results);
        setLoading(false);
        

      }
      if (err) {
        console.log(err.response);
      }
    },
  });

  const GetCommentList = () => {
    getcomment.reFetch();
  };
  return (
    <div>
      {draftCM.length !== 0 || cm.length !== 0 ? (
        <div>
          <div
            className="corseInfo__head__propertyBox "
            style={{ marginTop: "5.8rem" }}
          >
            <svg
              width="43%"
              height="50"
              viewPort="0 0 200 300"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line className="stroke" x1="40" y1="30" x2="620" y2="30"></line>
            </svg>
            <p className="corseInfo__head__propertyTitle">دیدگاه دیگران</p>
            <svg
              width="43%"
              height="50"
              viewPort="0 0 200 300"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line className="stroke" x1="10" y1="30" x2="620" y2="30"></line>
            </svg>
          </div>
          <div>
            {cm !== null
              ? cm.map((i) => (
                  <CommentBox>
                    <UserCommentsection
                      setEditText={setEditText}
                      i={i}
                      edittext={edittext}
                      GetCommentList={GetCommentList}
                    />
                  </CommentBox>
                ))
              : null}
            {draftCM !== null
              ? draftCM.map((i) => (
                  <CommentBox draft={true}>
                    <UserCommentsection
                      i={i}
                      draft={true}
                      setEditText={setEditText}
                      edittext={edittext}
                      GetCommentList={GetCommentList}
                    />
                  </CommentBox>
                ))
              : null}
          </div>

          {cm.page_count > page ? (
            <>
              <div className="CourseInfo__endBtn">
                <i className="fas fa-chevron-down"></i>
                <p>مشاهده بیشتر</p>
              </div>
              {setPage(page + 1)}
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

export default UserComment;

//height: 137px;

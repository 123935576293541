import React, { useContext, useEffect } from "react";
import CourseListSec from "../components/CourseList/CourseListSec";
import CouseListHeader from "../components/CourseList/CouseListHeader";
import PinkbackSection from "../components/share/PinkbackSection";
import FeedbackForm from "../components/share/FeedbackForm";
import UserFeedbackList from "../components/CourseList/UserFeedbackList";
import Footer from "../components/share/Footer";
import InfoSideBar from "../components/courseInfo/InfoSideBar/InfoSideBar";
import { CardCourses } from "../context/CardCourseContext";

const Courselist = ({ addToCard }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <CouseListHeader />
      <CourseListSec />
      <PinkbackSection />
      {/* <UserFeedbackList /> */}
      <Footer />
    </div>
  );
};

export default Courselist;

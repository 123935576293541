import React from "react";
import { BtnSecondary } from "../share/Btn";
import { NavLink } from "react-router-dom";
import "../../css/main.scss";

const ShowCase = (props) => {
  return (
    <div className="showcase">
      <div className="showcase__pic">
        <img src={props.homeShowcaseImage} alt="showcase pic" />
      </div>
      <div className="showcase__content">
        <h1 className="h-1">
          <span>{props.titleFirstSpan}</span>
          <span>{props.titleSecondSpan}</span>
        </h1>
        {props.children}
        <BtnSecondary className="ShowCase__BtnBox">
          <NavLink
            exact
            activeClassName="is-active"
            to="/CourseList"
            // className="nav__list--link"
          >
            <div className="ShowCase__Btn">
              <i className="fas fa-chevron-left"></i>
              <p>همین حالا شروع کن</p>
            </div>
          </NavLink>
        </BtnSecondary>
      </div>
    </div>
  );
};

export default ShowCase;

import React, {useEffect, useState} from 'react';
import {API_URL} from '../../constacts';
import useAxios from '@use-hooks/axios';
import {useHistory} from 'react-router';

function EmailVerify(props) {
  const [ke, setKe] = useState({key: ''});
  const history = useHistory()
  useEffect(() => {
    setKe({key: props.confirmKey});
    EmailVerifes.reFetch();
  }, []);
  const [respon, setRespon] = useState('');
  const EmailVerifes = useAxios({
    url: `${API_URL}/account-confirm-email/${props.confirmKey}/`,
    method: 'POST',
    options: {
      data: ke,
      headers: {
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log('res', res);
        setRespon('ایمیل با موفقیت تایید شد');
        history.push('/logIn')

      }
      if (err) {
        setRespon('ایمیل تایید نشد');
        console.log('error', err);
        history.push('/')

      }
    },
  });

  return (
    <div
    className='EmailVerify'
    onClick={()=>history.push("/logIn")}
      // style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
    >
      <h1>{respon}</h1>
    </div>
  );
}

export default EmailVerify;

import React, { useState, useRef, useEffect } from "react";
import Chevron from "../Common/Chevron";
import padlock from "../../img/courseinfo/Sarfasl/padlock.png";
import "../../css/main.scss";
import DarsAcoordionDetail from "./DarsAcoordionDetail";
import { getThemeProps } from "@material-ui/styles";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../constacts";
import "video-react/dist/video-react.css";
import { useAuth } from "../../context/AuthContext";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";

function DarsContentAccordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");
  const [loading, setloading] = useState(false)
  const [detail, setDetail] = useState(null);

  const content = useRef(null);
  const { token } = useAuth();
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: #43aa8b;
`;
  const getContentsDetail = useAxios({
    url: `${API_URL}/course_content/lite/${props.info.id}`,
    method: "GET",
    options: {
      headers: token
        ? {
            Authorization: `JWT ${token}`,
          }
        : {},
    },

    customHandler: (err, res) => {
      if (res) {
        console.log("res", res.data);
        setDetail(res.data);
        setloading(false)
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  function toggleAccordion() {
    if (!props.lockedOn && detail === null){
      setloading(true)
      getContentsDetail.reFetch()
    }
    if (!props.lockedOn) {
     

      setActiveState(setActive === "" ? "active" : "");
      setHeightState(
        setActive === "active" ? "0px" : `100%`
      );
      setRotateState(
        setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
      );
    }
    
  }
  useEffect(() => {
  }, []);
  return (
    <>
      {props.info !== null ? (
        <div className="SarFaslBoxContent DarsContentAccordion">
          <button
            className={`accordioninfo ${setActive}` }
            onClick={toggleAccordion}
            disabled={props.info.lock}
            style={
              props.info.lock ? { cursor: "not-allowed" } : { cursor: "pointer" }
            }
          >
            {!props.info.lockedOn ? (
              <div>
              {
                loading ?
                <ClipLoader
                loading={loading}
                css={override}
                size={15}
              />:
                 <Chevron className={`${setRotate}`} width={15} fill={"#000"} />
              }
             </div>
            ) : (
              <img alt="padlock" src={padlock}></img>
            )}

            <div style={{ width: "100%" }}>
              <div className="InfoSarfaslListBox__withIco">
                <div className="InfoSarfaslListIcoBox">
                  <i className="far fa-play-circle"></i>
                </div>
                <div className="InfoSarfaslListBox">
                  <label id="lable" className="InfoSarfaslList__label">
                    {props.info.title}
                  </label>
                  <div className="InfoSarfaslList__selectBox">
                    <div className="InfoSarfaslList__lessonBox">
                      <div className="InfoSarfaslList__houer">
                        {props.info.duration_time}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </button>

          <div
            ref={content}
            style={{ maxHeight: `${setHeight}` }}
            className="accordion__content"
          >
            <div className="accordion__content-box">
              <div style={{ textAlign: "right" }}>
                <div className="accordion__text">
                  {props.info.lockedOn !== true && (
                    <DarsAcoordionDetail
                      Coursetitle={props.Coursetitle}
                      darstitle={props.info.title}
                      seasontitle={props.seasontitle}
                      detail={detail}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default DarsContentAccordion;
{/*  */}

import React, { useEffect, useState } from "react";
import gmail from "../../../img/courseinfo/ic_mail_black_24dp.png";
import whatsapp from "../../../img/courseinfo/iconmonstr-whatsapp-1.png";
import telegram from "../../../img/courseinfo/telegram-brands.png";
import linkdin from "../../../img/courseinfo/linkedin.png";
import twitter from "../../../img/courseinfo/twitter.png";
import facebook from "../../../img/courseinfo/facebook-icon.png";
import virgool from "../../../img/courseinfo/virgool.png";
import { BtnSecondary } from "../../share/Btn";
import DefaultBox from "../../Common/DefaultBox";
import "../../../css/main.scss";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../../constacts";
import { useLocation } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: "12px",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip placement="top" arrow classes={classes} {...props} />;
}
const Mediacomponent = ({ index, i }) => {
  const [copied, setCopied] = useState(false);
  const handleTooltipClose = () => {
    setCopied(false);
  };

  const handleTooltipOpen = () => {
    setCopied(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className="media-circle">
        <BootstrapTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={copied}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="کپی شد"
        >
          <CopyToClipboard
            text={index.social_url}
            onCopy={() => setCopied(true)}
          >
            <img
              src={
                index.social_name === "Telegram"
                  ? telegram
                  : index.social_name === "Email"
                  ? gmail
                  : index.social_name === "FaceBook"
                  ? facebook
                  : index.social_name === "Twitter"
                  ? twitter
                  : index.social_name === "WhatsApp"
                  ? whatsapp
                  : index.social_name === "Virgool"
                  ? virgool
                  : index.social_name === "LinkedIn"
                  ? linkdin
                  : null
              }
              alt={index.social_name}
              className={`shareIco shareIco-${i}`}
            />
          </CopyToClipboard>
        </BootstrapTooltip>
      </div>
    </ClickAwayListener>
  );
};
function MediaShare() {
  const [id, setId] = useState("");
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);
  const [socailList, setSocialList] = useState(null);
  const [socailimg, setsocialimg] = useState(null);
  const social = useAxios({
    url: `${API_URL}/social/${id}/`,
    method: "GET",

    customHandler: (err, res) => {
      if (res) {
        setSocialList(res.data.results);
        console.log(res);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  useEffect(() => {
    social.reFetch();
  }, []);

  return (
    <div>
      <DefaultBox className="InfoSideBarPriseBox">
        <table className="InfoSideBarPriseTable">
          <thead>
            <tr>
              <th className="priceHeader__line">
                <p> اشتراک گذاری این دوره</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {socailList !== null ? (
                  <div className="shareIco__Box">
                    {socailList.map((index, i) => (
                      <Mediacomponent index={index} i={i} />
                    ))}
                  </div>
                ) : null}
              </td>
            </tr>
            <tr>
              <th className="PriceSection1 PriceSection__ThirdRow">
                <BtnSecondary className="MediaShare__Button">
                  <i className="fas fa-link"></i>
                  <p>کپی لینک</p>
                </BtnSecondary>
              </th>
            </tr>
          </tbody>
        </table>
      </DefaultBox>
    </div>
  );
}

export default MediaShare;

const MediaShareIcon = [
  { img: facebook },
  { img: twitter },
  { img: linkdin },
  { img: telegram },
  { img: whatsapp },
  { img: gmail },
  { img: virgool },
];

import React from "react";

function PhoneChevron(props) {
  return (
    <div>
      <svg
        className={props.className}
        height={props.height}
        width={props.width}
        viewBox="0 0 320 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 34.52 239.03 L 228.87 44.69 c 9.37 -9.37 24.57 -9.37 33.94 0 l 22.67 22.67 c 9.36 9.36 9.37 24.52 0.04 33.9 L 131.49 256 l 154.02 154.75 c 9.34 9.38 9.32 24.54 -0.04 33.9 l -22.67 22.67 c -9.37 9.37 -24.57 9.37 -33.94 0 L 34.52 272.97 c -9.37 -9.37 -9.37 -24.57 0 -33.94 Z"
          fill={props.fill}
        />
      </svg>
    </div>
  );
}

export default PhoneChevron;

import React, { createContext, useState, useEffect } from "react";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../constacts";
import { useAuth } from "../context/AuthContext";
import { useLocation } from "react-router-dom";
import { useList } from "./SeasonList";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { textState, textState1, textState2 } from "../Recoil/RecoilState";
export const ContentSidebarContext = createContext();

const ContentSidebarProvider = (props) => {
  const location = useLocation();
  const [id, setId] = useState("");
  useEffect(() => {
    setId(location.state.id);
  }, [location]);
  const { token } = useAuth();
  const [seasonlist, setseasonlist] = useState(null);
  const [seasonlist1, setseasonlist1] = useState(null);
  const [seasonlist2, setseasonlist2] = useState([]);
  const [list, setList] = useRecoilState(textState1);
  const [contentid, setContentid] = useRecoilState(textState);
  const [contenttitle, setContenttitle] = useRecoilState(textState2);
  // const [contentid, setContentid] = useState(null);
  // console.log("seasonlist", seasonlist);
  const getSidebarSesons = useAxios({
    url: `${API_URL}/sidebar/${id}/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("reslist", res.data);
        setseasonlist(res.data);
        // setList(res.data);
        setList(res.data);
        setseasonlist1(res.data.seasons[0].contents);
        setseasonlist2(res.data.seasons);
        // checkUserPassSeason.reFetch();
      }
      if (err) {
        console.log(err.response);
      }
    },
  });

  // const checkUserPassSeason = useAxios({
  //   url: seasonlist
  //     ? `${API_URL}/content/what_is_latest_content_id_user_passed/${seasonlist.id}/`
  //     : null,
  //   method: "GET",
  //   options: {
  //     headers: {
  //       Authorization: `JWT ${token}`,
  //     },
  //   },
  //   customHandler: (err, res) => {
  //     if (res) {
  //       setContentid(res.data.latest_content_id);
  //     }
  //     if (err) {
  //       setContentid(list[0].Content[0].id);
  //     }
  //   },
  // });
  return (
    <ContentSidebarContext.Provider
      value={{
        getSidebarSesons,
        seasonlist,
        seasonlist2,
        // setContentid,
        seasonlist1,
      }}
    >
      {props.children}
    </ContentSidebarContext.Provider>
  );
};

export { ContentSidebarProvider };

import React, {
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Accordion from "../Common/Accordion";
import "../../css/main.scss";
import { useLocation } from "react-router";
import { ContentSidebarContext } from "../../context/ContentSidebarContext";
import { useRecoilState } from "recoil";
import { textState } from "../../Recoil/RecoilState";
function SarfaslTable(props) {
  const [id, setId] = useState("");
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);

  const { getSidebarSesons, seasonlist } = useContext(ContentSidebarContext);
  const [contentid, setContentid] = useRecoilState(textState);
  const firstUpdate = useRef(true);
  // useLayoutEffect(() => {
  //   if (firstUpdate.current) {
  //     firstUpdate.current = false;
  //     return;
  //   }
  //   console.log("here");
  // }, [contentid]);
  useEffect(() => {
    getSidebarSesons.reFetch();
  }, [contentid]);
  return (
    <div>
      {seasonlist !== null ? (
        <div className="mycourse-defaultbox">
          <div className="mycourse-defaultheader">سرفصل ها</div>
          <div className="mycourse-defaulbody corsecontentbody">
            {seasonlist !== null
              ? seasonlist.seasons.map((i) => <Accordion key={i.id} info={i} />)
              : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default SarfaslTable;

import React from "react";
import FeatureSec from "../components/home/FeatureSec";
import HomeHeader from "../components/home/HomeHeader";
import { HowSec } from "../components/home/HowSec";
import LastnewsSec from "../components/home/LastnewsSec";
import FeedbackForm from "../components/share/FeedbackForm";
import Footer from "../components/share/Footer";

const Home = () => {
  return (
    <div>
      <HomeHeader />
      <HowSec />
      <FeatureSec />
      <LastnewsSec />
      <FeedbackForm />
    </div>
  );
};


export default Home;

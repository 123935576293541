import React, { useContext, useEffect, useState } from "react";
import user1 from "../../img/courseinfo/12790.png";
import admin from "../../img/courseinfo/همراه کد اموز.png";
import moment from "jalali-moment";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../constacts";
import { GetUserInfoContext } from "../../context/GetUserInfoContext";
import EditCommentForm from "./EditCommentForm";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";
import UserReply from "./UserReply";
import { DraftContext } from "../../context/DraftComment";
import EditReplyForm from "./EditReplyForm";

function UserCommentsection(props) {
  const [height, setHeight] = useState(false);
  const { getDraftcomment } = useContext(DraftContext);

  const [reply, setReply] = useState([]);
  const [draftreply, setdraftReply] = useState([]);
  const [draftrep, sedraftrep] = useState(false);
  const [replytoggle, setReplyToggle] = useState(false);
  const { id, username, text, date_created, user_picture, has_reply } = props.i;
  const [replyform, setreplyform] = useState(false);
  const { token } = useAuth();

  const getcommentreply = useAxios({
    url: `${API_URL}/reply/${props.i.id}/list/`,
    method: "GET",
    customHandler: (err, res) => {
      if (res) {
        setReply(res.data.results);
      }
      if (err) {
        console.log("err", err);
      }
    },
  });

  const deletecomment = useAxios({
    url: `${API_URL}/comment/${props.i.id}/`,
    method: "DELETE",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("delcm", res.data);
        deleteSuccess();
        props.GetCommentList();
        getDraftcomment.reFetch();
        setremove(false);
      }
      if (err) {
        console.log("err", err);
      }
    },
  });

  const deletefunc = () => {
    deletecomment.reFetch();
  };
  const refetchreplyagain = () => {
    getdraftcommentreply.reFetch();
    getcommentreply.reFetch();
  };
  const getdraftcommentreply = useAxios({
    url: `${API_URL}/reply/${props.i.id}/draft/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        setdraftReply(res.data.results);
      }
      if (err) {
        console.log("err", err.response);
      }
    },
  });
  const getdraftreply = () => {
    getdraftcommentreply.reFetch();
  };
  const [remove, setremove] = useState(false);
  const deleteCM = () => {
    setremove(true);
  };
  const [edit, setEdit] = useState(false);

  const { userinfo, getUserInfo } = useContext(GetUserInfoContext);

  const [time, setTimetest] = useState("");
  useEffect(() => {
    getUserInfo.reFetch();
    const dayAfterEpoch = new Date(date_created);
    var year = dayAfterEpoch.getFullYear();
    var day = dayAfterEpoch.getDate();
    var mountt = dayAfterEpoch.getMonth() + 1;
    if (mountt < 10) {
      var mount = `0${mountt}`;
    } else {
      var mount = mountt;
    }
    var aa = `${year}-${mount}-${day}`;

    setTimetest(moment(aa, "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD"));
  }, []);

  const togglereply = () => {
    setReplyToggle(!replytoggle);
    getcommentreply.reFetch();
    getdraftcommentreply.reFetch();
  };
  return (
    <>
      {!edit ? (
        <>
          <div className="UserCommentsectionBox">
            <div className="UserCommentsectionHeadrBox">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  alignItems: "center",
                }}
              >
                {user_picture === null ? (
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      backgroundColor: "#eeeeee",
                    }}
                  ></div>
                ) : (
                  <img
                    src={user_picture}
                    className="UserCommentsectionHeadrIco"
                    alt=""
                  />
                )}
                <p className="UserCommentsectionHeadrTitle">{username}</p>
              </div>
              {props.draft && <p>کامنت شما در انتظار تایید است </p>}

              <div className="d-flex">
                <p className="UserCommentsectionHeadrTime">{time}</p>
                {userinfo !== null && (
                  <>
                    {userinfo.username === username ? (
                      <div className="d-flex">
                        <div onClick={() => setEdit(true)}>
                          <i
                            style={{ color: "#878c91", marginLeft: "6px" }}
                            class="fas fa-edit"
                          ></i>
                        </div>
                        <div onClick={deleteCM}>
                          <i
                            style={{ color: "#878c91", marginLeft: "6px" }}
                            class="fas fa-trash"
                          ></i>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
            <div className="UserCommentsectionContentBox">
              <p className="UserCommentsectionContent">{text}</p>
            </div>
          </div>
          <div className="reply__box">
           {!props.draft ? <div
              className="reply__icon"
              onClick={() => setreplyform(!replyform)}
            >
              <i class="fas fa-comment-dots"></i>
              <p>پاسخ</p>
            </div> : null}
            {has_reply ? (
              <div>
                <p
                  onClick={togglereply}
                  style={{ cursor: "pointer", margin: "5px" }}
                >
                  {!replytoggle ? "نمایش پاسخ ها" : "مخفی کردن پاسخ ها"}
                </p>
              </div>
            ) : null}
          </div>
          <div>
            {replyform && (
              <UserReply
                setreplyform={() => setreplyform(false)}
                id={props.i.id}
                getdraftreply={getdraftreply}
              />
            )}
          </div>

          <div>
            {replytoggle === true ? (
              <div className="Reply__toggle">
                {reply.map((i) => (
                  <AdminCommentsection
                    i={i}
                    userinfo={userinfo}
                    username={username}
                    refetchreplyagain={refetchreplyagain}
                  />
                ))}
                {draftreply !== null && (
                  <div>
                    {draftreply.map((i) => (
                      <AdminCommentsection
                        i={i}
                        draftrep={true}
                        userinfo={userinfo}
                        username={username}
                        refetchreplyagain={refetchreplyagain}
                      />
                    ))}
                  </div>
                )}
              </div>
            ) : null}
          </div>
          {remove ? (
            <DeleteModal
              id={props.i.id}
              setremove={() => setremove(false)}
              edittext={props.edittext}
              deletefunc={deletefunc}
            />
          ) : null}
        </>
      ) : (
        <EditCommentForm id={id} text={text} setEdit={setEdit} />
      )}
    </>
  );
}

export { UserCommentsection };

const answer = [
  {
    img: admin,
    title: "کابرشماره یک",
    time: "20 اردیبهشت 1398 ",
    txt:
      "لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود.",
  },
];

function AdminCommentsection(props) {
  const { username, text, date_created, user_picture, id } = props.i;
  const [time, setTimetest] = useState("");
  const [Edit, setEdit] = useState(false);
  const [remove, setremove] = useState(false);
  const { token } = useAuth();
  const deletecomment = useAxios({
    url: `${API_URL}/reply/${id}/`,
    method: "DELETE",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("delcm", res.data);

        deleteSuccess();
        props.refetchreplyagain();
        setremove(false);
      }
      if (err) {
        console.log("err", err);
      }
    },
  });
  const deleteCM = () => {
    setremove(true);
  };
  const deletefunc = () => {
    deletecomment.reFetch();
  };
  useEffect(() => {
    const dayAfterEpoch = new Date(date_created);
    var year = dayAfterEpoch.getFullYear();
    var day = dayAfterEpoch.getDate();
    var mountt = dayAfterEpoch.getMonth() + 1;
    if (mountt < 10) {
      var mount = `0${mountt}`;
    } else {
      var mount = mountt;
    }
    var aa = `${year}-${mount}-${day}`;

    setTimetest(moment(aa, "YYYY/MM/DD").locale("fa").format("YYYY/MM/DD"));
  }, []);
  return (
    <>
      {!Edit ? (
        <div
          className="UserCommentsectionBox AdminCommentsection"
          style={props.draftrep && { backgroundColor: "#9fc0b67d" }}
        >
          {answer.map((item, index) => (
            <>
              <div className="UserCommentsectionHeadrBox">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={user_picture}
                    alt={index}
                    style={{
                      width: "3.5rem",
                      height: "3.5rem",
                      borderRadius: "50%",
                    }}
                  ></img>

                  <p className="UserCommentsectionHeadrTitle">{username}</p>
                </div>
                {props.draftrep && <p>کامنت شما در انتظار تایید است </p>}

                <div className="d-flex">
                  <p className="UserCommentsectionHeadrTime">{time}</p>
                  {props.userinfo !== null && (
                    <>
                      {props.userinfo.username === username ? (
                        <div className="d-flex">
                          <div onClick={() => setEdit(true)}>
                            <i
                              style={{ color: "#878c91", marginLeft: "6px" }}
                              class="fas fa-edit"
                            ></i>
                          </div>
                          <div onClick={deleteCM}>
                            <i
                              style={{ color: "#878c91", marginLeft: "6px" }}
                              class="fas fa-trash"
                            ></i>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
              <div className="UserCommentsectionContentBox">
                <p className="UserCommentsectionContent">{text}</p>
              </div>
            </>
          ))}
          {remove ? (
            <DeleteModal
              id={props.i.id}
              setremove={() => setremove(false)}
              edittext={props.edittext}
              deletefunc={deletefunc}
            />
          ) : null}
        </div>
      ) : (
        <EditReplyForm
          setEdit={setEdit}
          text={text}
          id={id}
          refetchreplyagain={props.refetchreplyagain}
        />
      )}
    </>
  );
}

export { AdminCommentsection };
const deleteSuccess = () => {
  toast.success("نظر شما با موفقیت پاک شد", {
    position: toast.POSITION.TOP_CENTER,
  });
};

const DeleteModal = (props) => {
  const removecoment = () => {
    props.deletefunc();
  };
  return (
    <div className="DeleteModal__back">
      <div className="DeleteModal__container">
        <div onClick={props.setremove} className="DeleteModal__close">
          <i class="fas fa-times"></i>
        </div>
        <h3>آیا از حذف کامنت مطمئن هستید؟</h3>
        <div className="center">
          <button className="DeleteModal__btn" onClick={props.setremove}>
            خیر
          </button>
          <button
            className="DeleteModal__btn DeleteModal__btn--yes"
            onClick={removecoment}
          >
            بله
          </button>
        </div>
      </div>
    </div>
  );
};

import React, {useState, useRef, useEffect} from 'react';
import PhoneChevron from './PhoneChevron';
import user1 from '../../img/courseinfo/12790.png';
import PhoneAccordion from './PhoneAccordion';
import CourseInfoAccordion from '../courseInfo/CourseInfoAccordion';
import '../../css/main.scss';

function PhoneAccordionSarfasl(props) {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setHeight1, setHeightState1] = useState('0px');
  const [setRotate, setRotateState] = useState('PhoneAccordion__icon');
  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'phoneactive' : '');

    setHeightState(setActive === 'phoneactive' ? '0px' : `100%`);
    setRotateState(
      setActive === 'phoneactive'
        ? 'PhoneAccordion__icon'
        : 'PhoneAccordion__icon Phonerotate'
    );
  }
  return (
    <div className="PhoneAccordion__section">
      <button
        className={`PhoneAccordion ${setActive}`}
        onClick={toggleAccordion}
      >
        <PhoneChevron className={`${setRotate}`} width={10} fill={'#43AA8B'} />

        <p className="PhoneAccordion__title">{props.title}</p>
      </button>
      {/* {props.content.map((index, i) => (
        <div
          ref={content}
          style={{maxHeight: `${setHeight}`}}
          className="PhoneAccordion__content"
        >
          {index.lockedOn === false ? (
            <PhoneAccordion
              title={index.title}
              content={index.contents}
              sar={true}
            />
          ) : (
            <div
              className={
                props.comment
                  ? 'PhoneAccordion__Commentcontent'
                  : 'PhoneAccordion__contentBox'
              }
            >
              <div
                className="PhoneAccordion__text"
                dangerouslySetInnerHTML={{__html: index.title}}
              />
            </div>
          )}
        </div>
      ))} */}
      {props.content !== null
        ? props.content.map((i) => (
            <div
              ref={content}
              style={{maxHeight: `${setHeight}`}}
              className="PhoneAccordion__content"
            >
              <CourseInfoAccordion Coursetitle={props.Coursetitle} info={i} />
            </div>
          ))
        : null}
    </div>
  );
}

export default PhoneAccordionSarfasl;
const PhoneDescriptionInfo = [
  {
    titr: 'لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به  عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری',
  },
];

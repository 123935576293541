import React, {
  useRef,
  useLayoutEffect,
  useState,
  useEffect,
  useContext,
} from "react";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import ShowCourses from "./ShowCourses";
import PaginationBox from "../Common/Pagination";
import { useAuth } from "../../context/AuthContext";
import "../../css/main.scss";
import ClipLoader from "react-spinners/ClipLoader";
import { CardCourses } from "../../context/CardCourseContext";
import { css } from "@emotion/core";
import AddToCardDialog from "../share/AddToCardDialog";

const CourseListSec = () => {
  const [searchIcon, setsearchIcon] = useState("");
  const {

    cardCourse,
    addToCardDliaog,
  } = useContext(CardCourses);
  const [information, setInformation] = useState("");
  const [page, setPage] = useState();
  const [loading, setloading] = useState(true);
  const { token } = useAuth();
  const [scrollPosition, setPosition] = useState(0);
  const sidebar = useRef(null);
  useEffect(() => {
    allcourse.reFetch();
  }, []);
  const togglefiltersearch = () => {
    if (searchIcon === "") {
      setsearchIcon("search-active");
    } else {
      setsearchIcon("");
    }
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: green;
  `;
  const allcourse = useAxios({
    url: `${API_URL}/courses/all/`,
    method: "GET",
    options: {
      headers: token
      ? {
          Authorization: `JWT ${token}`,
        }
      : {},
    },
    customHandler: (err, res) => {
      if (res) {
        setInformation(res.data.results);
        setPage(res.data.page_count);
        setloading(false);
        console.log("allcourse", res.data.results);
      }
      if (err) {
        console.log(err.response);
        // if (err.response.status === 401) {
        //   handlerefresh();
        // }
        setloading(false);
      }
    },
  });
  //have a fix sidebar
  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);
  let fixclass = "";
  let navfixclass = "";
  if (scrollPosition > 500 && scrollPosition < 1650) {
    fixclass = "fix";
    // navfixclass = "nav-fix";
  } else {
    fixclass = "";
    navfixclass = "";
  }
  //have a fix sarchbutton in responsive
  return (
    <>
      {!loading ? (
        <section className="courselist-sec">
          {allcourse.response?.data && (
            <div className="container">
              <div className="courselist">
                <div className="courselist-courses">
                  <ShowCourses information={information} />
                </div>
              </div>
              <div className="CourseListSec__pageination">
                <PaginationBox count={page} />
              </div>
            </div>
          )}
        </section>
      ) : (
        <div className="center" style={{ height: "100vh" }}>
          <ClipLoader
            color="#ffffff"
            loading={loading}
            css={override}
            size={40}
          />
        </div>
      )}
      {addToCardDliaog ? <AddToCardDialog cardCourse={cardCourse} /> : null}
    </>
  );
};
export default CourseListSec;

import React, { createContext, useContext, useState } from "react";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../constacts";
import { useAuth } from "../context/AuthContext";
import { useHistory } from "react-router-dom";
import { ContentSidebarContext } from "./ContentSidebarContext";
export const GetUserInfoContext = createContext();

const GetUserInfoContextProvider = (props) => {
  const { authDispatch, token, refreshtoken, authrefreshDispatch } = useAuth();
  const [userinfo, setuserinfo] = useState(null);
  const [info, setInfo] = useState(0);
  const [refresh, setRefresh] = useState({ refresh: refreshtoken });
  let history = useHistory();
  const reftoken = useAxios({
    url: `${API_URL}/token/refresh/`,
    method: "POST",
    options: {
      data: refresh,
      headers: {
        // Authorization: `JWT ${refreshtoken}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        authDispatch({ type: "LOGIN", token: res.data.access });
      }
      if (err) {
        console.log(err.response);
        authDispatch({ type: "LOGOUT", token });
        authrefreshDispatch({ type: "LOGOUT", refreshtoken });
      }
    },
  });
  const getUserInfo = useAxios({
    url: `${API_URL}/user/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("res", res.data);
        setuserinfo(res.data);
      }
      if (err) {
        console.log(err.response);
        if (refreshtoken) {
          // } else if (err.response.status === 403) {
          //   authDispatch({ type: "LOGOUT", token });
          //   history.push("/logIn");
          reftoken.reFetch();
        } else {
          authDispatch({ type: "LOGOUT", token });
          authrefreshDispatch({ type: "LOGOUT", refreshtoken });
        }
      }
    },
  });
  const summery = useAxios({
    url: `${API_URL}/cart/order-summary/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        // console.log("data", res.data.results.length);
        setInfo(res.data.results.length);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  return (
    <GetUserInfoContext.Provider
      value={{ userinfo, getUserInfo, summery, info }}
    >
      {props.children}
    </GetUserInfoContext.Provider>
  );
};

export { GetUserInfoContextProvider };

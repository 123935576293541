import React, { useContext, useState, useEffect } from "react";
import { BtnSecondarybig } from "../share/Btn";
import { useLocation } from "react-router-dom";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../constacts";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/main.scss";
import { useForm } from "react-hook-form";
import { DraftContext } from "../../context/DraftComment";
import { ErrorMessage } from "@hookform/error-message";

function CourseInfoForm() {
  const [id, setId] = useState("");
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);
  const [text, settext] = useState("");
  const { token } = useAuth();
  const { getDraftcomment } = useContext(DraftContext);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const userloginnotiy = () => {
    toast.error("برای ثبت نظر ابتدا وارد سایت شوید", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  //
  const registerSucessnotify = () => {
    toast.success(
      "نظر شما با موفقیت ارسال شد و پس از تایید ادمین نشان داده میشود",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };
  const onSubmit = (info) => {
    settext(info);
    reset();
    comments.reFetch();
  };

  const comments = useAxios({
    url: `${API_URL}/comment/${id}/create/`,
    method: "POST",
    options: {
      data: text,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        registerSucessnotify();
        getDraftcomment.reFetch();
        console.log("cm", res.data.id);
      }
      if (err) {
        if (
          err.response.data.detail ===
          "Given token not valid for any token type"
        ) {
          userloginnotiy();
        }
        console.log(err.response);
      }
    },
  });

  return (
    <div>
      <div className="corseInfo__head__propertyBox ">
        <svg
          width="38%"
          height="50"
          viewPort="0 0 200 300"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line className="stroke" x1="40" y1="30" x2="520" y2="30"></line>
        </svg>
        <p className="corseInfo__head__propertyTitle">دیدگاه خود را ثبت کنید</p>
        <svg
          width="38%"
          height="50"
          viewPort="0 0 200 300"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line className="stroke" x1="5" y1="30" x2="820" y2="30"></line>
        </svg>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form__display">
          <div className="footer__form">
            <div className="corurseinfo__form--box">
              <textarea
                type="text"
                name="text"
                {...register("text", { required: "فیلد را پر کنید" })}
                id="popupform__right-login-caption"
                className="corurseinfo__form--textarea"
                rows="9"
                placeholder="گوشمون با شماست..."
              />
              <ErrorMessage
                errors={errors}
                name="text"
                render={({ message }) => (
                  <p style={{ color: "red" }}>{message}</p>
                )}
              />
            </div>
          </div>
          <div className="form__btn">
            <BtnSecondarybig className="CourseInfoFormBtn">
              <p>ارسال برای ما</p>
            </BtnSecondarybig>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CourseInfoForm;

/* <div className=" footerInput form-input-div field">
              <input
                type="text"
                name="name"
                id="popupform__right-login-name"
                value={name}
                onChange={updateName}
                required
                class="required form-input field-input"
              />
              <label
                for="popupform__right-login-name"
                className="form-label field-label"
              >
                <span>نام </span>
              </label>
            </div> */
/* <div className=" footerInput form-input-div field">
              <input
                type="email"
                name="email"
                id="popupform__right-login-email"
                value={email}
                onChange={updateEmail}
                required
                class="required form-input field-input"
              />
              <label
                for="popupform__right-login-email"
                className="form-label field-label"
              >
                <span>ایمیل </span>
              </label>
            </div> */

import React, { useEffect, useState, useContext } from "react";
import InfoSideBar from "../components/courseInfo/InfoSideBar/InfoSideBar";
import MediaShare from "../components/courseInfo/InfoSideBar/MediaShare";
import CourseHead from "../components/courseInfo/CourseHead";
import CorseIntro from "../components/courseInfo/CorseIntro";
import PishNiazList from "../components/courseInfo/PishNiazList";
import SarfaslContent from "../components/courseInfo/SarfaslContent";
import SimilarCourse from "../components/courseInfo/SimilarCourse";
import Comment from "../components/courseInfo/Comment";
import UserComment from "../components/courseInfo/UserComment";
import CourseInfoForm from "../components/courseInfo/CourseInfoForm";
import TeachersBox from "../components/courseInfo/TeachersBox";
import StickDot from "../components/courseInfo/StickDot";
import Navigation from "../components/share/Navigation";
import UseWindowSize from "../Sizes/UseWindowSize";
import PhoneCourseInfo from "./PhoneCourseInfo";
import { API_URL } from "../constacts";
import useAxios from "@use-hooks/axios";
import { useAuth } from "../context/AuthContext";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import AddToCardDialog from "../components/share/AddToCardDialog";
import { CardCourses } from "../context/CardCourseContext";
import { useLocation } from "react-router-dom";
import { DraftCommentProvider } from "../context/DraftComment";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import useFetch from "../components/hooks/useFetch";
import Loader from "../components/Common/Loader";

/**
 * return Get one courseinfo for public
 * @param   {integer} id  id of course
 *
 * @return  {object} id,title,intro,cover,
 * intro_video,mean_of_participant_points,
 * nums_of_voter,percentage_of_quality_of_course ,
 * teacher_first_name,
 * teacher_last_name,
 * teacher_description,
 * teacher_picture,
 * properties:[],
 * prerequisites:[Prerequisite:{}],
 * seasons:[id of seasons],
 * is_accepted_comments:[]
 *  

 */

function CourseInfo({ addToCard }) {
  const windowSize = UseWindowSize();
  const { token } = useAuth();
  const [id, setId] = useState("");
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);
  console.log("location.state.id", location.state.id);
  Cookies.set("path", window.location.href);
  const tokencookie = Cookies.get("token") ? Cookies.get("token") : null;
  const [courseinfo, setcourseinfo] = useState(null);
  let [loading, setLoading] = useState(true);
  const { addToCardDliaog } = useContext(CardCourses);
  const [SarfaslContentInfo, setSarfaslContentInfo] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    getcourseInfo.reFetch();
  }, []);
  const finishloading = () => {
    setLoading(false);
    getSeason.reFetch();
  };
  const getcourseInfo = useFetch({
    url: `course/${id}/get`,
    method: "GET",
    setter: setcourseinfo,
    argFunc: finishloading,
    needcookie: true,
    withCredentials: true,
    loginifcondition: true,
  });

  const getSeason = useAxios({
    url: `${API_URL}/course_season/${id}/all/`,
    method: "GET",
    options: {
      headers: token
        ? {
            Authorization: `JWT ${token}`,
          }
        : {},
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("resseason", res.data.data);
        setSarfaslContentInfo(res.data.data);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: green;
  `;

  return (
    <DraftCommentProvider>
      <div style={{ position: "relative", backgroundColor: "#FDFDFD" }}>
        {getcourseInfo.response?.data ? (
          <div>
            {!loading ? (
              <div>
                {windowSize === "lg" ||
                windowSize === "md" ||
                windowSize === "md-2" ||
                windowSize === "sm" ? (
                  <div className="PhoneCourseInfoBox">
                    <PhoneCourseInfo
                      courseinfo={courseinfo}
                      SarfaslContentInfo={SarfaslContentInfo}
                      id={id}
                    />
                  </div>
                ) : (
                  <div className="container">
                    <div className="CourseInfoContentBody">
                      <div className="CourseInfoSideBar">
                        <InfoSideBar id={id} setLoader={setLoader} />
                        <MediaShare />
                      </div>
                      <div className="CourseInfStickDotBox">
                        <StickDot />
                      </div>
                      <div
                        className="courseinfo__background"
                        style={{
                          backgroundColor: "#FDFDFD",
                          position: "relative",
                          zIndex: "2",
                        }}
                      >
                        <div className="CourseInfoContentBody_Box">
                          <div>
                            <div id="CourseInfohead">
                              <CourseHead
                                title={courseinfo.title}
                                properties={courseinfo.properties}
                                picture={courseinfo.cover}
                              />
                              <CorseIntro
                                video={courseinfo.intro_video}
                                intro={courseinfo.intro}
                              />
                            </div>
                            <div id="CourseInfoPishniaz">
                              <PishNiazList id={id} />
                              <SarfaslContent
                                Coursetitle={courseinfo.title}
                                id={id}
                                seasons={courseinfo.seasons}
                                SarfaslContentInfo={SarfaslContentInfo}
                              />
                            </div>
                            <TeachersBox
                              name={courseinfo.teacher_first_name}
                              last={courseinfo.teacher_last_name}
                              txt={courseinfo.teacher_description}
                              pic={courseinfo.teacher_picture}
                            />
                            <div id="CourseInfoComment">
                              {courseinfo.mean_of_participant_points !==
                              null ? (
                                <Comment
                                  star={
                                    courseinfo.mean_of_participant_points.grade
                                  }
                                  num={courseinfo.nums_of_voter}
                                  linepro={
                                    courseinfo.percentage_of_quality_of_course
                                  }
                                />
                              ) : null}

                              <UserComment id={id} />
                            </div>

                            <CourseInfoForm />
                            <SimilarCourse />
                          </div>
                        </div>
                        {loader && (
                          <div className="CourseInfoLoader">
                            <Loader start={true} />{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="center" style={{ height: "100vh" }}>
                <ClipLoader
                  color="#000"
                  loading={loading}
                  css={override}
                  size={50}
                />
              </div>
            )}
          </div>
        ) : (
          <div style={{ height: "100vh" }}>
            <Loader />
          </div>
        )}
        {addToCardDliaog ? <AddToCardDialog /> : null}
        <ToastContainer />
      </div>
    </DraftCommentProvider>
  );
}

export default CourseInfo;

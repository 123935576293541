import React from "react";
import { BtnSecondary } from "./Btn";
import { NavLink } from "react-router-dom";
import "../../css/main.scss";

function SectionEndButton(props) {
  return (
    <div className="UtilitytextTitle  center column">
      <h2
        style={{
          marginTop: "6rem",
          marginBottom: "1rem",
          fontSize: "3rem",
          fontWeight: "100",
        }}
      >
        {props.title}
      </h2>
      <BtnSecondary className="ShowCase__BtnBox">
        <NavLink
          exact
          activeClassName="is-active"
          to="/CourseList"
          // className="nav__list--link"
        >
          <div className="ShowCase__Btn">
            <i className="fas fa-chevron-left"></i>
            <p>{props.text}</p>
          </div>
        </NavLink>
      </BtnSecondary>
    </div>
  );
}

export default SectionEndButton;

import React, { useEffect } from "react";
import { useState } from "react";
import empty from "../../img/mycourse/empty-folder-1519007-1284948.png";
import MyCourseNowCard from "./MyCourseNowCard";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { useAuth } from "../../context/AuthContext";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import QuizeModal from "./QuizeModal";
import Loader from "../Common/Loader";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #69b57d;
`;
const NowCourseMain = ({
  openQuizeModal,
  getdoingcourserefetch,
  doingcourseItems,
  loadingdoing,
  setLoad,
}) => {
  const { token } = useAuth();

  useEffect(() => {
    getdoingcourserefetch();
  }, []);
  return (
    <div className="NowCourseMain">
      {doingcourseItems !== null ? (
        <div>
          {" "}
          {doingcourseItems.length !== 0 ? (
            <div>
              {doingcourseItems.map((i) => (
                <MyCourseNowCard
                  openQuizeModal={() => openQuizeModal(i.id)}
                  doingcourseItems={i}
                  setLoad={setLoad}
                />
              ))}
            </div>
          ) : (
            <img className="empty__img" src={empty} />
          )}
        </div>
      ) : (
        <ClipLoader
          color="#69b57d"
          loading={loadingdoing}
          css={override}
          size={50}
        />
      )}
    </div>
  );
};
const FinishedCourseMain = ({
  openQuizeModal,
  donecourseItems,
  getdonecourserefetch,
  loadingdone,
  setLoad,
}) => {
  useEffect(() => {
    getdonecourserefetch();
  }, []);
  return (
    <div className="NowCourseMain">
      {donecourseItems !== null ? (
        <div>
          {donecourseItems.length !== 0 ? (
            <div>
              {donecourseItems.map((i) => (
                <MyCourseNowCard
                  openQuizeModal={() => openQuizeModal(i.id)}
                  doingcourseItems={i}
                  setLoad={setLoad}
                />
              ))}
            </div>
          ) : (
            <img className="empty__img" src={empty} />
          )}
        </div>
      ) : (
        <ClipLoader
          color="#69b57d"
          loading={loadingdone}
          css={override}
          size={50}
        />
      )}
    </div>
  );
};
const MycourseMainPanel = (props) => {
  const { token } = useAuth();

  const [finished, setFinished] = useState(false);
  const [donecourseItems, setdonecourseItems] = useState(null);
  const [loadingdone, setloadingdone] = useState(true);
  const [doingcourseItems, setdoingcourseItems] = useState(null);
  const [loadingdoing, setloadingdoing] = useState(true);
  const [quizemodal, setquizemodal] = useState(false);
  const [modalinfo, setmodalinfo] = useState();
  const getdoingcourse = useAxios({
    url: `${API_URL}/profile/my_doing_courses/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        setloadingdoing(false);
        setdoingcourseItems(res.data.results);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  const getdoingcourserefetch = () => {
    getdoingcourse.reFetch();
  };
  const getdonecourserefetch = () => {
    getdonecourse.reFetch();
  };
  const getdonecourse = useAxios({
    url: `${API_URL}/profile/my_done_courses/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        setdonecourseItems(res.data.results);
        setloadingdone(false);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  const [load, setLoad] = useState(false);

  const openQuizeModal = (id) => {
    if (!finished) {
      setmodalinfo(doingcourseItems.filter((i) => id === i.id));
    } else {
      setmodalinfo(donecourseItems.filter((i) => id === i.id));
    }
    setquizemodal(true);
    console.log(modalinfo);
  };
  const CloseModalQuize = () => {
    setquizemodal(false);
  };
  return (
    <div className="mycourse-defaultbox">
      <div className=" MycourseMainPanel__header">
        <div
          onClick={() => setFinished(true)}
          className={` MycourseMainPanel__button ${
            finished && "active__btn1"
          } `}
        >
          <p>دوره های تمام شده </p>
        </div>
        <div
          onClick={() => setFinished(false)}
          className={`MycourseMainPanel__button MycourseMainPanel__button1 ${
            !finished && "active__btn"
          }`}
        >
          <p>دوره های در حال گذراندن</p>
        </div>
      </div>
      <div>
        {!finished ? (
          <div className={`${!finished && "active__tabcourse"}`}>
            <NowCourseMain
              doingcourseItems={doingcourseItems}
              loadingdoing={loadingdoing}
              getdoingcourserefetch={getdoingcourserefetch}
              openQuizeModal={openQuizeModal}
              setLoad={setLoad}
            />
          </div>
        ) : (
          <div className={`${finished && "active__tabcourse1"}`}>
            <FinishedCourseMain
              loadingdone={loadingdone}
              donecourseItems={donecourseItems}
              getdonecourserefetch={getdonecourserefetch}
              openQuizeModal={openQuizeModal}
              setLoad={setLoad}
            />
          </div>
        )}
      </div>

      {quizemodal && (
        <QuizeModal
          lesson_quizzes={modalinfo[0].lesson_quizzes}
          CloseModalQuize={CloseModalQuize}
          course_quizzes={modalinfo[0].course_quizzes}
          season_quizzes={modalinfo[0].season_quizzes}
          id={modalinfo[0].id}
        />
      )}
      {load && (
        <div className="CourseInfoLoader">
          <Loader start={true} />{" "}
        </div>
      )}
    </div>
  );
};

export default MycourseMainPanel;

import React from "react";
import "../../css/main.scss";

const ShowCaseItem = (props) => {
  return (
    <li className="item">
      <span className={props.className}>
        <i className="fas fa-check"></i>
      </span>
      {props.text}
    </li>
  );
};
export default ShowCaseItem;

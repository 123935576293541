import React, { useState, useContext, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CardCourses } from "../../context/CardCourseContext";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NumberFormat from "react-number-format";
import { GetUserInfoContext } from "../../context/GetUserInfoContext";
import Chevron from "../Common/Chevron";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: "flex",
    alignItems: "center",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CardCourse = ({
  title,
  price,
  id,
  setValdeg,
  setValcor,
  setFinal,
  final,
  setFinal1,
  final1,
  degrees,
  degree,
  deg,
  lname,
  pic,
  costamount,
  calculateordertemcoupon,
}) => {
  const [val, setVal] = useState(0);
  const [value, setvalue] = useState({ name: degrees.name, id: degrees.id });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { summery } = useContext(GetUserInfoContext);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const classes = useStyles();

  //
  useEffect(() => {
    degrees.map((item, arr) => item[1] === degree && setVal(item[0]));
  }, []);
  const { removerCard } = useContext(CardCourses);
  const { token } = useAuth();
  const [dialog, setdialog] = useState(false);
  const [val1, setVal1] = useState({ degree: "" });
  const showdeletdialg = () => {
    setdialog(true);
  };
  console.log("degrees", degrees);
  const deleteCard = () => {
    setLoading(true);
    remove.reFetch();
  };
  const remove = useAxios({
    url: `${API_URL}/cart/remove-from-cart/${id}/`,
    method: "DELETE",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        summery.reFetch();
        setLoading(false);
        setdialog(false);

        // setLoading(false);
        // props.setCode(name);
        setFinal(!final);
      }
      if (err) {
        console.log(err.response);
        setLoading(false);
        setdialog(false);
        // setLoading(false);
        // takhfifcod();
      }
    },
  });
  const changedegreeSuccess = () => {
    toast.success("نوع مدرک با موفقت عوض شد", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const changedegreeError = () => {
    toast.error("دوباره تلاش کنید", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const changedeg = useAxios({
    url: `${API_URL}/cart/change-order-item-degree/${deg}/`,
    method: "PATCH",
    options: {
      data: val1,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        setFinal1(!final1);
        changedegreeSuccess();
        // setLoading(false);
        // props.setCode(name);
      }
      if (err) {
        console.log(err.response);
        changedegreeError();
      }
    },
  });

  const handlechange = (e) => {
    setVal(e.target.value);
    setValdeg(e.target.value);
    setVal1({ degree: parseInt(e.target.value) });
    changedeg.reFetch();
  };
  return (
    <div
      className="Card-Course-row"
      style={{ cursor: "pointer" }}
      onClick={setValcor(id)}
    >
      <div onClick={showdeletdialg} className="delete-icon">
        <i class="fas fa-trash"></i>
      </div>
      <div className="Card-Course">
        <div className="Card-madrak">
          {" "}
          {val !== 0 && degree !== null ? (
            <div className=' PriceSection__firstRow'>
                  <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                defaultValue={val}
                onChange={handlechange}
                style={{ color: "#f3722c" }}
                IconComponent={Chevron}

              >
                {degrees.map((index, arr) => (
                  <MenuItem value={index[0]} style={{ color: "#f3722c" }}>
                    {index[1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </div>
            
          ) : degree === null ? (
            <div className=' PriceSection__firstRow'>
            <FormControl variant="outlined"
                        className={classes.formControl}>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                defaultValue={0}
                onChange={handlechange}
                style={{ color: "#f3722c" }}
                IconComponent={Chevron}
              >
                <MenuItem value={0}> انتخاب نوع مدرک</MenuItem>
                 {degrees.map((index, arr) => (
                  <MenuItem value={index[0]} style={{ color: "#f3722c" }}>
                    {index[1] === null ? "بدون مدرک" : index[1]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </div>
          ) : null}
        </div>
        {calculateordertemcoupon.map((index, id) => (
          <p className="Card-title">{index}</p>
        ))}

        <p className="Card-title">
          <NumberFormat
            thousandSeparator={true}
            value={costamount}
            readOnly
            displayType={"text"}
          />
        </p>
        <div className="Card-cost ">
          <p>{lname}</p>
          <div>{price}</div>
          <img
            src={pic}
            alt={pic}
            style={{
              width: "4rem",
              height: "4rem",
              borderRadius: "50%",
              marginLeft: "0.5rem",
              overflow:'hidden'
            }}
          />
        </div>
        <p className="Card-title">{title}</p>
      </div>
      {dialog ? (
        <div className="card-dialog-bg">
          <div className="card-delete-dialog">
            <p>ایا از حذف دوره مطمئنید؟</p>
            <div className="dialog-btn-box">
              <button onClick={() => setdialog(false)} className="btn-no">
                خیر
              </button>
              <button
                onClick={() => deleteCard()}
                className="btn-primary btn-yes"
              >
                {loading ? (
                  <ClipLoader
                    color="#ffffff"
                    loading={loading}
                    css={override}
                    size={15}
                  />
                ) : (
                  " بلی"
                )}
              </button>
            </div>
          </div>{" "}
        </div>
      ) : null}
      <ToastContainer />
    </div>
  );
};

export default CardCourse;

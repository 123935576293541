import React,{useState} from "react";
import { BtnSecondarybig, BtnSubmitWhite } from "./Btn";
import FormHandleChange from "../hooks/FormHandleChange";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import "../../css/main.scss";
import { API_URL } from "../../constacts";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { useForm } from "react-hook-form";
import useAxios from "@use-hooks/axios";
import { css } from "@emotion/core";
import { ToastContainer, toast } from "react-toastify";
function PinkbackSection() {
  let [loading, setLoading] = useState(false);
  const [email, setemail] = useState();
  const sendfeedbacksuccess = () => {
    toast.success('ایمیل شما با موفقیت ثبت شد', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;
const postfeedback = useAxios({
  url: `${API_URL}/subscribtions/`,
  method: "POST",
  options: {
    data: email,
  },
  // trigger: resources,
  customHandler: (err, res) => {
    if (res) {
      console.log(res);
      reset()
      setLoading(false);
      sendfeedbacksuccess()
    }
    if (err) {
      setLoading(false);
 
     
    }
  },
});
const {
  register,
  handleSubmit,
  watch,
  reset,
  formState: { errors },
} = useForm();
const onSubmit = (info) => {
  setLoading(true);
  setemail(info);
  postfeedback.reFetch()
};
  return (
    <div className="bg-pink center start">
      <p className="PinkbackSection-p">
        برای اطلاع از جدیدترین دوره های ما ایمیل خود را وارد کنید
      </p>
      <form         onSubmit={handleSubmit(onSubmit)}
className="PinkbackSectionForm ">
      <div className="PinkbackSectionInputBox">
          <input
            placeholder="ایمیل"
            type="email"
            {...register("email", { required: true })}
            required
          ></input>
        </div>

        <BtnSecondarybig className="PinkbackSectionBtn ">
        {loading ? (
                <ClipLoader
                  color="#ffffff"
                  loading={loading}
                  css={override}
                  size={15}
                />
              ) : (
              ' ثبت نام'
              )}
        </BtnSecondarybig>
      </form>
    </div>
  );
}

export default PinkbackSection;

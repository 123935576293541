import React from "react";
import "../../css/main.scss";
import How1 from "../../img/How 1-1.svg";
import How2 from "../../img/How 1-2.svg";
import How3 from "../../img/How 1-3.svg";
import How4 from "../../img/How 1-4.svg";
import pathbackground from "../../img/Path 2587.svg";
import Circle from "../../img/svg/Circle.svg";
import SectionEndButton from "../share/SectionEndButton";
import HowDiv from "./HowDiv";
export const HowSec = (props) => {
  const content = [
    {
      image: How1,
      title: "انتخاب و مشاهده دوره",
      text: "دوره مورده علاقه ی خود را انتخاب و طبق سر فصل ها انتخاب کنید",
      id: 1,
    },
    {
      image: How2,
      title: "تمرین آنلاین و ارزیابی هوشمند",
      text:
        "همزمان و آنلاین تمرین کنید ،ارزیابی آنلاین کد هارا بررسی و مشکلات را گزارش میکند",
      id: 2,
    },
    {
      image: How3,
      title: "تست تمرین و مرحله ی بعدی",
      text: "تمرین را تست و عملکرد خود را ببینید،حالا به مرحله ی بعد بروید",
      id: 3,
    },
    {
      image: How4,
      title: "پروژه کامل و پایان آموزش",
      text:
        "نتیجه ی کار خود را بصورت یک پروژه ی کامل ببینید ،میتوانید دوره جدیدی را شروع کنید",
      id: 4,
    },
  ];
  return (
    <section className="howSec">
      <div className="howSec-container">
        <div className="heading center">
          <h3 className="h-3">کد آموز چگونه کار میکند؟</h3>
        </div>
        <div className="how">
          {content.map((c) => (
            <HowDiv
              key={c.id}
              classNumber={c.id}
              picture={c.image}
              title={c.title}
              text={c.text}
            />
          ))}
        </div>
        <div style={{position:"relative"}}>
          <img src={pathbackground} className="How__background" />
            
          
          <div className="How__SectionEndButton">
            <SectionEndButton
              title="کدآموز یادگیری را بسیار آسان کرده"
              text="همین حالا شروع کن"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

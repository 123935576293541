import React, {useState} from 'react';
import PhoneAccordion from '../../Common/PhoneAccordion';
import '../../../css/main.scss';

function PhoneDescription(props) {
  const [PhoneDescriptionInfo, setPhoneDescriptionInfo] = useState([
    {titr: props.intro},
  ]);
  return (
    <div className="PhoneDescription">
      <PhoneAccordion title="مشاهده ی توضیحات" content={PhoneDescriptionInfo} />
    </div>
  );
}

export default PhoneDescription;



import React, { useState } from "react";
import MyFavCourseHeader from "../components/Myfavecourse/MyFavCourseHeader";
import MyFavCourseList from "../components/Myfavecourse/MyFavCourseList";

function Myfavecourse() {
  return (
    <div>
      <MyFavCourseHeader />
      <MyFavCourseList />
    </div>
  );
}

export default Myfavecourse;

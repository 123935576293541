import React, { useState } from "react";
import "../../css/main.scss";
import LoginForm from "./LoginForm";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import SignUpForm from "./SignUpForm";
import UseWindowSize from "../../Sizes/UseWindowSize";
import {ToastContainer, toast} from 'react-toastify';

const LoginPage = (props) => {
  const [form, setToggleForm] = useState(props.form);
  const [fade, setFade] = useState(false);
  const [moveToleftClass, setMovetoLeftClass] = useState("");
  const windowSize = UseWindowSize();

  const toggleForm = () => {
    setToggleForm(!form);
  };
  const logInBtn = () => {
    setTimeout(() => {
      setToggleForm(false);
    }, 300);
    setMovetoLeftClass("toleft");
    setFade(true);
  };
  const SignUpBtn = () => {
    setTimeout(() => {
      setToggleForm(true);
    }, 300);
    setMovetoLeftClass("toRight");
    setFade(false);
  };

  return (
    <div>
      {windowSize === "md-2" || windowSize === "sm" ? (
        <div className="login-form responsivelogin" id="log-in">
          {form === false ? <LoginForm  fade={true}/> : <SignUpForm fade={false}/>}
        </div>
      ) : (
        <div className="login-form" id="log-in">
          <NavLink to="/" className="popup__close">
            <i class="fas fa-times"></i>
          </NavLink>

          <div className="popupform">
            {form === false ? <LoginForm fade={true} /> : null}

            <div className={`popupform__left ${moveToleftClass} `}>
              {form === true ? (
                <>
                  <h2 className="popupform__left-h2">به کدآموز خوش آمدید</h2>
                  <p className="popupform__left-p">
                    برای دسترسی به دوره های خود ابتدا وارد شوید
                  </p>
                  <button className="popupform__left-btn" onClick={logInBtn}>
                    ورود
                  </button>
                </>
              ) : (
                <>
                  <h2 className="popupform__left-h2">
                    سفر یادگیری خود را با ما آغاز کنید
                  </h2>
                  <p className="popupform__left-p">هنوز ثبت نام نکرده اید؟</p>
                  <button className="popupform__left-btn" onClick={SignUpBtn}>
                    ثبت نام
                  </button>
                </>
              )}
            </div>

            {form === true ? <SignUpForm fade={fade} /> : null}
          </div>
        </div>
      )}
            <ToastContainer />

    </div>
  );
};

export default LoginPage;

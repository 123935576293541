import React from "react";
import "../../css/main.scss";
import Feature from "./Feature";
import Circle from "../../img/svg/Circle.svg";
import suport from "../../img/support.svg";
import farsi from "../../img/farsi.svg";
import control from "../../img/control.svg";
import checkList from "../../img/checklist.svg";
import computer from "../../img/computer.svg";
import SectionEndButton from "../share/SectionEndButton";
const content = [
  {
    title: "محتوای فارسی کامل و غنی",
    img: farsi,
    description:
      "محتوای سایت کدآموز توسط متخصصین باتجربه ایرانی طراحی و تولید می‌شود. ",
  },
  {
    img: control,
    title: "سیستم کنترل و نظارت",
    description:
      "کنترل و نظارت بر کیفیت محتوای آموزشی (فیلم، متن، سرفصل‌ و ...) از ابتدا در دستور کار تیم کدآموز بوده تا بهترین محتوا را در اختیار مهارت‌آموزان بگذارد.",
  },
  {
    img: checkList,
    title: "سیستم ارزیابی هوشمند",
    description:
      "تصحیح و ارزیابی هوشمند فعالیت کاربران در طول آموزش به صورت آنلاین همواره در حال انجام است.این سیستم یه رشد کاربر کمک میکند",
  },
  {
    img: suport,
    title: "دسترسی به راهنما",
    description:
      "در طول فرآیند آموزش شما به یک راهنما دسترسی دارید که برای پیشرفت در درس‌ها و حل مشکلات به شما کمک می‌کند.",
  },
  {
    img: computer,
    title: "ادیتور آنلاین",
    description:
      "بی‌نیاز از نصب هرگونه نرم‌افزار. با ادیتور آنلاین کدآموز شما فقط به اینترنت نیاز دارید. همه چیز برای یادگیری به‌صورت آنلاین در اختیار شماست.",
  },
];

function FeatureSec() {
  return (
    <div >
      <div className="heading center header_topMargin">
        <h3 className="h-3">آنچه کدآموز را خاص می کند</h3>
      </div>
      <div className="content__position">
        <div className="feature__pos">
          {content.map((item, index) => (
            <Feature
              img={item.img}
              title={item.title}
              description={item.description}
              key={index}
              i={index}
            />
          ))}
        </div>
      </div>
      <SectionEndButton
        title="همه چیز برای یادگیری آماده است"
        text="همین حالا شروع کن"
      />
    </div>
  );
}

export default FeatureSec;

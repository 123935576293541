import React, {useState, useContext} from 'react';
import '../../css/main.scss';
import {CardCourses} from '../../context/CardCourseContext';

import PerviewCoursesinCard from './PerviewCoursesinCard';
const AddToCardDialog = () => {
  const [perview, setPerview] = useState(false);
  const showPerview = () => {
    setPerview(true);
  };
  const closePerview = () => {
    setPerview(false);
  };
  const {cardCourse, alredyaddedDialog, removerCard, closeAddtocardDialog} =
    useContext(CardCourses);

  return (
    <div>
      {alredyaddedDialog ? (
        <div className="Addtocard-dialog">
          <p>قبلا این دوره به سبد خرید اضافه شده است</p>
          <button onClick={closeAddtocardDialog}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      ) : (
        <div className="Addtocard-dialog">
          <p>دوره اضافه شد</p>
          <button className="perview" onClick={showPerview}>
            پیش نمایش
          </button>
          <button onClick={closeAddtocardDialog}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      )}

      {perview ? (
        <PerviewCoursesinCard
          removerCard={removerCard}
          cardCourse={cardCourse}
          closePerview={closePerview}
        />
      ) : null}
    </div>
  );
};

export default AddToCardDialog;

import { useState, useEffect } from "react";
import useAxios from "@use-hooks/axios";
import {API_URL} from '../../constacts';
import { toast } from "react-toastify";
import { useAuth } from "../../context/AuthContext";
import Cookies from "js-cookie";

export default function useFetch({
  method = "GET",
  url,
  setter = null,
  data = null,
  params = null,
  trigger = true,
  caller = null,
  func = null,
  message = null,
  argFunc = null,
  needcookie=false,
  withCredentials=false,
  shouldlogin=false,
  loginifcondition=false,
  errmessage=null,
  errfamessage=null
}) {
    const {token} = useAuth();
    const path = Cookies.get("path") ? Cookies.get("path") : null;

  const { response, loading, error, reFetch } = useAxios({
    url: `${API_URL}/${url}/`,
    withCredentials: withCredentials,
    method: method,
    options: {
      headers: shouldlogin ? {
        // 'content-type': 'multipart/form-data',
        Authorization: `JWT ${token}`,
      }:loginifcondition ? token
           ? {
               Authorization: `JWT ${token}`,
             }
           : {} :{},
      data: data,
      params: params,
      cookies: needcookie ?{
        path: path,
      }:null,
    },

    customHandler: (err, res) => {
      if (res) {
        console.log("data", res.data);
        if (setter !== null) setter(res.data);
        if (caller !== null) caller.reFetch();
        if (func !== null) func();
        if (argFunc !== null) argFunc(res.data);
        if (message !== null)
          toast.success(message, {
            position: toast.POSITION.TOP_CENTER,
          });
      }
      if (err) {
        console.log(err);

        console.log(err.response);
        // authDispatch({ type: "LOGOUT" });
        
        if (errmessage !== null)
        toast.success(errfamessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      
    },
  });

  // useEffect(() => {
  //   if (trigger) reFetch();
  // }, []);

  return { response, error, loading, reFetch };
}

import React, {useState} from 'react';
import {BtnPrimary, BtnSecondary} from '../share/Btn';
import {useAuth} from '../../context/AuthContext';
import {API_URL} from '../../constacts';
import useAxios from '@use-hooks/axios';
import FormHandleChange from '../hooks/FormHandleChange';
import {useForm} from 'react-hook-form';
import {FlightTakeoffOutlined} from '@material-ui/icons';
import ClipLoader from 'react-spinners/ClipLoader';
import {css} from '@emotion/core';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/main.scss';

const CardTipsBox = (props) => {
  const {token} = useAuth();
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm();
  const takhfifcod = () => {
    toast.error('کورسی انتخاب نشده است', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const takhfifcod1 = () => {
    toast.error('کد تخفیف  زمان آن تمام شده است', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const takhfifcod2 = () => {
    toast.error('کد تخفیف اشتباه است', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const takhfifcod3 = () => {
    toast.error('کد تخفیف قبلا برای سبد خرید استفاده شده است', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const takhfifcod4 = () => {
    toast.error('کد تخفیف قبلا برای درس مورد نظر استفاده شده است', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const registerSucessnotify = () => {
    toast.success('کد تخفیف اعمال شده', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const takhfif = useAxios({
    url: `${API_URL}/cart/add-coupon/`,
    method: 'POST',
    options: {
      data: name,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        setLoading(false);
        registerSucessnotify();
        props.setCode(name);
        props.setFinal(!props.final);
        props.setFinal1(!props.final1);
      }
      if (err) {
        console.log(err.response.data);
        setLoading(false);
        if (err.response.data.data === 'This Coupon Is Expired') {
          takhfifcod1();
        } else if (err.response.data.data === 'This Coupon Is Invalid') {
          takhfifcod2();
        } else if (
          err.response.data.data ===
          'This Coupon is already used for this Order'
        ) {
          takhfifcod3();
        } else if (
          err.response.data.data ===
          'This Coupon is already used for this OrderItem'
        ) {
          takhfifcod4();
        } else {
          takhfifcod();
        }
      }
    },
  });
  const onSubmit = (info) => {
    setLoading(true);
    setName(info);
    takhfif.reFetch();
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  return (
    <div className="Card-box">
      <div className="Card-box-title">کد تخفیف</div>
      <form onSubmit={handleSubmit(onSubmit)} className="Card-tip-form">
        <input
          type="text"
          name="coupon_number"
          placeholder="کد تخفیف"
          className="Card-tip-form-input"
          {...register('coupon_number', {required: true})}
          required
        />
        <BtnSecondary className="btn btn-primary Card-tip-form-btn">
          {loading ? (
            <ClipLoader
              color="#ffffff"
              loading={loading}
              css={override}
              size={15}
            />
          ) : (
            ' اعمال کد'
          )}
        </BtnSecondary>
      </form>
      <ToastContainer />
    </div>
  );
};

export default CardTipsBox;

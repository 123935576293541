import React, { useState, useRef, useEffect } from "react";
import Chevron from "../Common/Chevron";
import padlock from "../../img/courseinfo/Sarfasl/padlock.png";
import "../../css/main.scss";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../constacts";
import DarsContentAccordion from "./DarsContentAccordion";

/**
 * return Get getcourseContentList of one season
 * @param   {integer} id  id of season
 *
 * @return  {object} 
 *  "data": [
   {
    "id": integer,
    "title": "",
    "duration_time": "",
    "lockedOn": boolean
   }
  ]
 * 
 */

function CourseInfoAccordion(props) {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");
  const [contentList, setcontentList] = useState(null);

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");

    setHeightState(setActive === "active" ? "0px" : `max-content`);
    setRotateState(
      setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
    );
  }
  useEffect(() => {
    //getcourseContent.reFetch();
  }, []);
  // const getcourseContent = useAxios({
  //   url: `${API_URL}/course_content/${props.info.id}/all/`,
  //   method: "GET",

  //   customHandler: (err, res) => {
  //     if (res) {
  //       console.log(res.data);

  //       setcontentList(res.data.data);
  //       setLoading(false);
  //     }
  //     if (err) {
  //       console.log(err.response);
  //     }
  //   },
  // });

  return (
    <>
      {props.info !== null ? (
        <div className="SarFaslBoxContent">
          <button
            className={`accordioninfo ${setActive}`}
            onClick={toggleAccordion}
            disabled={props.lock}
            style={
              props.lock ? { cursor: "not-allowed" } : { cursor: "pointer" }
            }
          >
            {!props.info.lockedOn ? (
              <Chevron className={`${setRotate}`} width={15} fill={"#000"} />
            ) : (
              <img alt="padlock" src={padlock}></img>
            )}

            <div style={{ width: "100%" }}>
              <div className="InfoSarfaslListBox__withIco">
                <div className="InfoSarfaslListBox">
                  <label id="lable" className="InfoSarfaslList__label">
                    {props.info.title}
                  </label>
                  <div className="InfoSarfaslList__selectBox">
                    <div className="InfoSarfaslList__lessonBox">
                      <p className="InfoSarfaslList__lesson">
                        {props.info.get_nums_of_contents_for_each_season}درس
                      </p>
                      <div className="InfoSarfaslList__houer">
                        {props.info.total_time_for_each_season}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </button>

          <div
            ref={content}
            style={{ maxHeight: `${setHeight}` }}
            className="accordion__content"
          >
            <div className="accordion__content-Box">
              <div style={{ textAlign: "right" }}>
                <div className="accordion__text">
                  {props.info.lockedOn !== true
                    ? props.info.contents.map((i) => (
                        <DarsContentAccordion
                          info={i}
                          Coursetitle={props.Coursetitle}
                          seasontitle={props.info.title}
                        />
                      ))
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default CourseInfoAccordion;

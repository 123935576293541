import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {API_URL} from '../../constacts';
import useAxios from '@use-hooks/axios';
import {logDOM} from '@testing-library/dom';
import {useAuth} from '../../context/AuthContext';
import {useHistory} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UserPasword() {
  const [info, setInfo] = useState('');
  const {authDispatch, token} = useAuth();
  let history = useHistory();
  const {
    register,
    handleSubmit,
    watch,
    formState: {errors},
  } = useForm();
  const changepass = useAxios({
    url: `${API_URL}/password/change/`,
    method: 'POST',
    options: {
      data: info,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        // logoutpage.reFetch();
        authDispatch({type: 'LOGOUT', token});
        history.push('/');
      }
      if (err) {
        if (
          err.response.data.old_password
        
        ) {
          pssnotify();
        } else if (
         err.response.data.new_password2
        
        ) {
           onlynumbernotify();
        }
        //  else if (
        //   err.response.data.new_password2
          
        // ) {
        //   onlynumbernotify1();
        // }
        console.log(err.response);
      }
    },
  });
  const pssnotify = () => {
    toast.error('پسورد فعلی اشتباه است', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const onlynumbernotify = () => {
    toast.error(
      'پسورد باید جدید حداقل 8 کاراکتر و شامل اعداد و حروف باهم باشد',
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };
  const onlynumbernotify1 = () => {
    toast.error('پسورد جدید و تکرار آن برابرنیستند', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const onSubmit = (data) => {
    setInfo(data);
    changepass.reFetch();
  };
  return (
    <div className="UserDetails">
      <h1>تغییر رمز ورود</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="UserDetails__dataTopBox1">
          <div
            className={
              errors.old_password
                ? 'UserDetails__ErrinputBox'
                : 'UserDetails__inputBox'
            }
          >
            <label className="AddNews__formLabel">رمز فعلی</label>
            <input
              type="password"
              {...register('old_password', {required: true})}
            />
            {errors.old_password && (
              <span className="newGrouperrorMsg">This field is required</span>
            )}
          </div>
        </div>
        <div className="UserDetails__dataTopBox">
          <div
            className={
              errors.new_password1
                ? 'UserDetails__ErrinputBox'
                : 'UserDetails__inputBox'
            }
          >
            <label className="AddNews__formLabel">رمز ورود جدید</label>
            <input
              type="password"
              {...register('new_password1', {required: true})}
            />
            {errors.new_password1 && (
              <span className="newGrouperrorMsg">This field is required</span>
            )}
          </div>
          <div
            className={
              errors.new_password2
                ? 'UserDetails__ErrinputBox'
                : 'UserDetails__inputBox'
            }
          >
            <label className="AddNews__formLabel">تکرار رمز ورود جدید</label>
            <input
              type="password"
              {...register('new_password2', {required: true})}
            />
            {errors.new_password2 && (
              <span className="newGrouperrorMsg">This field is required</span>
            )}
          </div>
        </div>
        <div className="UserDetails__BtnSecondary">
          <button type="submit" className="BtnSecondary">
            ذخیره اطلاعات
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default UserPasword;

import React, {useState, useRef, useEffect} from 'react';
import PhoneChevron from './PhoneChevron';
import user1 from '../../img/courseinfo/12790.png';
import moment from 'jalali-moment';
import useAxios from '@use-hooks/axios';
import {API_URL} from '../../constacts';
import {useAuth} from '../../context/AuthContext';
import '../../css/main.scss';

function PhoneAccordion(props) {
  const [setActive, setActiveState] = useState('');
  const [setHeight, setHeightState] = useState('0px');
  const [setRotate, setRotateState] = useState('PhoneAccordion__icon');
  const [timetest, setTimetest] = useState('');
  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'phoneactive' : '');

    setHeightState(
      setActive === 'phoneactive' ? '0px' : `${content.current.scrollHeight}px`
    );
    setRotateState(
      setActive === 'phoneactive'
        ? 'PhoneAccordion__icon'
        : 'PhoneAccordion__icon Phonerotate'
    );
  }
  const handledate = (tim) => {
    const dayAfterEpoch = new Date(tim);
    var year = dayAfterEpoch.getFullYear();
    var day = dayAfterEpoch.getDate();
    var mountt = dayAfterEpoch.getMonth() + 1;
    if (mountt < 10) {
      var mount = `0${mountt}`;
    } else {
      var mount = mountt;
    }
    var aa = `${year}-${mount}-${day}`;

    setTimetest(moment(aa, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD'));
    let commission = timetest;
    return timetest;
  };
  return (
    <div className="PhoneAccordion__section">
      <button
        className={`PhoneAccordion ${setActive}`}
        onClick={toggleAccordion}
      >
        <PhoneChevron className={`${setRotate}`} width={10} fill={'#43AA8B'} />

        <p className="PhoneAccordion__title">{props.title}</p>
      </button>
      {props.content.map((index, i) => (
        <div
          ref={content}
          style={{maxHeight: `${setHeight}`}}
          className="PhoneAccordion__content"
        >
          <div
            className={
              props.comment
                ? 'PhoneAccordion__Commentcontent'
                : 'PhoneAccordion__contentBox'
            }
          >
            {props.comment ? (
              <div>
                <div
                  className="PhoneAccordion__text"
                  dangerouslySetInnerHTML={{__html: ''}}
                />

                <div className="UserCommentsectionBox">
                  <>
                    <div className="UserCommentsectionHeadrBox">
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          alignItems: 'center',
                        }}
                      >
                        <img
                          src={index.user_picture}
                          className="UserCommentsectionHeadrIco"
                          alt={index}
                        ></img>
                        <p className="UserCommentsectionHeadrTitle">
                          {index.username}
                        </p>
                      </div>
                      <p className="UserCommentsectionHeadrTime">
                        {/* {index.date_created} */}
                        {() => handledate(index.date_created)}
                      </p>
                    </div>
                    <div className="UserCommentsectionContentBox">
                      <p className="UserCommentsectionContent">{index.text}</p>
                    </div>
                  </>
                </div>
              </div>
            ) : props.sar ? (
              <>
                {' '}
                <p className="UserCommentsectionHeadrTitle">
                  {index.title}
                </p>{' '}
              </>
            ) : (
              <div
                className="PhoneAccordion__text"
                dangerouslySetInnerHTML={{__html: index.titr}}
              />
            )}
          </div>
          {/* <p>{index.titr}</p> */}
        </div>
      ))}
    </div>
  );
}

export default PhoneAccordion;
const comment = [
  {
    img: user1,
    title: 'کاربرشماره یک',
    time: '20 اردیبهشت 1398',
    txt: 'لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود.',
  },
];

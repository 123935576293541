import React, {useState, useEffect} from 'react';
import img1 from '../../img/mycourse/react(2).png';
import Circle from 'react-circle';
import {BtnSecondary} from '.././share/Btn';
import {API_URL} from '../../constacts';
import {useAuth} from '../../context/AuthContext';
import useAxios from '@use-hooks/axios';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import {useUser} from '../../context/UserContext';
import {useList} from '../../context/SeasonList';
import Cookies from 'js-cookie';

const LastActivity = () => {
  const {token} = useAuth();
  const [log, setLog] = useState([]);

  const getlog = useAxios({
    url: `${API_URL}/profile/logs/`,
    method: 'GET',
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log('res', res.data);
        setLog(res.data.data);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });

  useEffect(() => {
    getlog.reFetch();
  }, []);
  return (
    <div className="mycourse-defaultbox">
      <div className="mycourse-defaultheader">اخرین فعالیت های من در سایت</div>
      {log.length !== 0 ? log.map((i) => <CartBody i={i} />) : null}
    </div>
  );
};

export default LastActivity;
const CartBody = ({i}) => {
  const {userDispatch, testtt} = useUser();
  const {listDispatch, list} = useList();
  const {token} = useAuth();
  const history = useHistory();
  const contentIdCourse = useAxios({
    url: `${API_URL}/sidebar/sidebar-season/${i.course_id}/`,
    method: 'GET',
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        listDispatch({type: 'LISTLOGIN', list: res.data.data});
        history.push(`/courcontent/${i.course_id}`);
      }
      if (err) {
        console.log(err);
      }
    },
  });

  const gotocourse = () => {
    contentIdCourse.reFetch();
  };

  useEffect(() => {
    //goto.reFetch();
  }, []);
  return (
    <div className="mycourse-defaulbody">
      <div className="lastactivity-card">
        <div className="lastactivity-card--img">
          <img src={i.course_cover} />
        </div>
        <div className="lastactivity-card--text">
          <p style={{textAlign: 'right'}}>{i.course_name}</p>
          <div className="lastactivity-card--progress">
            <p>:وضعیت دوره</p>
            <div className="lastactivity__Circle">
              <Circle
                progress={i.course_progress}
                responsive={false}
                size={35}
                progressColor="#fe7f2d"
                bgColor="#fff"
                textColor="#323232"
                textStyle={{
                  fontSize: '12rem',
                  fontWeight: 'bold',
                }}
                lineWidth={35}
                animate={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        {i.example_name ? (
          <div className="d-flex name__sec">
            <p>نام مثال</p> :<p>{i.example_name}</p>
          </div>
        ) : i.quiz_name ? (
          <div className="d-flex name__sec">
            <p>نام آزمون</p> :<p>{i.quiz_name}</p>
          </div>
        ) : i.content_name ? (
          <div className="d-flex name__sec">
            <p>نام درس</p> :<p>{i.content_name}</p>
          </div>
        ) : null}
      </div>

      <BtnSecondary className="lastactivity__btn" onClick={gotocourse}>
        ورود به دوره
      </BtnSecondary>
    </div>
  );
};

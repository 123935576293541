import React, { useContext, useState, useEffect } from "react";
import CardCourseList from "./CardCourseList";
import { useAuth } from "../../context/AuthContext";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import CardTipsBox from "./CardTipsBox";
import CardfinalCostBox from "./CardfinalCostBox";
import { BtnSecondary, BtnOrangeTransparent } from "../share/Btn";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { GetUserInfoContext } from "../../context/GetUserInfoContext";
import "../../css/main.scss";

const CardContainer = (props) => {
  const [code, setCode] = useState(0);
  const [reload, setReload] = useState(false);
  const [final, setFinal] = useState(false);
  const [final1, setFinal1] = useState(false);
  const [list, setList] = useState(0);
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const { summery } = useContext(GetUserInfoContext);
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  const handleClick = () => {
    if (list !== 0) {
      setLoading(true);
      finalpay.reFetch();
    }
  };
  const finalPaysuccess = () => {
    toast.success("خرید با موفقت انجام شد", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  let history = useHistory();
  const finalpay = useAxios({
    url: `${API_URL}/payment/completed-or-not/`,
    method: "POST",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        summery.reFetch();
        setLoading(false);
        finalPaysuccess();
        history.push("/mycourse");
        setFinal(!final);
      }
      if (err) {
        console.log(err.response);
        setLoading(false);
      }
    },
  });

  return (
    <section className="Card-container-sec">
      <div className="container">
        <div className="Card-Container">
          <div className="Card-sidebar">
            <CardTipsBox
              setCode={setCode}
              setFinal={setFinal}
              final={final}
              setFinal1={setFinal1}
              final1={final1}
            />
            <CardfinalCostBox
              code={code}
              reload={reload}
              setFinal={setFinal}
              final={final}
              setFinal1={setFinal1}
              final1={final1}
            />
            {list !== 0 ? (
              <BtnSecondary
                className="center centermargin"
                onClick={handleClick}
              >
                {loading ? (
                  <ClipLoader
                    color="#ffffff"
                    loading={loading}
                    css={override}
                    size={15}
                  />
                ) : (
                  "    ثبت و پرداخت نهایی"
                )}
              </BtnSecondary>
            ) : (
            <div className='center'>
                <BtnOrangeTransparent className="center centermargin Content__Btn-dis1">
                ثبت و پرداخت نهایی
              </BtnOrangeTransparent>
            </div>
            )}
          </div>
          <CardCourseList
            reload={reload}
            setReload={setReload}
            setFinal={setFinal}
            final={final}
            setFinal1={setFinal1}
            final1={final1}
            setList={setList}
          />
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default CardContainer;

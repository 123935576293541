import React, { createContext, useContext, useState, useEffect } from "react";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../constacts";
import { useAuth } from "../context/AuthContext";
import { useLocation } from "react-router-dom";

export const LanContext = createContext();

const LanContextProvider = (props) => {
  const { token } = useAuth();
  const [lan, setLan] = useState(0);
  const [id, setId] = useState("");
//   const location = useLocation();
//   useEffect(() => {
//     setId(location.state.id);
//   }, [location]);
  const getLan = useAxios({
    url: `${API_URL}/language/${id}/get/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        setLan(res.data.language);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });

  return (
    <LanContext.Provider value={{  lan }}>
      {props.children}
    </LanContext.Provider>
  );
};

export { LanContextProvider };

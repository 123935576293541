import React from 'react';
import teacher from '../../img/courselist/12790.png';
import {BtnSecondarybig} from '../share/Btn';
import '../../css/main.scss';

function TeachersBox(props) {
  return (
    <div id="CourseInfoTeachersBox">
      <div className="corseInfo__head__propertyBox ">
        <svg
          width="43%"
          height="50"
          viewPort="0 0 200 300"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line className="stroke" x1="40" y1="30" x2="620" y2="30"></line>
        </svg>
        <p className="corseInfo__head__propertyTitle">مدرسان دوره</p>
        <svg
          width="43%"
          height="50"
          viewPort="0 0 200 300"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line className="stroke" x1="5" y1="30" x2="820" y2="30"></line>
        </svg>
      </div>
      <div className="TeachersBox">
        <div className="TeachersBox__Table">
          <div style={{display: 'flex', alignItems: 'center'}}>
            <h5>:گفته</h5>
            {props.last !== null && <h5>{props.last}</h5>}
            {props.name !== null && <h5>{props.name} </h5>}
          </div>
          {props.txt !== null && <p>{props.txt}</p>}

          <BtnSecondarybig className="TeachersBox__Btn">
            <i className="fas fa-chevron-left"></i>
            <p>مشاهده پروفایل</p>
          </BtnSecondarybig>   

        </div>
        <div style={{width:'155px' ,height:'155px',borderRadius:'50%' , overflow:'hidden'}} className='center'>
        {props.pic !== null && (
         
         <img src={props.pic} alt={props.pic} className="TeachersBox__img" />

       
       )}
        </div>
      </div>
    </div>
  );
}

export default TeachersBox;

/* eslint-disable import/no-anonymous-default-export */
import { useState } from "react";

export default (initialVal) => {
  const [passwordShown, setPasswordShown] = useState(initialVal);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  return [passwordShown, togglePasswordVisiblity];
};

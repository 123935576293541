import React from 'react'
import "../../css/main.scss"
const Footer = () => {
    return (
        <footer>

        </footer>
    )
}

export default Footer

import React, {useState, useEffect} from 'react';
import {Player} from 'video-react';
import axios from 'axios';
import Sizes from '../../Sizes/Sizes';
import UseWindowSize from '../../Sizes/UseWindowSize';
import '../../css/main.scss';
import "video-react/dist/video-react.css";

function CorseIntro({intro,video}) {
  return (
    <div>
      <div className="CorseIntroPlayerBox">
        <Player
          className="CorseIntro__video"
          preload
          width="100%"
          height={400}
          src={video}
          fluid={false}
          playsInline
       />

        <div className="CorseIntro__videoheader-Title"> معرفی دوره</div>
      </div>

      <div className="Intro__contentTxt ">
        <div>{intro}</div>
      </div>
    </div>
  );
}

export default CorseIntro;

import React, {createContext, useReducer} from 'react';
import {listReducer} from '../reducers/ListReducer';

const SeasonList = createContext();

const ListProvider = (props) => {
  const [list, listDispatch] = useReducer(
    listReducer,
    localStorage.getItem('list')
  );

  return (
    <SeasonList.Provider value={{list, listDispatch}} {...props}>
      {props.children}
    </SeasonList.Provider>
  );
};

const useList = () => React.useContext(SeasonList);

export {ListProvider, useList};

import React from "react";
import "../../css/main.scss";

function Properties(props) {
  return (
    <li className="GridRows">
      <span className={props.className}>
        <i
          className="fas fa-check"
          style={{
            border: "1px solid #0DCA78",
            borderRadius: "50%",
            padding: ".2rem",
            boxSizing: "content-box",
            fontSize: "1rem",
            marginLeft: ".5rem",
          }}
        ></i>
      </span>
      {props.text}
    </li>
  );
}

export { Properties };

function TeacherProperties(props) {
  return (
    <li
      className={`GridRows disable ${props.margStyle}`}
    >
      <span className={props.className}>
        <i
          className="fas fa-check"
          style={{
            border: "1px solid #0DCA78",
            borderRadius: "50%",
            padding: ".2rem",
            boxSizing: "content-box",
            fontSize: "1.25rem",
            marginLeft: ".5rem",
          }}
        ></i>
      </span>
      {props.children}
    </li>
  );
}
export { TeacherProperties };

import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../constacts";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/main.scss";
import { useForm } from "react-hook-form";
import { DraftContext } from "../../context/DraftComment";
import { ErrorMessage } from "@hookform/error-message";
import { BtnSecondary } from "../share/Btn";

const UserReply = (props) => {
  const [id, setId] = useState("");
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);
  const [text, settext] = useState("");
  const { token } = useAuth();
  const { getDraftcomment } = useContext(DraftContext);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const userloginnotiy = () => {
    toast.error("برای ثبت نظر ابتدا وارد سایت شوید", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  //
  const registerSucessnotify = () => {
    toast.success(
      "نظر شما با موفقیت ارسال شد و پس از تایید ادمین نشان داده میشود",
      {
        position: toast.POSITION.TOP_CENTER,
      }
    );
  };

  useEffect(() => {
  }, []);
  const editcomments = useAxios({
    url: `${API_URL}/reply/${props.id}/create/`,
    method: "POST",
    options: {
      data: text,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        registerSucessnotify();
        console.log("editcm", res.data);
        props.setreplyform();
        props.getdraftreply();
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  const onSubmit = (info) => {
    settext(info);
    editcomments.reFetch();
  };
  return (
    <div className="replyform">
      <form onSubmit={handleSubmit(onSubmit)}>
        <textarea
          className="replyform__textarea"
          rows="4"
          {...register("text", { required: "فیلد را پر کنید" })}
          placeholder="دیدگاه خود را تایپ کنید"
        />
        <BtnSecondary className="replyform__btn">ارسال پاسخ</BtnSecondary>
      </form>
    </div>
  );
};

export default UserReply;

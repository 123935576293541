import React, { useContext, useEffect, useState } from "react";
import CourseContentNav from "../components/CourseContent/CourseContentNav";
import SarfaslTable from "../components/CourseContent/SarfaslTable";
import UseWindowSize from "../Sizes/UseWindowSize";
import { API_URL } from "../constacts";
import useAxios from "@use-hooks/axios";
import { useAuth } from "../context/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import "../css/main.scss";
import { ContentSidebarProvider } from "../context/ContentSidebarContext";
import { GetContentInoContextProvider } from "../context/GetContentInoContext";
import ContentContainer from "../components/CourseContent/ContentContainer";
import Loader from "../components/Common/Loader";
import { BtnSecondary } from "../components/share/Btn";
import CourseSeasonModal from "../components/CourseSeason/CourseSeasonModal";

function CourseContent(props) {
  const [state, setState] = useState(false);
  const windowSize = UseWindowSize();
  const [lan, setLan] = useState(0);
  const [id, setId] = useState("");
  // const {  lan } = useContext(LanContext);
  const [quizecourseModal, setquizecourseModal] = useState(false);
  const { token } = useAuth();
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
    getLan.reFetch();
  }, [, location]);
  // useEffect(() => {
  //   getLan.reFetch();
  // }, [, location]);
  const OpenModalQuize = () => {
    setquizecourseModal(true);
  };
  const CloseModalQuize = () => {
    setquizecourseModal(false);
  };
  let history = useHistory();
  const getLan = useAxios({
    url: `${API_URL}/language/${location.state.id}/get/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        setLan(res.data.language);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  ///sidebar/quizzes_of_course_in_sidebar/2/
  return (
    <ContentSidebarProvider>
      {getLan.response?.data &&
        (windowSize === "lg" ||
        windowSize === "md" ||
        windowSize === "md-2" ||
        windowSize === "sm" ? (
          <div
            className=" container"
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column-reverse",
              paddingTop: "4rem",
            }}
          >
            <div className="CourseContent1">
              <SarfaslTable />
              <BtnSecondary onClick={OpenModalQuize}>
                آزمون های درس{" "}
              </BtnSecondary>
            </div>
            <div>
              <GetContentInoContextProvider>
                {/* <CourseContentNav /> */}
                <ContentContainer setState={setState} lan={lan} />
              </GetContentInoContextProvider>
            </div>
            {quizecourseModal && (
              <CourseSeasonModal CloseModalQuize={CloseModalQuize} />
            )}
          </div>
        ) : (
          <div
            className="CourseContent container"
            style={{ position: "relative" }}
          >
            <div className="CourseContent1">
              <SarfaslTable />
              <BtnSecondary onClick={OpenModalQuize}>
                آزمون های دوره{" "}
              </BtnSecondary>
            </div>
            <div className="CourseContent-container">
              <GetContentInoContextProvider>
                <CourseContentNav id={id} />
                <ContentContainer setState={setState} lan={lan} />
              </GetContentInoContextProvider>
            </div>

            {quizecourseModal && (
              <CourseSeasonModal CloseModalQuize={CloseModalQuize} />
            )}
          </div>
        ))}
    </ContentSidebarProvider>
  );
}

export default CourseContent;

import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { useAuth } from "../../context/AuthContext";
import UseCopyToClipboard from "../hooks/UseCopyToClipboard";
import QuizEditor from "../codeeditor/QuizEditor";
import { ContentSidebarContext } from "../../context/ContentSidebarContext";
import { BtnSecondary, BtnOrangeTransparent } from "../share/Btn";
import { GetContentInoContext } from "../../context/GetContentInoContext";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { textState, textState1 } from "../../Recoil/RecoilState";
import { useLocation, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import ExampleTextContainer from "../Example/ExampleTextContainer";
function QuizDashboard({ contentInfo, couid }) {
  const [info, setInfo] = useState("");
  const [sea1, setSea1] = useState("");
  const [seaid, setSeaid] = useState("");
  const [seaid1, setSeaid1] = useState("");
  const [pass, setPass] = useState();
  const [result, setResult] = useState(0);
  const [code, setCode] = useState("");

  const [contentid, setContentid] = useRecoilState(textState);
  // const [list, setList] = useRecoilState(textState1);
  const { token } = useAuth();
  const [id, setId] = useState("");
  const [quizid, setQuizid] = useState("");
  const location = useLocation();
  const [lan, setLan] = useState("");
  useEffect(() => {
    setId(location.state.id);
    setQuizid(location.state.quizid);
    setCode(location.state.code);
  }, [location]);

  useEffect(() => {
    if (location) {
      Quiztest.reFetch();
      getLan.reFetch();
    }
    // checkUserPassSeason.reFetch();
  }, []);
  const getLan = useAxios({
    url: `${API_URL}/language/${id}/get/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        setLan(res.data.language);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  Cookies.set("path", window.location.href);
  const path = Cookies.get("path") ? Cookies.get("path") : null;
  const tokencookie = Cookies.get("token") ? Cookies.get("token") : null;
  const Quiztest = useAxios({
    url: `${API_URL}/quiz/${quizid}/get/`,
    method: "GET",
    options: {
      withCredentials: true,
      headers: {
        Authorization: `JWT ${token}`,
      },
      Cookies: {
        path: path,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("Quiztest", res.data);
        setInfo(res.data);
        // quizseason();
      }
      if (err) {
        console.log(err.response);
        // quizseason();
      }
    },
  });

  let history = useHistory();

  const handleNextCourse = () => {};
  return (
    <div className="Example">
      {Quiztest.response?.data && (
        <>
          <ExampleTextContainer test_cases={info.test_cases} text={info.text} />
          <QuizEditor
            name={info.name}
            latest_content_id_in_season={info.latest_content_id_in_season}
            lan={lan}
            code={true}
          />
        </>
      )}
    </div>
  );
}

export default QuizDashboard;

import React from "react";
import ShowCase from "../share/ShowCase";
import HomeshowcaseImage from "../../img/courselist/Group 947.png";
import Navigation from "../share/Navigation";
import UseWindowSize from "../../Sizes/UseWindowSize";
import PhoneNavigation from "../share/PhoneNavigation";
import "../../css/main.scss";

const CouseListHeader = () => {
  const windowSize = UseWindowSize();
  return (
    <header className="header headercourselist" id="header">
      <div className="container">

        <ShowCase
          titleFirstSpan="اکنون زمان یادگیری"
          titleSecondSpan="مهارت برنامه نویسی است"
          homeShowcaseImage={HomeshowcaseImage}
        >
          <div className="showcase-text" style={{ maxWidth: "38rem" }}>
            مهارت های خود را بادوره هایی در مورد محبوب ترین زبان های برنامه
            نویسی توسعه دهید
          </div>
        </ShowCase>
      </div>
      <div className="arrow__down">
      <i class="fas fa-arrow-down"></i>
      </div>
    </header>
  );
};
export default CouseListHeader;

/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { Player } from "video-react";
import { withStyles } from "@material-ui/core/styles";
import { BtnSecondary, BtnOrangeTransparent } from "../share/Btn";
import { ContentSidebarContext } from "../../context/ContentSidebarContext";
import { useAuth } from "../../context/AuthContext";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import "../../css/main.scss";
import { useRecoilState } from "recoil";
import { textState, textState1, textState2 } from "../../Recoil/RecoilState";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import { useUser } from "../../context/UserContext";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";

const styles = {
  textcolor: {
    fontSize: "1.255rem",
    marginLeft: "2rem",
    position: "relative",
  },
  video: {
    width: "100%",
    height: "93%",
    backgroundColor: "#a6adb4",
    "&:focus": {
      outline: "none",
      border: "none",
    },
  },
};

function Content({ contentInfo, setState, contents, seasonlist, seasonlist2 }) {
  const { getSidebarSesons } = useContext(ContentSidebarContext);
  // const [list, setList] = useRecoilState(textState1);
  const [contentid, setContentid] = useRecoilState(textState);
  const [season, setSeason] = useState(contentInfo.id);
  const [count, setCount] = useState(contentInfo.id);
  const [pass, setPass] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadnext, setLoadnext] = useState(false);
  const [id, setId] = useState("");
  const { token } = useAuth();
  const location = useLocation();
  const [contenttitle, setContenttitle] = useRecoilState(textState2);
  const [info, setInfo] = useState();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: #fe7f2d;
  `;
  const getNextbtn = useAxios({
    url: contentid
      ? `${API_URL}/state/currentContentState/${contentid}/`
      : null,
    method: "GET",
    options: {
      // data: data,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("QuizResult", res.data);
        setInfo(res.data);
      }
      if (err) {
        console.log(err.response);
        setLoad(false);
      }
    },
  });

  useEffect(() => {
    getNextbtn.reFetch();

    if (seasonlist && contenttitle !== null) {
      seasonlist.seasons.map((index, arr) =>
        !index.lockedOn
          ? index.contents.map((ind, idcon) =>
              ind.unique_id === contenttitle
                ? !ind.lockedOn
                  ? ind.is_content_passed && setPass(true)
                  : null
                : null
            )
          : null
      );
    }
    if (seasonlist && contenttitle === null) {
      seasonlist.seasons.map((index, arr) =>
        !index.lockedOn
          ? index.contents.map((ind, idcon) =>
              ind.id === contentid
                ? !ind.lockedOn
                  ? ind.is_content_passed && setPass(true)
                  : null
                : null
            )
          : null
      );
    }
  }, [, contentInfo]);

  Cookies.set("path", window.location.href);
  console.log("contenttitle", contenttitle);
  let history = useHistory();

  const handleNext = () => {
    setLoadnext(true);
    setContentid(info.next_content_id);
  };

  const handleBack = () => {
    setLoad(true);
    setContentid(info.prev_content_id);
  };
  const AllCourse = () => {
    toast.error("همه ی دروس این فصل هنوز پاس نشده اند", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const Corse = () => {
    toast.error("همه ی فصل این دوره هنوز پاس نشده اند", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const successpasslastcontent = () => {
    toast.success("درس شما با موفقیت پاس شد", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const checkuserPass = useAxios({
    url: `${API_URL}/pass/user-content-pass/create/${contentid}/`,
    method: "POST",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("checkuserPass", res.data);
        setLoadnext(false);

        if (
          res.data.data === "This Course Already Passed" ||
          res.data.data === "Successfully Created" ||
          res.data.data === "Season Quiz Already Passed"
        ) {
          if (
            info.has_next_content &&
            info.next_content_id === "You have not passed quiz season"
          ) {
            getSidebarSesons.reFetch();
          } else if (info.has_next_content) {
            handleNext();
            setLoadnext(false);
          } else {
            successpasslastcontent();
            getSidebarSesons.reFetch();
            setLoadnext(false);
          }
        } else if (
          res.data.data === "All content of this season didnt pass yet"
        ) {
          AllCourse();
        } else if (
          res.data.data === "All season of this course didnt pass yet"
        ) {
          Corse();
        } else {
          if (
            info.has_next_content &&
            info.next_content_id === "You have not passed quiz season"
          ) {
            getSidebarSesons.reFetch();
            setLoadnext(false);
          } else if (info.has_next_content) {
            handleNext();
            setLoadnext(false);
          } else {
            successpasslastcontent();
            getSidebarSesons.reFetch();
            setLoadnext(false);
          }
        }
      }
      if (err) {
        console.log(err.response);
        if (
          err.response.data === "Content ID Already exists" ||
          err.response.data === "Successfully Created"
        ) {
          handleNext();
        }
      }
    },
  });
  const handleNextnoquiz = () => {
    setLoadnext(true);
    console.log("here");
    checkuserPass.reFetch();
  };
  const handleNextnoquiz1 = () => {
    setLoadnext(true);
    console.log("here");
    setContentid(info.next_content_id);
    checkuserPass.reFetch();
  };
  const handlepasslastcontent = () => {
    setLoadnext(true);
  };
  return (
    <div className="contentMain">
      {contentInfo !== null ? (
        <div className="Content__videoLoader">
          <>
            <Player
              className="CorseIntro__video"
              playsInline
              fluid={false}
              width="100%"
              height={400}
              src={contentInfo.video}
            ></Player>

            <div className="CorseIntro__video-Title">
              <p>{contentInfo.title} </p>
              <span> </span>
              <p className="befor-arrow"> {contentInfo.season}</p>
              <span> </span>

              <p className="befor-arrow"> {contentInfo.course}</p>
            </div>
          </>
        </div>
      ) : null}
      {getNextbtn.response?.data && (
        <div className="mt-2 Content__BtnBox ">
          <div className="d-flex">
            {!info.has_prev_content ? (
              <BtnOrangeTransparent className=" Content__Btn-dis1">
                درس قبل
              </BtnOrangeTransparent>
            ) : (
              <BtnOrangeTransparent
                className=" Content__Btn1"
                onClick={handleBack}
              >
                {load ? (
                  <ClipLoader
                    color="#ffffff"
                    loading={true}
                    css={override}
                    size={15}
                  />
                ) : (
                  "   درس قبل"
                )}
              </BtnOrangeTransparent>
            )}
            {contentInfo.quiz !== null ? (
              pass &&
              info.has_next_content &&
              info.next_content_id !== "You have not passed quiz season" ? (
                <BtnOrangeTransparent
                  className=" Content__Btn1"
                  onClick={handleNext}
                >
                  {loadnext ? (
                    <ClipLoader
                      color="#ffffff"
                      loading={loadnext}
                      css={override}
                      size={15}
                    />
                  ) : (
                    "  درس بعد"
                  )}
                </BtnOrangeTransparent>
              ) : (
                <BtnOrangeTransparent className=" Content__Btn-dis1">
                  درس بعد
                </BtnOrangeTransparent>
              )
            ) : info.has_next_content &&
              info.next_content_id !== "You have not passed quiz season" ? (
              <BtnOrangeTransparent
                className=" Content__Btn1"
                onClick={handleNextnoquiz1}
              >
                {loadnext ? (
                  <ClipLoader
                    color="#ffffff"
                    loading={loadnext}
                    css={override}
                    size={15}
                  />
                ) : (
                  "  درس بعد"
                )}
              </BtnOrangeTransparent>
            ) : info.has_next_content &&
              info.next_content_id === "You have not passed quiz season" ? (
              <BtnOrangeTransparent
                className=" Content__Btn1"
                onClick={handleNextnoquiz}
              >
                {loadnext ? (
                  <ClipLoader
                    color="#ffffff"
                    loading={loadnext}
                    css={override}
                    size={15}
                  />
                ) : (
                  "  پاس درس "
                )}
              </BtnOrangeTransparent>
            ) : !info.has_next_content ? (
              <BtnOrangeTransparent
                onClick={handleNextnoquiz}
                className=" Content__Btn1"
              >
                {loadnext ? (
                  <ClipLoader
                    color="#ffffff"
                    loading={loadnext}
                    css={override}
                    size={15}
                  />
                ) : (
                  "   پاس درس "
                )}
              </BtnOrangeTransparent>
            ) : (
              <BtnOrangeTransparent className=" Content__Btn-dis1">
                درس بعد
              </BtnOrangeTransparent>
            )}
          </div>
          <div className="d-flex">
            <NavLink
              to={{
                pathname: `/codeeditor`,
                state: { id: id },
              }}
            >
              <BtnOrangeTransparent className=" Content__Btn1">
                مشاهده ادیتور
              </BtnOrangeTransparent>
            </NavLink>
            {contentInfo.quiz !== null ? (
              <NavLink
                to={{
                  pathname: `/quiz`,
                  state: {
                    id: id,
                    quizid: contentInfo.quiz,
                    contentid: contentid,
                    code: false,
                  },
                }}
              >
                <BtnSecondary className=" Content__Btn">آزمون</BtnSecondary>
              </NavLink>
            ) : (
              <BtnSecondary disabled={true} className=" Content__Btn-dis">
                آزمون
              </BtnSecondary>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default withStyles(styles)(Content);

import React, {
  createContext,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../constacts";
import { useAuth } from "../context/AuthContext";
import { ContentSidebarContext } from "./ContentSidebarContext";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { textState } from "../Recoil/RecoilState";
import Cookies from "js-cookie";
import { useUser } from "./UserContext";

export const GetContentInoContext = createContext();
const GetContentInoContextProvider = (props) => {
  const { token } = useAuth();
  const [contentInfo, setcontentInfo] = useState(null);
  // const {contentid } = useContext(ContentSidebarContext);
  Cookies.set("path", window.location.href);
  const [contentid, setContentid] = useRecoilState(textState);
  const path = Cookies.get("path") ? Cookies.get("path") : null;
  const tokencookie = Cookies.get("token") ? Cookies.get("token") : null;
  const { testtt, userDispatch } = useUser();

  const getContentInfo = useAxios({
    url:
      contentid === null
        ? `${API_URL}/content/${testtt}/get/`
        : `${API_URL}/content/${contentid}/get/`,
    method: "GET",
    options: {
      withCredentials: true,

      headers: {
        Authorization: `JWT ${token}`,
      },
      cookies: {
        path: path,
      },
    },

    //  trigger:contentid,
    customHandler: (err, res) => {
      if (res) {
        console.log("res", res.data);
        setcontentInfo(res.data);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });

  return (
    <GetContentInoContext.Provider value={{ getContentInfo, contentInfo }}>
      {props.children}
    </GetContentInoContext.Provider>
  );
};

export { GetContentInoContextProvider };

import React from "react";
import "../../css/main.scss";

function Feature(props) {
  return (
    <div className={`content__box content__box-${props.i}`}>
      <div className={`img__back  img__back-${props.i} `}>
        <img src={props.img} className="img_size" alt={props.title}></img>
      </div>
      <div className="text__box">
        <h2 className="text__titr">{props.title}</h2>
        <p className="text__description">{props.description}</p>
      </div>
    </div>
  );
}


export default Feature;

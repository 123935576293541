import React, {createContext, useState, useEffect} from 'react';
import {coursese} from '../data/courses';
import {API_URL} from '../constacts';
import useAxios from '@use-hooks/axios';
import {useAuth} from './AuthContext';

export const CardCourses = createContext();

const CardCoursesProvider = (props) => {
  const [addToCardDliaog, setAddToCardDliaog] = useState(false);
  const [alredyaddedDialog, setalredyaddedDialog] = useState(false);
  const [alredyaddedDialog1, setalredyaddedDialog1] = useState(false);
  const [alredyaddedDialog2, setalredyaddedDialog2] = useState(false);
  const [cardCourse, setCardCourse] = useState([]);
  const {token} = useAuth();


  const summary = useAxios({
    url: `${API_URL}/cart/order-summary/`,
    method: 'GET',
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        setCardCourse(res.data.results);
        setalredyaddedDialog2(!alredyaddedDialog2);
        console.log("res.data",res.data);
      }
      if (err) {
        console.log(err.response);
        // setalredyaddedDialog2(!alredyaddedDialog2);
      }
    },
  });
  const addToCard = (newCourse, id) => {
    if (cardCourse.length === 0) {
      setCardCourse([...cardCourse, newCourse]);
      setAddToCardDliaog(true);
      setTimeout(() => {
        setAddToCardDliaog(false);
      }, 10000);
    }
    // for (let i = 0; i < cardCourse.length; i++) {
    //   if (cardCourse.course_name === newCourse.course_name) {
    //     setAddToCardDliaog(true);
    //     setalredyaddedDialog(true);
    //     setCardCourse([...cardCourse]);
    //     setTimeout(() => {
    //       setAddToCardDliaog(false);
    //       setalredyaddedDialog(false);
    //     }, 1000);
    //   } else {
    //     setCardCourse([...cardCourse, newCourse]);
    //     setAddToCardDliaog(true);
    //     // setalredyaddedDialog(false);

    //     setTimeout(() => {
    //       setAddToCardDliaog(false);
    //     }, 30000);
    //   }
   // }
  };
  const closeAddtocardDialog = () => {
    setAddToCardDliaog(false);
  };
  const removerCard = (id) => {
    setCardCourse(cardCourse.filter((c) => c.id !== id));
    setalredyaddedDialog1(!alredyaddedDialog1);
  };

  return (
    <CardCourses.Provider
      value={{
        cardCourse,
        addToCardDliaog,
        alredyaddedDialog,
        setalredyaddedDialog,
        setalredyaddedDialog1,
        setAddToCardDliaog,
        addToCard,
        closeAddtocardDialog,
        removerCard,
        setCardCourse,
        summary
      }}
    >
      {props.children}
    </CardCourses.Provider>
  );
};

export default CardCoursesProvider;

import React, { useContext, useEffect, useState } from "react";
import { BtnPrimary, BtnSecondarybig } from "../share/Btn";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../constacts";
import { useAuth } from "../../context/AuthContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/main.scss";
import { useForm } from "react-hook-form";
import { DraftContext } from "../../context/DraftComment";
import { ErrorMessage } from "@hookform/error-message";

const EditReplyForm = (props) => {
    const [text, settext] = useState("");
    const { token } = useAuth();
    const { getDraftcomment } = useContext(DraftContext);
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
      reset,
    } = useForm();
    const userloginnotiy = () => {
      toast.error("برای ثبت نظر ابتدا وارد سایت شوید", {
        position: toast.POSITION.TOP_CENTER,
      });
    };
    //
    const registerSucessnotify = () => {
      toast.success(
        "نظر شما با موفقیت ارسال شد و پس از تایید ادمین نشان داده میشود",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    };
   
   
    useEffect(() => {
    }, [])
    const editcomments = useAxios({
      url: `${API_URL}/reply/${props.id}/`,
      method: "PUT",
      options: {
        data: text,
        headers: {
          Authorization: `JWT ${token}`,
        },
      },
      customHandler: (err, res) => {
        if (res) {
          registerSucessnotify();
          props.setEdit(false)
          props.refetchreplyagain()
          console.log("editcm", res.data);

        }
        if (err) {
         
          console.log(err.response);
        }
      },
    });
    const onSubmit = (info) => {
        settext(info);
        editcomments.reFetch();
      };
      return(
        <div>
        
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="EditReplyForm__display">
              <div className="EditReplyForm__form">
                <div className="corurseinfo__form--box">
                  <textarea
                    type="text"
                    name="text"
                    {...register("text", { required: "فیلد را پر کنید" })}
                    id="popupform__right-login-caption"
                    className="EditReplyForm__form--textarea"
                    rows="4"
                    placeholder="گوشمون با شماست..."
                    defaultValue={props.text}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="text"
                    render={({ message }) => <p style={{color:'red'}}>{message}</p>}
                  />
                </div>
              </div>
              <div className="EditReplyForm__btn d-flex">
                <button type='submit' className="BtnSecondary EditReplyFormBtn EditReplyFormBtn-pri">
                  <p>ارسال برای ما</p>
                </button>
                <button type='button' className="EditReplyFormBtn EditReplyFormBtn-sec" onClick={()=>props.setEdit(false)} >
                    برگشت به کامنت
                </button>
                
               
              </div>
            </div>
          </form>
        </div>
      )
}

export default EditReplyForm

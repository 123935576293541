import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { useAuth } from "../../context/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { ContentSidebarContext } from "../../context/ContentSidebarContext";

const CourseSeasonModal = ({ CloseModalQuize }) => {
  const { token } = useAuth();
  const [id, setId] = useState("");
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);
  //const [CourseSeseon, setCourseSeason] = useState([]);
  const { seasonlist, seasonlist1 } = useContext(ContentSidebarContext);

  return (
    <div className="CourseSeasonModal__background">
      <div className="CourseSeasonModal__container">
        <div className="CourseSeasonModal__close" onClick={CloseModalQuize}>
          <i class="fas fa-times"></i>
        </div>
        <ul className="list CourseSeasonModal__list">
          {seasonlist !== null ? (
            seasonlist.quizzes.map((i, index) => (
              <Link
                to={{
                  pathname: `/quiz`,
                  state: { id: id, quizid: i.id, code: false },
                }}
              >
                <li className="CourseSeasonModal__li">
                  <p>{index + 1}</p>.<p>{i.name}</p>
                </li>
              </Link>
            ))
          ) : (
            <h2>این درس آزمونی ندارد</h2>
          )}
        </ul>
      </div>
    </div>
  );
};

export default CourseSeasonModal;

import React from "react";

const HowDiv = (props) => {
  return (
    <div className="how__box ">
      <div className={`how__box-pic how__box-pic--${props.classNumber}`}>
        <img src={props.picture} alt={`How ${props.id}`} />
      </div>
      <div
        className={`how__box-content how__box-content--${props.classNumber} `}
      >
        <h4 className="h-4">{props.title}</h4>
        <p>{props.text}</p>
      </div>
    </div>
  );
};

export default HowDiv;

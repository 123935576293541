import React from 'react';
import '../../css/main.scss';
const PerviewCoursesinCard = ({cardCourse, closePerview, removerCard}) => {
  return (
    <div className="Card-box perview-box">
      <div className="perview-close-dialog" onClick={closePerview}>
        <i class="fas fa-times" style={{cursor: 'pointer'}}></i>
      </div>

      {cardCourse.map((c) => (
        <div className="perview-course">
          <div >
            <p>{c.course_name}</p>
            <i class="fas fa-chalkboard-teacher"></i>
          </div>
          <p>{`${c.cost_amount} ت`}</p>
          {/* <i
            onClick={() => {
              removerCard(c.id);
              closePerview();
            }}
            class="fas fa-times"
          ></i> */}
        </div>
      ))}
    </div>
  );
};

export default PerviewCoursesinCard;

import React from "react";
import { BtnTransparent } from "../share/Btn";

export const LastnewsItem = (props) => {
  return (
    <div className={`last__box last__box-${props.id}`}>
        <h5>{props.newsTitle}</h5>
        <p>{props.newsText}</p>
        <div>
          <BtnTransparent className={`colros${props.id}`}>
            <a href="#">ادامه مطلب</a>
          </BtnTransparent>
        </div>
    
    </div>
  );
};
export default LastnewsItem;

import React, { useState, useRef, useEffect } from "react";
import Circle from "react-circle";
import Chevron from "./Chevron";
import "../../css/main.scss";
import DefaultBox from "../Common/DefaultBox";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { useLocation } from "react-router";
import { useAuth } from "../../context/AuthContext";
import SideBarContent from "../CourseContent/SideBarContent";
import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { textState, textState2 } from "../../Recoil/RecoilState";
const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "15px",
  },
}));
///pass/check-user-content-pass/{content_id}/
function Accordion(props) {
  // const [seasonitem, setseasonItem] = useState(null);
  const [contentid, setContentid] = useRecoilState(textState);
  const [contenttitle, setContenttitle] = useRecoilState(textState2);
  const [seasonprogress, setseasonprogress] = useState(0);
  const { token } = useAuth();
  const classes = useStyles();
  const [id, setId] = useState("");
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);

  useEffect(() => {}, []);
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef(null);

  function toggleAccordion() {
    if (!props.info.lockedOn) {
      setActiveState(setActive === "" ? "active" : "");

      setHeightState(
        setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
      );
      setRotateState(
        setActive === "active" ? "accordion__icon" : "accordion__icon rotate"
      );
    }
  }
  return (
    <>
      {props.info !== null ? (
        <div
          className={
            !props.info.lockedOn
              ? "accordion__section"
              : "accordion__sectionDis"
          }
        >
          <button
            className={`accordion ${setActive}`}
            onClick={!props.info.lockedOn ? toggleAccordion : null}
            disabled={props.info.lockedOn}
          >
            {!props.info.lockedOn ? (
              <Chevron className={`${setRotate}`} width={15} fill={"#656565"} />
            ) : (
              <i className="fas fa-lock"></i>
            )}
            {setActive === "active" ? (
              <div className="accordion__Circle">
                <Circle
                  progress={props.info.progresses}
                  responsive={false}
                  size={44.1}
                  progressColor="#fe7f2d"
                  textColor="#323232"
                  textStyle={{
                    fontSize: "12rem",
                    fontWeight: "bold",
                  }}
                  bgColor="#eaeaea"
                  lineWidth={35}
                  animate={true}
                />
              </div>
            ) : props.info.is_season_passed ? (
              <i className="fas fa-check-circle"></i>
            ) : null}

            {setActive === "active" ? (
              <div>
                <p className="accordion__title ">{props.info.title}</p>
                <p className="accordion__Subtitle">
                  <span style={{ direction: "rtl" }}>
                    درس{props.info.get_nums_of_contents_for_each_season}{" "}
                  </span>
                  /{props.info.total_time_for_each_season}
                </p>
              </div>
            ) : (
              <Tooltip
                className={classes.root}
                title={
                  <h3
                    style={{
                      fontSize: "15px",
                      padding: "5px",
                      textAlign: "right",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {props.info.title}
                  </h3>
                }
                ransitionComponent={Fade}
                placement="bottom"
                arrow
                size="15px"
                aria-label={props.info.title}
              >
                <p className="accordion__title accordion__title1">
                  {props.info.title}
                </p>
              </Tooltip>
            )}
          </button>
          {props.info.contents
            ? props.info.contents.map((index, i) => (
                <SideBarContent
                  content={content}
                  index={index}
                  setHeight={setHeight}
                />
              ))
            : null}
          {props.info.season_quiz !== null && (
            <div
              ref={props.content}
              className="accordion__content"
              style={{
                maxHeight: `${props.setHeight}`,
                cursor: "pointer",
                textAlign: "right",
                fontSize: "15px",
                padding: "10px 20px",
                color: "#fcca46 ",
              }}
            >
              <Link
                style={{ color: "#fe7f2d" }}
                to={{
                  pathname: `/quiz`,
                  state: {
                    id: id,
                    quizid: props.info.season_quiz,
                    contentid: contentid,
                    code: false,
                  },
                }}
              >
                آزمون فصل : {props.info.season_quiz_name}
              </Link>
            </div>
          )}
          <div className="accordion__LineBotten"></div>
        </div>
      ) : null}
    </>
  );
}

export default Accordion;

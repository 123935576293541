import { NavLink } from "react-router-dom";
import { BtnSecondary } from "./Btn";
import Logo from "../../img/logo web.png";
import "../../css/main.scss";
import { useAuth } from "../../context/AuthContext";
import { useUser } from "../../context/UserContext";
import { useList } from "../../context/SeasonList";
import React, { useContext, useState, useEffect } from "react";
import Menu from "./menu";
import "../../css/main.scss";
import cardIcon from "../../img/menu-profile/Package.svg";
import ringIcon from "../../img/menu-profile/Ring.svg";
import profileIcon from "../../img/defaultprofile.png";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { GetUserInfoContext } from "../../context/GetUserInfoContext";
function PhoneNavigation() {
  const { authDispatch, token, authrefreshDispatch, refreshtoken } = useAuth();
  const { userDispatch, testtt } = useUser();
  const { listDispatch, list } = useList();
  const { userinfo, getUserInfo } = useContext(GetUserInfoContext);
  let history = useHistory();
  const logoutpage = useAxios({
    url: `${API_URL}/logout/`,
    method: "POST",
    options: {
      headers: {},
    },
    // trigger: resources,
    customHandler: (err, res) => {
      if (res) {
        console.log("res", res);
        authDispatch({ type: "LOGOUT", token });
        userDispatch({ type: "LOGOUTREFRESH", testtt });
        authrefreshDispatch({ type: "LOGOUT", refreshtoken });
        listDispatch({ type: "LISTCLEAR", list });
        history.push("/");
      }
      if (err) {
        console.log("error", err);
      }
    },
  });
  const logOut = () => {
    logoutpage.reFetch();
  };
  useEffect(() => {
    getUserInfo.reFetch();
  }, []);
  return (
    <div className="PhoneNavigationBox">
      <img src={Logo} alt="logo" className="phonenavigation__logo" />

      {!token ? (
        <div style={{ position: "absolute", top: "18px", left: "70px" }}>
          <NavLink
            exact
            activeClassName="is-active"
            to="/signin"
            className=""
            style={{ width: "60px" }}
          >
            <BtnSecondary className="Phonesignup-btn">ثبت نام</BtnSecondary>
          </NavLink>

          <NavLink
            exact
            activeClassName="is-active"
            to="/logIn"
            className="phonenav__btn phonesign-up"
            style={{ width: "60px" }}
          >
            ورود
          </NavLink>
        </div>
      ) : (
        <div
          style={{ position: "absolute", top: "9px", left: "70px" }}
          className="Profile__box"
        >
          <NavLink to="/card">
            <img
              src={cardIcon}
              alt={cardIcon}
              className="Profile__box--icon profile__icon cardIcon "
              style={{ marginTop: "5px" }}
            />
          </NavLink>
          <img
            src={ringIcon}
            className="Profile__box--icon profile__icon ringIcon"
            alt={ringIcon}
          />
          <div className="Profile__box-1">
            {userinfo !== null ? (
              <div className="Profile__box--img">
                {userinfo.cover !== null ? (
                  <img
                    className="Profile__box--icon"
                    src={userinfo.cover}
                    alt={userinfo.cover}
                  />
                ) : (
                  <img
                    className="Profile__box--icon"
                    src={profileIcon}
                    alt={profileIcon}
                  />
                )}
              </div>
            ) : null}
            <div className="Profile__submenu">
              <ul>
                <li>
                  <NavLink to="/user_setting">پروفایل</NavLink>
                </li>
                <li>
                  <NavLink to="/mycourse"> دوره های من</NavLink>
                </li>
                <li>
                  <NavLink to="/myfavecourse">دروس مورد علاقه</NavLink>
                </li>
                <li onClick={logOut}>خروج</li>
              </ul>
            </div>
          </div>
        </div>
      )}
      <div className="phoneNavigation">
        <input
          type="checkbox"
          className="phoneNavigation__checkbox"
          id="navi-toggel"
        />
        <label for="navi-toggel" className="phoneNavigation__botton">
          <span className="phoneNavigation__icon">&nbsp;</span>
        </label>
        <div className="phoneNavigation__background">&nbsp;</div>
        <nav className="phoneNavigation__nav">
          <ul className="phoneNavigation__list">
            <li className="phoneNavigation__item">
              <NavLink
                exact
                activeClassName="is-active"
                to="/"
                className="phoneNavigation__link"
              >
                خانه
              </NavLink>
            </li>
            <li className="phoneNavigation__item">
              <NavLink
                exact
                activeClassName="is-active"
                to="/CourseList"
                className="phoneNavigation__link"
              >
                آموزش
              </NavLink>
            </li>
            <li className="phoneNavigation__item">
              <a href="#" className="phoneNavigation__link">
                بلاگ
              </a>
            </li>
            <li className="phoneNavigation__item">
              <a href="#" className="phoneNavigation__link">
                سوالات متداول
              </a>
            </li>
            <a className="Phonenav__teacher">بخش مدرسان </a>
          </ul>
        </nav>
      </div>
    </div>
  );
}
export default PhoneNavigation;

import React, { useEffect, useState } from "react";
import { coursese } from "../../../data/courses";
import CourseDiv from "../../share/CourseDiv";
import { useLocation } from "react-router-dom";
import { API_URL } from "../../../constacts";
import useAxios from "@use-hooks/axios";
import "../../../css/main.scss";

function PhoneSimilarCurse(props) {
  const [id, setId] = useState("");
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);
  const [information, setInformation] = useState("");
  useEffect(() => {
    Similar.reFetch();
  }, []);
  const Similar = useAxios({
    url: `${API_URL}/courses/similar/${id}/`,
    method: "GET",
    options: {
      headers: {},
    },
    customHandler: (err, res) => {
      if (res) {
        setInformation(res.data);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  return (
    <div className="PhoneSimilarCurse">
      <div className="PhoneSimilarCurse__head__propertyBox ">
        <svg
          className="PhoneSimilarCurse__head__firstLine"
          viewPort="0 0 200 300"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line className="stroke" x1="20" y1="30" x2="650" y2="30"></line>
        </svg>
        <p className="PhoneSimilarCurse__head__propertyTitle">دوره های مشابه</p>
        <svg
          className="PhoneSimilarCurse__head__SecLine"
          viewPort="0 0 200 300"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line className="stroke" x1="10" y1="30" x2="650" y2="30"></line>
        </svg>
      </div>

      {Similar.response?.data && (
        <div className="PhoneSimilarCurse__box">
          {information.map((c) => (
            <CourseDiv course={c} />
          ))}
        </div>
      )}
    </div>
  );
}

export default PhoneSimilarCurse;

import React, { useState, useRef, useEffect } from "react";
import { Player } from "video-react";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../constacts";
import "video-react/dist/video-react.css";
import { useAuth } from "../../context/AuthContext";

/**
 * return Get getcourseContentList of one season
 * @param   {integer} id  id of content
 *
 * @return  {object} 
 * {
  "id": 1,
  "short_description":""
  "video": "source of video"
   }

 */

const DarsAcoordionDetail = (props) => {
  const { detail } = props;

  return (
    <>
      {detail !== null ? (
        <div>
          <div className="SarfaslContent__contentTxt">
            <p style={{ textAlign: "justify", direction: "rtl" }}>
              {detail.short_description}
            </p>
          </div>
          <div className="SarfaslPlayerBox">
            {detail.video ? (
              <>
                <Player
                  className="CorseIntro__video"
                  playsInline
                  fluid={false}
                  width="100%"
                  height={400}
                  src={detail.video}
                  
                ></Player>

                <div className="CorseIntro__video-Title">
                  <p>{props.darstitle} </p>
                  <span> </span>
                  <p className="befor-arrow"> {props.seasontitle}</p>
                  <span> </span>
                  <p className="befor-arrow"> {props.Coursetitle}</p>
                </div>
              </>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DarsAcoordionDetail;

import React, { useEffect, useState } from "react";
import image from "../../img/mycourse/download.png";
import star from "../../img/mycourse/Polygon 2.svg";
import users from "../../img/mycourse/user.svg";
import time from "../../img/mycourse/زمان.svg";
import { useHistory } from "react-router-dom";
import Circle from "react-circle";
import { BtnPrimary } from "../share/Btn";
import useAxios from "@use-hooks/axios";
import { API_URL } from "../../constacts";
import { useAuth } from "../../context/AuthContext";
import { useUser } from "../../context/UserContext";
import { useList } from "../../context/SeasonList";
import QuizeModal from "./QuizeModal";
import Cookies from "js-cookie";
import { useRecoilState } from "recoil";
import { textState, textState1, textState2 } from "../../Recoil/RecoilState";
import Loader from "../Common/Loader";

const MyCourseNowCard = ({ doingcourseItems, openQuizeModal, setLoad }) => {
  const [contentid, setContentid] = useRecoilState(textState);
  const [idd, setIdd] = useState("");
  const [listdis, setListdis] = useState(false);
  const [loader, setLoader] = useState(false);
  const { token } = useAuth();
  const { userDispatch, testtt } = useUser();
  // const { listDispatch, list } = useList();

  useEffect(() => {}, []);
  useEffect(() => {
    if (idd !== "") {
      setContentid(idd);
    }
  }, [idd]);
  let history = useHistory();
  const [contenttitle, setContenttitle] = useRecoilState(textState2);
  const checkUserPassSeason = useAxios({
    url: `${API_URL}/content/what_is_latest_content_id_user_passed/${doingcourseItems.course_id}/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        // console.log(res.data);
        userDispatch({
          type: "REFRESH",
          testtt: res.data.latest_content_id,
        });
        setContenttitle(res.data.latest_content_uniq_id);
        setIdd(res.data.latest_content_id);
        // } else {
        //   userDispatch({
        //     type: "REFRESH",
        //     testtt: res.data.data[0].contents[0].id,
        //   });
        //   setIdd(res.data.data[0].contents[0].id);
        // }
        setListdis(true);
        // contentIdCourse1.reFetch();
        contentIdCourse1.reFetch();
      }
      if (err) {
        setListdis(false);
        contentIdCourse.reFetch();
      }
    },
  });
  const contentIdCourse = useAxios({
    url: `${API_URL}/sidebar/${doingcourseItems.course_id}/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        setIdd(res.data.seasons[0].contents[0].id);

        userDispatch({
          type: "REFRESH",
          testtt: res.data.seasons[0].contents[0].id,
        });
        setContenttitle(res.data.seasons[0].contents[0].unique_id);

        history.push({
          pathname: `/courcontent`,
          state: {
            id: doingcourseItems.course_id,
          },
        });
      }
      if (err) {
        console.log(err);
      }
    },
  });
  const contentIdCourse1 = useAxios({
    url: `${API_URL}/sidebar/${doingcourseItems.course_id}/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        history.push({
          pathname: `/courcontent`,
          state: {
            id: doingcourseItems.course_id,
         
          },
        });
      }
      if (err) {
        console.log(err);
      }
    },
  });

  const handleClick = () => {
    setLoad(true);
    checkUserPassSeason.reFetch();
  };
  return (
    <div className="MyCourseNowCard mycourse-defaultbox">
      <img
        src={doingcourseItems.cover}
        alt={doingcourseItems.cover}
        className="MyCourseNowCard--img"
      />
      <div className="MyCourseNowCard__main">
        <div className="MyCourseNowCard__header">
          <div
            onClick={handleClick}
            style={{ color: "#000", cursor: "pointer" }}
          >
            <h3>{doingcourseItems.title} </h3>
          </div>
          <div className="lastactivity__Circle">
            <Circle
              progress={doingcourseItems.progresses}
              responsive={false}
              size={40}
              progressColor="#fe7f2d"
              bgColor="#eaeaea"
              textColor="#323232"
              textStyle={{
                fontSize: "12rem",
                fontWeight: "bold",
              }}
              lineWidth={35}
              animate={true}
            />
          </div>
        </div>
        <div className="d-flex MyCourseNowCard__row">
          <div className="d-flex ">
            <p className="MyCourseNowCard__point--P">
              {doingcourseItems.num_of_participants}
            </p>
            <img
              src={users}
              className="MyCourseNowCard__point--img"
              alt={users}
            />
          </div>

          <div className="d-flex MyCourseNowCard__row-box">
            <p className="MyCourseNowCard__point--P">
              {doingcourseItems.mean_of_participant_points}
            </p>
            <img src={star} className="MyCourseNowCard__point--img" />
          </div>

          <div className="MyCourseNowCard__text d-flex ">
            <p className="MyCourseNowCard__text--orange">
              {doingcourseItems.level}
            </p>
            :<p>سطح دوره</p>
          </div>
        </div>
        <div className="MyCourseNowCard__text d-flex">
          <p className="MyCourseNowCard__text--orange">
            {doingcourseItems.teacher_first_name}
            {doingcourseItems.teacher_last_name}
          </p>
          :<p>مدرس دوره</p>
        </div>
        <div className="d-flex MyCourseNowCard__point">
          <p className="MyCourseNowCard__point--P">
            {doingcourseItems.total_time_of_course}
          </p>
          <img src={time} className="MyCourseNowCard__point--img" />
        </div>
        <div className="MyCourseNowCard__footer">
          <BtnPrimary name="آزمون های گذرانده شده" onClick={openQuizeModal} />
        </div>
      </div>
    </div>
  );
};

export default MyCourseNowCard;

import React, { useContext, useState, useEffect } from "react";
import "../../css/main.scss";
import CardCourse from "./CardCourse";
import { CardCourses } from "../../context/CardCourseContext";
import { useAuth } from "../../context/AuthContext";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CardCourseList = (props) => {
  const { cardCourse } = useContext(CardCourses);
  const [info, setInfo] = useState(0);
  const [valdeg, setValdeg] = useState(0);
  const [valcor, setValcor] = useState(0);
  const [final, setFinal] = useState(false);
  const { token } = useAuth();
  useEffect(() => {
    summeryy.reFetch();
  }, [, props.final, props.final1]);

  const summeryy = useAxios({
    url: `${API_URL}/cart/order-summary/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("data", res.data);
        setInfo(res.data.results);
        props.setList(res.data.results.length);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: green;
  `;

  return (
    <>
      {summeryy.response?.data ? (
        <>
          <div className="Card__CourseList Card-box">
            <ul className="card__List list">
              <li className="card__List--item">نوع مدرک</li>
              <li className="card__List--item">قیمت نهایی</li>
              <li className="card__List--item">تخفیف</li>
              <li className="card__List--item">قیمت اصلی</li>
              <li className="card__List--item">مدرس</li>
              <li className="card__List--item">نام دوره</li>
            </ul>
            {info.length !== 0 ? (
              info.map((item, id) => (
                <CardCourse
                  title={item.course_name}
                  price={item.teacher_name}
                  lname={item.teacher_last_name}
                  pic={item.teacher_avatar}
                  id={item.course_id}
                  setValdeg={setValdeg}
                  setValcor={setValcor}
                  setFinal={props.setFinal}
                  setFinal1={props.setFinal1}
                  degree={item.degree_name}
                  degrees={item.all_degrees}
                  final={props.final}
                  final1={props.final1}
                  deg={item.id}
                  costamount={item.cost_amount}
                  calculateordertemcoupon={item.calculate_order_item_coupon}
                />
              ))
            ) : (
              <div className="center">
                <p className="CardCourseList__empytList">
                  هیچ دوره ای انتخاب نشده است
                </p>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="center" style={{ height: "100vh" }}>
          <ClipLoader color="#ffffff" loading={true} css={override} size={40} />
        </div>
      )}
    </>
  );
};

export default CardCourseList;

import React, { useEffect, useState } from "react";
import CourseDiv from "../share/CourseDiv";
import { coursese } from "../../data/courses";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import "../../css/main.scss";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

function SimilarCourse(props) {
  const [id, setId] = useState("");
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);
  const [information, setInformation] = useState("");
  useEffect(() => {
    Similar.reFetch();
  }, []);
  const { token } = useAuth();

  const Similar = useAxios({
    url: `${API_URL}/courses/similar/${id}/`,
    method: "GET",
    options: {
      headers: token
      ? {
          Authorization: `JWT ${token}`,
        }
      : {},
    },
    customHandler: (err, res) => {
      if (res) {
        setInformation(res.data);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  return (
    <div className="PhoneSimilarCurse">
      {information.length !== 0 ? (
        <div className="corseInfo__head__propertyBox ">
          <svg
            width="41%"
            height="50"
            viewPort="0 0 200 300"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line className="stroke" x1="40" y1="30" x2="520" y2="30"></line>
          </svg>
          <p className="corseInfo__head__propertyTitle">دوره های مشابه</p>
          <svg
            width="41%"
            height="50"
            viewPort="0 0 200 300"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line className="stroke" x1="5" y1="30" x2="820" y2="30"></line>
          </svg>
        </div>
      ) : null}

      {Similar.response?.data && (
        <div className="SimilarCourse">
          {information.map((c) => (
            <CourseDiv course={c} />
          ))}
        </div>
      )}
    </div>
  );
}

export default SimilarCourse;

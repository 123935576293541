/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import javalogo from "../../img/courseinfo/JavaScript-logo.png";
import { Properties } from "../Common/Properties";
import { NavLink,Link } from "react-router-dom";
import "../../css/main.scss";


function CourseHead({title,properties,picture}) {

  return (
    <div className="corseInfo__headBox">
      <div className="corseInfo__head__navBox">
        <div className="corseInfo__headRed-dot"> </div>
        <ul className="corseInfo__headTitleBox">
          <li className="corseInfo__headTitle">
            <Link to='/'><p className="corseInfo__headTitlTxt">خانه</p></Link>
          </li>
          <li className="corseInfo__headTitle">
          <Link to='/CourseList'>  <p className="corseInfo__headTitlTxt">آموزش</p></Link>
          </li>
          <li className="corseInfo__headTitle">
            <p className="corseInfo__headTitlTxt">
              {title}
            </p>
          </li>
        </ul>
      </div>
      <div className="corseInfo__head__contentBox">
       
          <img
            src={picture}

            className="corseInfo__javaico"
          />
        
      
          <div className="corseInfo__head__titleBox" >
            <h2 className="corseInfo__head__title">دوره آموزشی</h2>
            <h1 className='corseInfo__head__titre'>
            {title}
            </h1>
              
            
          </div>
 
      </div>
      <div className="corseInfo__head__propertyBox ">
        <svg
          className="corseInfo__head__firstLine"
          viewPort="0 0 200 300"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line className="stroke" x1="40" y1="30" x2="650" y2="30"></line>
        </svg>
        <p className="corseInfo__head__propertyTitle">ویژگی های این دوره</p>
        <svg
          className="corseInfo__head__SecLine"
          viewPort="0 0 200 300"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line className="stroke" x1="10" y1="30" x2="650" y2="30"></line>
        </svg>
      </div>
      <div className="corseInfo__head__propertyContent-box">
        <ul className="BoxGrid">
          {properties.map((text) => (
            <Properties key={text.id} text={text.prop} className="svgblack" />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default CourseHead;

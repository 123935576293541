import shoppinglist from "../../img/courselist/Add to package.svg";
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DefaultBox from "../Common/DefaultBox";
import { BtnSecondarybig } from "../share/Btn";
import ADDTOPackage from "../../img/Add to package.png";
import user from "../../img/svg/vectorpaint.svg";
import { CardCourses } from "../../context/CardCourseContext";
import { GetUserInfoContext } from "../../context/GetUserInfoContext";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../context/AuthContext";
import "../../css/main.scss";
import useFetch from "../../components/hooks/useFetch";

const CourseDiv = (props) => {
  const {
    addToCard,
    setalredyaddedDialog1,
    setalredyaddedDialog,
    handlerefresh1,
    setAddToCardDliaog,
    cardCourse,
    setCardCourse,

    summary,
  } = useContext(CardCourses);
  const [loading, setLoading] = useState(false);
  const [coast, setCoast] = useState(0);
  const [coast1, setCoast1] = useState(0);
  const [coast2, setCoast2] = useState(0);
  const { summery } = useContext(GetUserInfoContext);
  const { token } = useAuth();
  const {
    title,
    cover,
    mean_of_participant_points,
    level,
    total_time_of_course,
    num_of_participants,
    get_price_without_degree_with_some_extra_info,
    id,
    has_user_course,
    is_favorite,
  } = props.course;

  const [course, setCourse] = useState(false);
  //const [fave, setFave] = useState(isFavorite);
  const [isFavorite1, setIsFavorite1] = useState({ isFavorite: is_favorite });

  const addtowishlistrefetch = useFetch({
    url: `willing/${id}/post`,
    method: "POST",
    shouldlogin: true,
  });
  const removewishlistrefetch = useFetch({
    url: `willing/${id}/delete`,
    method: "DELETE",
    shouldlogin: true,
  });
  useEffect(() => {}, []);
  const autherror = () => {
    toast.error("برای خرید محصول ابتدا وارد سایت شوید", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const allreadyerror = () => {
    toast.error("دوره قبلا ب سبد خرید اضافه شده است", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;

  const cardadd = useAxios({
    url: `${API_URL}/cart/add-to-cart-without-degree/${id}/`,
    method: "POST",
    options: {
      // data: `${id}`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        setLoading(false);
        // handlerefresh1();
        //addToCard(props.course, id);
        summary.reFetch();
        console.log("cardCourse", cardCourse);
        setAddToCardDliaog(true);
        summery.reFetch();
        setTimeout(() => {
          setAddToCardDliaog(false);
        }, 10000);
      }

      if (err) {
        setLoading(false);
        if (err.response.status === 403) {
          autherror();
        } else if (err.response.data.data === "course already exists") {
          // allreadyerror();
          // handlerefresh1();
          addToCard(props.course, id);
          setAddToCardDliaog(true);
          setalredyaddedDialog(true);
          setTimeout(() => {
            setAddToCardDliaog(false);
            setalredyaddedDialog(false);
          }, 10000);

          console.log(err.response.data.data);
        } else {
          console.log(err.response);
        }
      }
    },
  });

  const handleClick = () => {
    setLoading(true);
    cardadd.reFetch();
    // addToCard(props.course, id);
  };

  const addtowishlist = () => {
    setIsFavorite1((prevState) => {
      return { isFavorite: !prevState.isFavorite };
    });

    addtowishlistrefetch.reFetch();
  };
  const deletewishlist = () => {
    setIsFavorite1((prevState) => {
      return { isFavorite: !prevState.isFavorite };
    });

    removewishlistrefetch.reFetch();
  };
  return (
    <DefaultBox className="course-card">
      <div className="course-card__picture">
        <img src={cover} alt="nodejs" />
      </div>
      <div className="course-card__content">
        <div className="course-card__title">
          <Link
            className="h-4"
            to={{
              pathname: `/courseinfo`,
              state: { id: id },
            }}
          >
            {title}
          </Link>
          {token !== null && (
            <div>
              {!props.willing ? (
                <div className="bookmark">
                  {!isFavorite1.isFavorite ? (
                    <i className="far fa-bookmark " onClick={addtowishlist}></i>
                  ) : (
                    <i className="fas fa-bookmark" onClick={deletewishlist}></i>
                  )}
                </div>
              ) : (
                <div className="bookmark">
                  <i className="fas fa-bookmark" onClick={deletewishlist}></i>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="level">
          <span>: سختی دوره</span>
          <span>{level}</span>
        </div>
        <div className="course-card__details">
          <div className="rateBox">
            <div className="rate">
              <span>
                <i className="fas fa-star"></i>
              </span>
              {mean_of_participant_points.grade}
            </div>
            <div className="number">
              <span>
                <img src={user} alt="user" />
              </span>
              {num_of_participants}
            </div>
          </div>
        </div>
        {get_price_without_degree_with_some_extra_info !== null ? (
          <>
            {get_price_without_degree_with_some_extra_info[1] !== null ? (
              <div className="course-card__details">
                {get_price_without_degree_with_some_extra_info[1] !== null ? (
                  <div
                    className={
                      get_price_without_degree_with_some_extra_info[1] !== null
                        ? "timeBox1"
                        : "timeBox"
                    }
                  >
                    <div className="time">
                      <span>
                        <i className="far fa-clock"></i>
                      </span>
                      {total_time_of_course}
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
            {get_price_without_degree_with_some_extra_info[2] !== null && (
              <div className="price__box">
                <div className="">
                  {get_price_without_degree_with_some_extra_info[1] !== null &&
                  get_price_without_degree_with_some_extra_info[1] !== 0 ? (
                    <div className="price-Off">
                      <button>
                        {get_price_without_degree_with_some_extra_info[1]}%
                      </button>
                    </div>
                  ) : null}
                  {get_price_without_degree_with_some_extra_info[0] !==
                    null && (
                    <p
                      className="real__cost"
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                      {get_price_without_degree_with_some_extra_info[1] !== 0
                        ? get_price_without_degree_with_some_extra_info[0]
                        : null}
                    </p>
                  )}
                </div>

                <div className="price-box">
                  <div className="price">
                    <div className="PriceSection__PriceBox">
                      <span>تومان</span>
                      {get_price_without_degree_with_some_extra_info[2].toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : null}

        {has_user_course ? (
          <div className="center">
            {" "}
            <p style={{ color: "#fe7f2d", fontSize: "14px" }}>
              این دوره قبلا خریداری شده است
            </p>
          </div>
        ) : (
          <div className="shop" onClick={handleClick}>
            <BtnSecondarybig className="PriceSection__Button">
              {loading ? (
                <ClipLoader
                  color="#ffffff"
                  loading={loading}
                  css={override}
                  size={15}
                />
              ) : (
                <>
                  <img src={ADDTOPackage} alt="ADDTOPackage" />
                  <p>افزودن به سبد خرید</p>
                </>
              )}
            </BtnSecondarybig>
          </div>
        )}
      </div>
      <ToastContainer />
    </DefaultBox>
  );
};
export default CourseDiv;

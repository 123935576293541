import React, {useState, useEffect} from 'react';
import CourseInfoAccordion from './CourseInfoAccordion';
import '../../css/main.scss';
import useAxios from '@use-hooks/axios';
import {API_URL} from '../../constacts';
import {useAuth} from '../../context/AuthContext';

/**
 * return Get getSeasonList of one course
 * @param   {integer} id  id of course
 *
 * @return  {object} 
 * {
 *   id": integer,
      "title": "",
      "short_description": "",
      "lockedOn": boolean,
      "get_nums_of_contents_for_each_season": integer,
      "total_time_for_each_season": "00:00:00",
      "contents": [
        id of contents
      ]
 * }

 */

function SarfaslContent(props) {
  // const [SarfaslContentInfo, setSarfaslContentInfo] = useState(null);
  const {token} = useAuth();

  // const getSeason = useAxios({
  //   url: `${API_URL}/course_season/${props.id}/all/`,
  //   method: "GET",
  //   options: {

  //     headers: token ? {
  //       Authorization: `JWT ${token}`,
  //     } : {},
  //   },
  //   customHandler: (err, res) => {
  //     if (res) {
  //       console.log("resseason", res.data.data);
  //       setSarfaslContentInfo(res.data.data);
  //     }
  //     if (err) {
  //       console.log(err.response);
  //     }
  //   },
  // });

  // useEffect(() => {
  //   getSeason.reFetch();
  //   console.log("SarfaslContentInfo", SarfaslContentInfo);
  // }, []);
  return (
    <div className="SarfaslContent">
      <div className="corseInfo__head__propertyBox ">
        <svg
          className="SarfaslContent__headFirstLine"
          viewPort="0 0 200 300"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line className="stroke" x1="40" y1="30" x2="620" y2="30"></line>
        </svg>
        <p className="corseInfo__head__propertyTitle">مشاهده سرفصل ها</p>
        <svg
          className="SarfaslContent__headSecLine"
          viewPort="0 0 200 300"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line className="stroke" x1="10" y1="30" x2="620" y2="30"></line>
        </svg>
      </div>

      {props.SarfaslContentInfo !== null
        ? props.SarfaslContentInfo.map((i) => (
            <>
              <CourseInfoAccordion Coursetitle={props.Coursetitle} info={i} />
            </>
          ))
        : null}
      <div className="SarfaslContent__contentTxt-down"></div>
    </div>
  );
}

export default SarfaslContent;

import React, { useState } from "react";
import password from "../../img/signlogform/Icon ionic-ios-lock.svg";
import "../../css/main.scss";
import { useForm } from "react-hook-form";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import ShowHidePass from "../hooks/ShowHidePass";
import { useHistory } from "react-router";
import logo from '../../img/logowhite/logo web.svg'
import { Link } from "react-router-dom";

/**
 * return password
 * @param   {integer} password1  
 * @param   {integer} password2
 * @param   {integer} uid  
 * @param   {integer} token  
  
 *
 * @return  reset password
 * 
 */ 
const ResetPassworld = (props) => {
  const [signininfo, setSigninfo] = useState();
  let [loading, setLoading] = useState(false);

  const commonpasswordNotify = () => {
    toast.error("پسوورد ساده است", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const DiffrentpassNotify = () => {
    toast.error("پسوورد  شبیه نیست", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const Succesfullmassage = () => {
    toast.success("پسوورد با موفقیت تغییر کرد", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const emailsimilar = () => {
    toast.error("پسوورد و ایمیل شبیه هستند", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const emailnumber = () => {
    toast.error("پسورد فقط عدد میباشد .لطفا ترکیب حروف و عدد وارد کنید", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const usersimilar = () => {
    toast.error("پسوورد و نام کاربری شبیه هستند ", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const passwordcommon = () => {
    toast.error("پسورد معمولی است", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const history = useHistory()

  const resetPassword = useAxios({
    url: `${API_URL}/password/reset/confirm/${props.uid}/${props.token}/`,
    method: "POST",
    options: {
      data: signininfo,
      headers: {},
    },
    // trigger: resources,
    customHandler: (err, res) => {
      if (res) {
        setLoading(false);
        Succesfullmassage();
        console.log("res", res);
        history.push("/")
      }
      if (err) {
        setLoading(false);

        console.log(err.response);
        if (err.response.data.new_password2.includes("The password is too similar to the email address.")) {
            emailsimilar();
        }
        if (err.response.data.new_password2.includes("This password is too short. It must contain at least 8 characters.")) {
            commonpasswordNotify();
        }
        if (err.response.data.new_password2.includes("The password is too similar to the username.")) {
            usersimilar();
        }
        if (err.response.data.new_password2.includes("This password is entirely numeric.")) {
          emailnumber();
      }
      if (err.response.data.new_password2.includes("This password is too common.")) {

        passwordcommon();
    }
      }
    },
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const onSubmit = (info) => {
    setSigninfo({ ...info, uid: props.uid, token: props.token });
    if (info.new_password1 !== info.new_password2) {
      DiffrentpassNotify();
    } else {
      setLoading(true);

      resetPassword.reFetch();
    }

    //authDispatch({type: 'LOGIN', token: 123456});
    // history.push('/');
  };
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  const [passwordShown, togglePasswordVisiblity] = ShowHidePass(false);

  return (
   <div >
    
      <div className='ResetPassworld-container '>
      
      <form onSubmit={handleSubmit(onSubmit)} className='ResetPassworld-form'>
     <Link className='ResetPassworld--logo' to='/'> <img src={logo} /></Link>
        <div className="ResetPassworld-div ">
          <input
            type={passwordShown ? "text" : "password"}
            name="new_password1"
            id="popupform__right-login-password "
            readOnly={loading ? true : false}
            required
            {...register("new_password1", { required: true })}
            class="required ResetPassworld-input"
          />
          <label
            for="popupform__right-login-password"
            className=" ResetPassworld-label "
          >
            <span>:رمز عبور</span>
          </label>
          <div className="formInput_eyeBox">
            <i className="fas fa-eye" onClick={togglePasswordVisiblity}></i>
            <img
              className="popupform__right-login-img"
              src={password}
              alt="password"
            />
          </div>
        </div>
        <div className="ResetPassworld-div ">
          <input
            type={passwordShown ? "text" : "password"}
            name="new_password2"
            id="popupform__right-login-password-2"
            readOnly={loading ? true : false}
            required
            {...register("new_password2", { required: true })}
            class="required ResetPassworld-input"
          />
          <label
            for="popupform__right-login-password-2"
            className="ResetPassworld-label"
          >
            <span> :تکرار رمز عبور</span>
            <div className="formInput_eyeBox">
              <i className="fas fa-eye" onClick={togglePasswordVisiblity}></i>
              <img
                className="popupform__right-login-img"
                src={password}
                alt="password"
              />
            </div>
          </label>
        </div>

        <button type="submit" className="BtnSecondary">
          {loading ? (
            <ClipLoader
              color="#ffffff"
              loading={loading}
              css={override}
              size={15}
            />
          ) : (
            " تغییر رمز "
          )}
        </button>
      </form>
      <ToastContainer />
    </div>
   </div>
  );
};

export default ResetPassworld;
//

//This password is too common.
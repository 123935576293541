import React, {createContext, useReducer} from 'react';
import {userReducer} from '../reducers/UserReducer';

const UserContext = createContext();

const UserProvider = (props) => {
  const [testtt, userDispatch] = useReducer(
    userReducer,
    localStorage.getItem('testtt')
  );


  return (
    <UserContext.Provider value={{testtt, userDispatch}} {...props}>
      {props.children}
    </UserContext.Provider>
  );
};

const useUser = () => React.useContext(UserContext);

export {UserProvider, useUser};

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "../../css/main.scss";
import emailIcon from "../../img/signlogform/Icon material-email.svg";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
/**
 * return send password resetlink to usersemail
 * @param   {integer} email  
  
 *
 * @return  send password resetlink to usersemail
 * 
 */ 
const ForgetPass = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [signininfo, setSigninfo] = useState();
  let [loading, setLoading] = useState(false);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  const loginpage = useAxios({
    url: `${API_URL}/password/reset/`,
    method: "POST",
    options: {
      data: signininfo,
      headers: {},
    },
    // trigger: resources,
    customHandler: (err, res) => {
      if (res) {
        setLoading(false);
        success();
      }
      if (err) {
        console.log("error", err.response);
        setLoading(false);

      }
    },
  });
  const success = () => {
    toast.success("ایمیل با موفقیت ارسال شد", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const onSubmit = (info) => {
    setLoading(true);
    setSigninfo(info);
    loginpage.reFetch();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-input-div field">
        <input
          type="email"
          name="email"
          id="popupform__right-login-email"
          required
          class="required form-input field-input"
          {...register("email", { required: true })}
        />
        <label
          for="popupform__right-login-email"
          className="form-label  field-label"
        >
          <span>ایمیل</span>

          <img
            className="popupform__right-login-img"
            src={emailIcon}
            alt="user"
          />
        </label>
      </div>
      <p
        href="#"
        onClick={props.backToLogin}
        style={{ cursor: "pointer", marginRight: "40px" }}
      >
        برگشت به صفحه ی ورود{" "}
      </p>
      <button
        type="submit"
        className="BtnSecondary"
        style={{ marginTop: "40px" }}
      >
        {loading ? (
          <ClipLoader
            color="#ffffff"
            loading={loading}
            css={override}
            size={15}
          />
        ) : (
          "ورود"
        )}
      </button>
      <ToastContainer />
    </form>
  );
};

export default ForgetPass;

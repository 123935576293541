import React, {useState} from 'react';
import PhoneAccordionSarfasl from '../../Common/PhoneAccordionSarfasl';
import '../../../css/main.scss';

function PhoneSarfasl(props) {
  // const [PhoneDescriptionInfo, setPhoneDescriptionInfo] = useState([
  //    props.SarfaslContentInfo,
  // ]);
  return (
    <div className="PhoneSarfasl">
      {props.SarfaslContentInfo && (
        <PhoneAccordionSarfasl
          title="مشاهده ی سرفصل ها"
          content={props.SarfaslContentInfo}
          sarfaslform={true}
        />
      )}
    </div>
  );
}

export default PhoneSarfasl;

const PhoneDescriptionInfo = [
  {
    titr: 'لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به  عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری',
  },
  {
    titr: 'لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به  عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری',
  },
  {
    titr: 'لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به  عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری',
  },
];

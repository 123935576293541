import React from 'react';
import '../../css/main.scss';

function BtnPrimary(props) {
  return (
    <button className={`BtnPrimary ${props.className}`} onClick={props.onClick}>{props.name}</button>
  );
}

export {BtnPrimary};

function BtnSecondary(props) {
  return (
    <button
      className={`BtnSecondary ${props.className}`}
      type="submit"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

export {BtnSecondary};
function BtnSecondarybig(props) {
  return (
    <button className={`BtnSecondarybig ${props.className}`} type="submit">
      {props.children}
    </button>
  );
}

export {BtnSecondarybig};

function BtnSubmitWhite(props) {
  return (
    <button
      className={`BtnSubmitWhite ${props.className}`}
      type="submit"
      value="Submit"
    >
      {props.children}
    </button>
  );
}
export {BtnSubmitWhite};

function InputSubmitSecondary(props) {
  return (
    <button className="BtnSecondary disable" type="submit" value="Submit">
      {props.children}
    </button>
  );
}

export {InputSubmitSecondary};

function BtnDynamicGreen(props) {
  return (
    <div>
      <button className="BtnDynamicGreen">{props.children}</button>
    </div>
  );
}

export {BtnDynamicGreen};

function BtnTransparent(props) {
  return (
    <div>
      <button
        onClick={props.onClick}
        className={`BtnTransparent ${props.className}`}
      >
        {props.children}
      </button>
    </div>
  );
}

export {BtnTransparent};
function BtnOrangeTransparent(props) {
  return (
    <div>
      <button
        onClick={props.onClick}
        className={`BtnOrangeTransparent ${props.className}`}
      >
        {props.children}
      </button>
    </div>
  );
}

export {BtnOrangeTransparent};

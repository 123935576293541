import React from "react";
import { Link } from "react-router-dom";

const QuizeModal = ({
  lesson_quizzes,
  season_quizzes,
  CloseModalQuize,
  course_quizzes,
  id,
}) => {
  return (
    <div className="CourseSeasonModal__background">
      <div className="CourseSeasonModal__container">
        <div className="CourseSeasonModal__close" onClick={CloseModalQuize}>
          <i class="fas fa-times"></i>
        </div>
        <div className="QuizeModal__row">
          {lesson_quizzes.length !== 0 ? (
            <div>
              <p>آزمون های فصل:</p>

              {lesson_quizzes.map((i, index) => (
                <div className="QuizeModal__box">
                  <Link
                    to={{
                      pathname: `/quiz`,
                      state: { id: id, quizid: i, code: false },
                    }}
                  >
                    <p>آزمون{index + 1}</p>
                  </Link>
                </div>
              ))}
            </div>
          ) : null}
          {season_quizzes.length !== 0 ? (
            <div>
              <p>آزمون های فصل:</p>

              {season_quizzes.map((i, index) => (
                <div className="QuizeModal__box">
                  <Link
                    to={{
                      pathname: `/quiz`,
                      state: { id: id, quizid: i, code: false },
                    }}
                  >
                    <p>آزمون{index + 1}</p>
                  </Link>
                </div>
              ))}
            </div>
          ) : null}
          {course_quizzes.length !== 0 ? (
            <div>
              <p>آزمون های فصل:</p>

              {course_quizzes.map((i, index) => (
                <div className="QuizeModal__box">
                  <Link
                    to={{
                      pathname: `/quiz`,
                      state: { id: id, quizid: i, code: false },
                    }}
                  >
                    <p>آزمون{index + 1}</p>
                  </Link>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default QuizeModal;

// lesson_quizzes.length === 0 && season_quizzes.length === 0
// && course_quizzes.length === 0 ?<div className='center'>
// <p>آزمونی پاس نشده</p>
// </div>
// :

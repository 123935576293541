import React, { useContext, useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/main.scss";
import "../../css/main.scss";
import { CardCourses } from "../../context/CardCourseContext";

const CardfinalCostBox = (props) => {
  const { cardCourse } = useContext(CardCourses);
  const { token } = useAuth();
  const [name, setName] = useState("");
  useEffect(() => {
    finalprice.reFetch();
  }, [, props.final, props.final1]);
  let total = 0;
  const totalcost = () => {
    for (let i = 0; i < cardCourse.length; i++) {
      total += cardCourse[i].price;
    }
    return total;
  };
  const tips = 50000;
  let totalpay = totalcost();
  const shouldpay = totalpay - tips;
  const finalprice = useAxios({
    url: `${API_URL}/payment/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        setName(res.data);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  return (
    <>
      {!props.reload ? (
        <>
          {finalprice.response?.data && (
            <div className="Card-box">
              <div className="Card-box-title">کل هزینه ی قابل پرداخت</div>
              <div className="final-cost-table">
                <span className="final-cost-price">{`${name.final_amount}تومان`}</span>
                <p>:مبلغ کل</p>
                <span className="final-cost-tips">
                  {name.final_amount_by_added_coupon[1]}تومان
                </span>
                <p className="final-cost-tips">تخفیف:</p>
              </div>
              <div className="final-cost-pay">
                <p className="final-cost-pay-price">
                  <span>{name.final_amount_by_added_coupon[0]}</span>
                  <span>تومان</span>
                </p>
                <p> :مبلغ نهایی</p>
              </div>
            </div>
          )}{" "}
        </>
      ) : (
        <div className="Card-box">
          <div className="Card-box-title">کل هزینه ی قابل پرداخت</div>
          <div className="final-cost-table">
            <span className="final-cost-price">{`0تومان`}</span>
            <p>:مبلغ کل</p>
            <span className="final-cost-tips">{0}تومان</span>
            <p className="final-cost-tips">تخفیف:</p>
          </div>
          <div className="final-cost-pay">
            <p className="final-cost-pay-price">
              <span>{0}</span>
              <span>تومان</span>
            </p>
            <p>:مبلغ نهایی</p>
          </div>
        </div>
      )}
    </>
  );
};

export default CardfinalCostBox;

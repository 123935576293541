import React, {useState, useEffect} from 'react';
import {Player} from 'video-react';
import axios from 'axios';
import UseWindowSize from '../../../Sizes/UseWindowSize';
import '../../../css/main.scss';

const api__test =
  'https://medium.com/@mjberman024/embedding-a-code-editor-in-your-website-using-react-ace-42e64a58507';

function PhoneCorseIntro(props) {
  return (
  <div>
      <div className="PhoneCorseIntroPlayerBox">
      <Player
        className="PhoneCorseIntro__video"
        playsInline
        fluid={false}
        width="100%"
        height={400}
        src={props.video}
      ></Player>

    </div>
    {/* */}
  </div>
  );
}

export default PhoneCorseIntro;

const PhoneCorseIntroInfo = [
  {
    txt:
      ' لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت و ظاهر متن باشد.    ',
  },
  {
    txt:
      ' لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت و ظاهر متن باشد.    ',
  },
  {
    txt:
      ' لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت و ظاهر متن باشد.    ',
  },
  {
    txt:
      ' لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه‌آرایی و طراحی گرافیک گفته می‌شود. طراح گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته شده استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت و ظاهر متن باشد.    ',
  },
];

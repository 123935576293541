import React from "react";
import checkmark from "../../img/courseinfo/check-mark.png";
import { TeacherProperties } from "../Common/Properties";
import "../../css/main.scss";

const PishNiazItem = (props) => {
  return (
    <div className={props.className}>
      {props.sub.length === 0 ? (
        <TeacherProperties className="svgblack " margStyle="PishNiazItem">
          {props.first}
        </TeacherProperties>
      ) : (
        <div className="properties__Subtxt">
          <TeacherProperties
            className="svgblack "
            margStyle="PishNiazItem__sub"
          >
            {props.first}
          </TeacherProperties>
          <ul className="PishNiazItem__Sub-List">
            {props.sub.map((content) => (
              <li style={{ marginBottom: "1.4rem" }}>{content.sub_pre_prop}</li>
            ))}
          </ul>
          {/* <p style={{ marginBottom: "1.4rem", listStyle: "square" }}>
            
          </p> */}
        </div>
      )}
      {/* <TeacherProperties
        className= "svgblack" 
      >
        {props.first}
      </TeacherProperties> */}
    </div>
  );
};

export default PishNiazItem;

import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

function ContentExample(props) {
  const [id, setId] = useState("");
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);
  return (
    <div>
      {props.example !== null ? (
        <NavLink
          exact
          to={{
            pathname: `/example`,
            state: { id: id, exmpid: props.example },
          }}
        >
          <div className="ContentBody__Example">
            <div className="d-flex">
              <i className="fas fa-link"></i>

              <p>https://codeamooz.com/course_content/{props.example}</p>
            </div>
            <p style={{ marginRight: "1rem", fontSize: "1.2rem" }}>
              {props.examplename}
            </p>
          </div>
        </NavLink>
      ) : null}
    </div>
  );
}

export default ContentExample;

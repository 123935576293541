import React, { useState, useEffect } from "react";
import DefaultBox from "../../Common/DefaultBox";
import PriceSection from "./PriceSection";
import CourseInfoTableContent from "./CourseInfoTableContent";
import { API_URL } from "../../../constacts";
import useAxios from "@use-hooks/axios";
import "../../../css/main.scss";

function InfoSideBar(props) {
  const [degree, setDegree] = useState(0);
  return (
    <div>
      <DefaultBox className="InfoSideBarPriseBox">
        <table className="InfoSideBarPriseTable">
          <thead>
            <tr>
              <th className="priceHeader__line">
                <p> هزینه و اطلاعات دوره</p>
              </th>
            </tr>
          </thead>

          <tbody>
            <CourseInfoTableContent id={props.id} setLoader={props.setLoader} />
            {/* <PriceSection setDegree={setDegree} degree={degree} /> */}
          </tbody>
        </table>
      </DefaultBox>
    </div>
  );
}

export default InfoSideBar;

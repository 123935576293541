import React from "react";
import "../../css/main.scss";
import LastnewsItem from "./LastnewsItem";
import { BtnSecondary, BtnSecondarybig } from "../share/Btn";
import PinkbackSection from "../share/PinkbackSection";

import Circle from "../../img/svg/Circle.svg";

const LastnewsSec = () => {
  const newContent = [
    {
      title: "کد نویسی پازل",
      text: "در این مقاله با اصول کد نویسی یک پازل مجازی آشنا می شوید",
      id: 1,
    },
    {
      title: "فضای کاری جدید تیم کارآموز",
      text:
        "روز گذشته تیم کاراموز در فضای کاری جدید خود واقع در شهرک عملی تحقیقاتی اصفهان مستقر شد و دور هم بازی کردند",
      id: 2,
    },
    {
      title: "توپ را در سبد بگذار",
      text:
        "برای اینکه دچار دوباره کاری نشیم ،چکار کنیم؟در این مقاله یک سری دستورالعمل برای شماست",
      id: 3,
    },
    {
      title: "چطور فریلنسر شویم؟",
      text:
        "فریلنسر شدن اصول خود را دارد ،در این مقاله سعی میکنیم اصول اولیه فریلنسرینگ را",
      id: 4,
    },
  ];
  return (
    <section className="last-sect">
      <div className="container">
        <BtnSecondarybig className="latestHome-btn ">
          <p>برای مشاهده جدیدترین دوره های اموزشی کلیک کنید</p>
        </BtnSecondarybig>
        <div className="heading center" style={{ marginBottom: "4rem" }}>
          <h4 className="h-4">آخرین اخبار و مقالات کدآموز</h4>
        </div>
        <div className="last">
          {newContent.map((c) => (
            <LastnewsItem
              key={c.id}
              id={c.id}
              newsTitle={c.title}
              newsText={c.text}
            />
          ))}
        </div>
        <div className="center" style={{ margin: "30px 0"}}>
          <BtnSecondary>مشاهده همه ی مقالات</BtnSecondary>
        </div>
      </div>
      <PinkbackSection />
    </section>
  );
};
export default LastnewsSec;

import React, { useState, useEffect, useContext } from "react";
import NumberFormat from "react-number-format";
import FormHandleChange from "../../hooks/FormHandleChange";
import ADDTOPackage from "../../../img/Add to package.png";
import { BtnSecondarybig } from "../../share/Btn";
import { API_URL } from "../../../constacts";
import { CardCourses } from "../../../context/CardCourseContext";
import useAxios from "@use-hooks/axios";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../../context/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import "../../../css/main.scss";

function PriceSection(props) {
  const [loading, setLoading] = useState(false);
  const { addToCard, setalredyaddedDialog1 } = useContext(CardCourses);
  const [id, setId] = useState("");
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);
  const { token } = useAuth();
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  useEffect(() => {
    // hascourse.reFetch();
  }, []);
  var buttonStyle = {
    "&>*:hover": {
      background: "#efefef",
    },
  };
  const autherror = () => {
    toast.error("برای خرید محصول ابتدا وارد سایت شوید", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const cardadd = useAxios({
    url: `${API_URL}/cart/add-to-cart-with-degree/${id}/${props.degree}/`,
    method: "POST",
    options: {
      // data: `${id}`,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        setLoading(false);
        setalredyaddedDialog1(false);
        addToCard(props.information, id);
      }
      if (err) {
        setLoading(false);
        console.log(err.response);
        // addToCard(props.information, id);
        if (err.response.status === 403) {
          autherror();
        } else if (err.response.status === 409) {
          setalredyaddedDialog1(true);
          addToCard(props.information, id);
        } else {
          console.log(err.response);
        }
      }
    },
  });
  const handleClick = () => {
    setLoading(true);
    // cardadd.reFetch();
    // addToCard(props.course, id);
  };
  return (
    <>
      {" "}
      {/* <tr>
        <th className="PriceSection PriceSection__firstRow">
          <select
            onChange={handleChange}
            style={buttonStyle}
            defaultValue={props.degreeid}
          >
            {costs
              .filter(function (item) {
                return item.degree !== null;
              })
              .map((item, id) => (
                <option value={id}> {item.name}</option>
              ))}
          </select>
          <div>
            <p>{costs[props.degreeid].amount}</p>
            <button>{costs[props.degreeid].percentage_discount}%</button>
          </div>
        </th>
      </tr> */}
      {/* <tr className="PriceSection PriceSection__SecondRow">
        <th>
          <div className="PriceSection__PriceBox">
            <span>تومان</span>
            {costs[props.degreeid].discount_amount}
          </div>
        </th>
      </tr> */}
      <tr>
        <th className="PriceSection PriceSection__ThirdRow">
          <div className="shop" onClick={handleClick}>
            <BtnSecondarybig className="PriceSection__Button">
              {loading ? (
                <ClipLoader
                  color="#ffffff"
                  loading={loading}
                  css={override}
                  size={15}
                />
              ) : (
                <>
                  <img src={ADDTOPackage} alt="ADDTOPackage" />
                  <p>افزودن به سبد خرید</p>
                </>
              )}
            </BtnSecondarybig>
          </div>
        </th>
      </tr>{" "}
    </>
  );
}

export default PriceSection;

import React, { useEffect, useState } from "react";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import Rating from "@material-ui/lab/Rating";
import { useLocation } from "react-router-dom";
import ProgressBar from "../Common/ProgressBar";
import "../../css/main.scss";

function Comment(props) {
  const [id, setId] = useState("");
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
  }, [location]);
  return (
    <>
      <div>
        <div
          className="corseInfo__head__propertyBox "
          style={{ marginTop: "5.8rem" }}
        >
          <svg
            width="38%"
            height="50"
            viewPort="0 0 200 300"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line className="stroke" x1="40" y1="30" x2="620" y2="30"></line>
          </svg>
          <p className="corseInfo__head__propertyTitle">
            امتیاز دوره از دید کاربران
          </p>
          <svg
            width="38%"
            height="50"
            viewPort="0 0 200 300"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line className="stroke" x1="10" y1="30" x2="620" y2="30"></line>
          </svg>
        </div>
        <div className="Comment center">
          <div className="CommentRateBox">
            <div className="CommentRateBoxCol1">
              <p className="CommentRateTitle">
                مجموع امتیاز کابران به این دوره
              </p>

              <div className="CommentRateContentBox">
                {props.star !== null ? (
                  <div className="CommentRateSatrBox">
                    <Rating
                      name="simple-controlled"
                      value={props.star}
                      readOnly
                    />
                  </div>
                ) : (
                  <div className="CommentRateSatrBox">
                    <Rating name="simple-controlled" value={0} readOnly />
                  </div>
                )}

                <div className="CommentRateNumberBox">
                  {props.star !== null && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p className="CommentRateNumberTop">5</p>
                      <p className="CommentRateNumberTop">از</p>
                      <p className="CommentRateNumberTop">{props.star}</p>
                    </div>
                  )}
                  {props.num !== null && (
                    <div>
                      <p className="CommentRateNumberDown">( {props.num}رای)</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {props.linepro !== null &&
              props.linepro.length !== 0 &&
              props.num !== null &&
              props.star !== null && <div className="CommentLineMiddel"></div>}

            <div className="CommentRatebyline">
              {props.linepro !== null &&
                props.linepro.map((item, arr) => (
                  <div className="CommentRatebylineBox">
                    <p className="CommentRatebylinetxt">{item.name}</p>
                    <ProgressBar
                      key={arr}
                      bgcolor={"#fe7f2d"}
                      completed={item.amount}
                    />

                    <div className="CommentRatebylinestrongBox">
                      <p className="CommentRatebylinestrongtxt">
                        {item.amount > 50
                          ? "عالی"
                          : item.amount < 50
                          ? "ضعیف"
                          : "متوسط"}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Comment;

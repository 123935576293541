import React, {useState, useEffect} from 'react';
import {BtnSecondarybig} from '../../share/Btn';
import armeedarehrangi from '../../../img/arme-edareh-rangi-1.png';
import FormHandleChange from '../../hooks/FormHandleChange';
import user from '../../../img/svg/vectorpaint.svg';
import {API_URL} from '../../../constacts';
import useAxios from '@use-hooks/axios';
import '../../../css/main.scss';

function PhoneCurseTable(props) {
  const [degree, handleChange] = FormHandleChange(null);
  const [information, setInformation] = useState('');
  let test1 = 8000;
  let test2 = 1000000;
  var buttonStyle = {
    '&>*:hover': {
      background: '#efefef',
    },
  };
  useEffect(() => {
    SideBar.reFetch();
  }, []);
  const SideBar = useAxios({
    url: `${API_URL}/sidebar/order-sidebar/${props.id}/`,
    method: 'GET',
    options: {
      headers: {},
    },
    customHandler: (err, res) => {
      if (res) {
        // console.log(res.data);
        setInformation(res.data);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2.2rem',
      }}
    >
      <table className="PhoneCurseTable">
        {SideBar.response?.data && (
          <tbody>
            <tr>
              <th className=" PhoneCurseTable__firstRow">
                <div>
                  <i className="far fa-bookmark"></i>
                  <i class="fas fa-share-alt"></i>
                </div>
                <p>node js دوره آموزشی</p>
              </th>
            </tr>
            <tr>
              <th className="PhoneCurseTable__SecRow">
                <div>
                  <select onChange={handleChange} style={buttonStyle}>
                    <option value=""> نوع مدرک</option>
                    <option value="نوع1"> نوع1</option>
                    <option value="نوع2"> نوع2</option>
                  </select>
                </div>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div className="PhoneCurseTable__SecRow-right">
                    <p>سازمان فنی و حرفه‌ای</p>
                    <p>با اعتبار بین‌المللی</p>
                  </div>
                  <img src={armeedarehrangi} alt={armeedarehrangi} />
                </div>
              </th>
            </tr>
            <tr>
              <th className="PhoneCurseTable__ThirdRow">
                <p>:سختی دوره</p>
                <p>متوسط</p>
              </th>
            </tr>
            <tr>
              <th className="PhoneCurseTable__ForthRow">
                <div>
                  <div>
                    <i className="fas fa-star"></i>
                    <p>3.2</p>
                  </div>
                  <div className="PhoneCurseTable__Line"></div>
                  <div>
                    <img src={user} alt="user" />
                    <p>416</p>
                  </div>
                </div>
                <div>
                  <button>20%</button>
                  <p>{test2.toLocaleString()}</p>
                </div>
              </th>
            </tr>
            <tr>
              <th className="PhoneCurseTable__LastRow">
                <div>
                  <span>تومان</span>
                  {test1.toLocaleString()}
                </div>
                <div>
                  <p>{formatted} </p>
                  <i className="far fa-clock"></i>
                </div>
              </th>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
}

export default PhoneCurseTable;
const formatted = `${2}:${25}:${42}`;

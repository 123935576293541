import nodejs from "../img/courselist/Curses 3 - Node JS.png";
import python from "../img/courselist/course-2.png";
import swift from "../img/courselist/course-1.png";
import c from "../img/courselist/course-1.png";

export const coursese = [
  {
    id: 1,
    title: " C++ دوره آموزشی ",
    lan: "c++",
    image: c,
    rate: "3.2",
    level: "متوسط",
    time: "2:25:42",
    number: "416",
    price: 800000,
    offtag: false,
    new: false,
  },
  {
    id: 2,
    title: "(python) دوره آموزشی پایتون",
    lan: "python",
    image: python,
    rate: "3.2",
    level: "متوسط",
    time: "2:25:42",
    number: "416",
    price: 2000000,
    offtag: true,
    off: "20",
    new: false,
  },
  {
    id: 3,
    title: "nodejs دوره آموزشی ",
    lan: "nodejs",
    image: nodejs,
    rate: "3.2",
    level: "متوسط",
    time: "2:25:42",
    number: "416",
    price: 800000,
    offtag: false,
    new: true,
  },
  // {
  //   id: 3,
  //   title: "nodejs دوره آموزشی ",
  //   image: nodejs,
  //   rate: "3.2",
  //   level: "متوسط",
  //   time: "2:25:42",
  //   number: "416",
  //   price: 800000,
  //   offtag: false,
  //   new: true,
  // },
  // {
  //   id: 3,
  //   title: "nodejs دوره آموزشی ",
  //   image: nodejs,
  //   rate: "3.2",
  //   level: "متوسط",
  //   time: "2:25:42",
  //   number: "416",
  //   price: 800000,
  //   offtag: false,
  //   new: true,
  // },

  // {
  //   id: 3,
  //   title: " دوره آموزشی سوئیفت",
  //   image: swift,
  //   rate: "3.2",
  //   level: "متوسط",
  //   time: "2:25:42",
  //   number: "416",
  //   price: 800000,
  //   hashtag1: "Lorem imPuse",
  //   hashtag2: "Lorem",
  //   offtag: false,
  //   new: false,
  // },

  // {
  //   id: 4,
  //   title: " C++ دوره آموزشی ",
  //   image: c,
  //   rate: "3.2",
  //   level: "متوسط",
  //   time: "2:25:42",
  //   number: "416",
  //   price: 800000,
  //   hashtag1: "Lorem imPuse",
  //   hashtag2: "Lorem",
  //   offtag: false,
  //   new: false,
  // },
  // {
  //   id: 5,
  //   title: "nodejs دوره آموزشی ",
  //   image: nodejs,
  //   rate: "3.2",
  //   level: "متوسط",
  //   time: "2:25:42",
  //   number: "416",
  //   price: 800000,
  //   hashtag1: "Lorem imPuse",
  //   hashtag2: "Lorem",
  //   offtag: false,
  //   new: false,
  // },

  // {
  //   id: 6,
  //   title: " دوره آموزشی سوئیفت",
  //   image: swift,
  //   rate: "3.2",
  //   level: "متوسط",
  //   time: "2:25:42",
  //   number: "416",
  //   price: 800000,
  //   hashtag1: "Lorem imPuse",
  //   hashtag2: "Lorem",
  //   offtag: false,
  //   new: false,
  // },

  // {
  //   id: 7,
  //   title: " C++ دوره آموزشی ",
  //   image: c,
  //   rate: "3.2",
  //   level: "متوسط",
  //   time: "2:25:42",
  //   number: "416",
  //   price: 800000,
  //   hashtag1: "Lorem imPuse",
  //   hashtag2: "Lorem",
  //   offtag: false,
  //   new: false,
  // },
  // {
  //   id: 8,
  //   title: "nodejs دوره آموزشی ",
  //   image: nodejs,
  //   rate: "3.2",
  //   level: "متوسط",
  //   time: "2:25:42",
  //   number: "416",
  //   price: 800000,
  //   hashtag1: "Lorem imPuse",
  //   hashtag2: "Lorem",
  //   offtag: false,
  //   new: false,
  // },

  // {
  //   id: 9,
  //   title: " دوره آموزشی سوئیفت",
  //   image: swift,
  //   rate: "3.2",
  //   level: "متوسط",
  //   time: "2:25:42",
  //   number: "416",
  //   price: 800000,
  //   hashtag1: "Lorem imPuse",
  //   hashtag2: "Lorem",
  //   offtag: false,
  //   new: false,
  // },
];

import React, { useState, useEffect, useContext } from "react";
import QuizDashboard from "../components/Quiz/QuizDashboard";
import UseWindowSize from "../Sizes/UseWindowSize";
import PhoneNavigation from "../components/share/PhoneNavigation";
import Navigation from "../components/share/Navigation";
import { API_URL } from "../constacts";
import { useAuth } from "../context/AuthContext";
import { useUser } from "../context/UserContext";
import { useList } from "../context/SeasonList";
import Loader from "../components/Common/Loader";
import { GetContentInoContext } from "../context/GetContentInoContext";
import { ContentSidebarContext } from "../context/ContentSidebarContext";
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { textState, textState1 } from "../Recoil/RecoilState";
import useAxios from "@use-hooks/axios";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

function Quiz(routeProps) {
  const [seasonlist, setseasonlist] = useState(null);
  const [contentid, setContentid] = useRecoilState(textState);
  const [seasonlist1, setseasonlist1] = useState(null);
  const [errornotpass, setErrornotpass] = useState(null);
  const [loading, setLoading] = useState(true);
  const [couid, setCouid] = useState(0);
  const [id, setId] = useState("");
  const [quizid, setQuizid] = useState("");
  const [code, setCode] = useState("");
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    setId(location.state.id);
    setQuizid(location.state.quizid);
    setCode(location.state.code);
  }, [location]);
  const windowSize = UseWindowSize();
  const { testtt, userDispatch } = useUser();
  // const [list, setList] = useRecoilState(textState1);

  const { token } = useAuth();
  useEffect(() => {
    Quiztest.reFetch();
  }, []);
  const [contentInfo, setcontentInfo] = useState(null);
  const backtocontent = () => {
    // setContentid(cid);
    history.push({
      pathname: `/courcontent`,
      state: {
        id: location.state.id,
      },
    });
  };

  const Quiztest = useAxios({
    url: `${API_URL}/quiz/${quizid}/get/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log("Quiztest", res.data);
        setCouid(res.data.course);
        setLoading(false);
      }
      if (err) {
        if (
          err.response.data.detail ===
          "You didnt pass former contents and seasons quizzes"
        ) {
          setErrornotpass("شما درس های این فصل را پاس نکردید");
          setLoading(false);
        }
        console.log(err.response);
      }
    },
  });
  console.log("quizid", id);
  return (
    <div>
      {!loading ? (
        <div>
          {errornotpass === null ? (
            <QuizDashboard />
          ) : (
            <div
              style={{
                fontSize: "27px",
                marginTop: "120px",
                color: "#e23c3c",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p>{errornotpass}</p>
              <NavLink
                to={{
                  pathname: `/courcontent`,
                  state: {
                    id: contentid,
                  },
                }}
              >
                <button className="BtnPrimary ">برگشت به درس</button>{" "}
              </NavLink>
            </div>
          )}
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <Loader />
        </div>
      )}
    </div>
  );
}

export default Quiz;

import React, { useContext, useState, useRef, useEffect } from "react";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { useAuth } from "../../context/AuthContext";
import { GetUserInfoContext } from "../../context/GetUserInfoContext";
import { useForm, Controller } from "react-hook-form";
import { useFileUpload } from "use-file-upload";
import defaultprofile from "../../img/profile/Group 1389.svg";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-multi-date-picker";

function UserDetails() {
  const phoneError = () => {
    toast.error("لطفا برای شماره ی تلفن فرمت درست را وارد کنید", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const successtoast = () => {
    toast.success("اطلاعات شما با موفقیت ثبت شد", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  
  const [info, setInfo] = useState(null);
  const { userinfo, getUserInfo } = useContext(GetUserInfoContext);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const reference = useRef(null);
  const [files, selectFiles] = useFileUpload();
  const [fileList, setfileList] = useState([]);
  const { authDispatch, token } = useAuth();
  const userdetail = useAxios({
    url: userinfo !== null ? `${API_URL}/profile/` : null,
    method: "PUT",
    options: {
      data: info,
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    trigger:info,
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        successtoast();
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  useEffect(() => {
    getUserInfo.reFetch();
  }, []);
  // const onSubmit = data =>{
  //    console.log(data)
  //   };
  const [inputValue, setInputValue] = useState("");

  function formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early

    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    // if (phoneNumberLength < 7) {
    //   return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    // }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    // return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    //   3,
    //   6
    // )}-${phoneNumber.slice(6, 10)}`;
  }
  const handleInput = (e) => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);

    // we'll set the input value using our setInputValue
    setInputValue(formattedPhoneNumber);
  };


  const onSubmit = (data) => {
    let datee = data.birth_date.format().replace(/\//g, "-");
    let newState = Object.assign({}, data);
    newState.birth_date = datee;
    var formData = new FormData();
    if (data.cover.length !== 0) {
      formData.append("cover", data.cover[0], data.cover[0].name);
    }
    formData.append("first_name", data.first_name);
    formData.append("username", userinfo.username);
    formData.append("bio", "");
    formData.append("email", userinfo.email);
    formData.append("last_name", data.last_name);
    formData.append("phone_number", data.phone_number);
    formData.append("gender", data.gender);
    formData.append("location", data.location);
    formData.append("birth_date", newState.birth_date);
    console.log(formData);
    setInfo(formData);
   
  };
  
  const [imagePreviewUrl, setstateimagePreviewUrl] = useState("");
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setstateimagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
    // console.log(URL.createObjectURL(e.target.files[0]));
  };
  //Unhandled Rejection (TypeError): Failed to execute 'createObjectURL' on 'URL': Overload resolution failed.
  let $imagePreview = null;
  if (imagePreviewUrl) {
    $imagePreview = <img className="imagePreview" src={imagePreviewUrl} />;
  } else {
    $imagePreview = <img className="imagePreview" src={defaultprofile} />;
  }

  return (
    <div className="UserDetails">
      <h1>مشخصات فردی</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="UserDetails--uploadcover--container">
          {$imagePreview}

          <div className="UserDetails--uploadcoverbox">
            <div className="UserDetails--uploadcover">
              <input
                type="file"
                accept=".png , .jpg"
                {...register("cover")}
                className="testuploadfileDashboard--input "
                //onChange={handleImageChange}
                //value={field.value}
                id="image__cover--perview"
                //  onChange={(e) => field.onChange(e)}
                onInput={handleImageChange}
              />
              <label
                for="image__cover--perview"
                className="image__cover--perview-label"
              >
                آپلود عکس کاربری
              </label>
            </div>
          </div>
        </div>
        <div className="UserDetails__dataTopBox">
          <div className="form-input-div field">
            <input
              type="text"
              id="popupform__right-login-firstname"
              {...register("first_name")}
              required
              class={`required form-input field-input ${
                errors.first_name && "error-border"
              }`}
            />
            <label
              for="popupform__right-login-firstname"
              className="form-label field-label UserDetails__label"
            >
              <span>نام </span>
            </label>
          </div>
          <div className="form-input-div field">
            <input
              type="text"
              id="popupform__right-login-lastname"
              {...register("last_name")}
              required
              class={`required form-input field-input ${
                errors.last_name && "error-border"
              }`}
            />
            <label
              for="popupform__right-login-lastname"
              className="form-label field-label UserDetails__label"
            >
              <span>نام خانوادگی</span>
            </label>
          </div>
        </div>

        <div className="UserDetails__dataTopBox">
          {/* <div className="form-input-div field">
            <input
              type="email"
              id="popupform__right-login-email"
              {...register("email", {})}
              required
              class={`required form-input field-input ${
                errors.email && "error-border"
              }`}
            />
            <label
              for="popupform__right-login-email"
              className="form-label field-label UserDetails__label"
            >
              <span>ایمیل </span>
            </label>
          </div> */}
          <div className="UserDetails__selectinput">
            <select defaultValue="" {...register("gender")}>
              <option value="">جنسیت</option>
              <option value="female">خانوم</option>
              <option value="male">آقا</option>
              <option value="unknown">ناشناس</option>
            </select>
          </div>
          <div className="form-input-div field">
            <input
              type="tel"
              id="popupform__right-login-phone_number	"
              {...register("phone_number", {
                // validate: validatetexterea,
                // pattern: phoneRegExp,
                // persianNumbers: fixNumbers,
              })}
              value={inputValue}
              onInput={(e) => handleInput(e)}
              required
              class={`required form-input field-input ${
                errors.phone_number && "error-border"
              }`}
            />
            <label
              for="popupform__right-login-phone_number"
              className="form-label field-label UserDetails__label"
            >
              <span>شماره موبایل </span>
            </label>
          </div>
        </div>

        <div className="UserDetails__dataTopBox">
          {/* <div className="form-input-div field">
            <input
              type="text"
              id="popupform__right-login-birthday"
              {...register("birth_date")}
              required
              class={`required form-input field-input ${
                errors.birth_date && "error-border"
              }`}
            />
            <label
              for="popupform__right-login-birthday"
              className="form-label field-label UserDetails__label"
            >
              <span>تاریخ تولد</span>
            </label>
          </div> */}
          <div className="form-input-div field">
            <Controller
              name="birth_date"
              control={control}
              render={({ field }) => (
                <DatePicker
                  animation
                  calendar="persian"
                  locale="fa"
                  calendarPosition="auto-right"
                  value={field.value}
                  inputClass="required form-input field-input"
                  required
                  onChange={(e) => field.onChange(e)}
                  placeholder='تاریخ تولد'
                />
              )}
            />
          
          </div>
          <div className="form-input-div field">
            <input
              type="text"
              name="location"
              id="popupform__right-login-location"
              {...register("location")}
              class={`required form-input field-input ${
                errors.location && "error-border"
              }`}
              required
            />
            <label
              for="popupform__right-login-location"
              className="form-label field-label UserDetails__label"
            >
              <span> محل زندگی</span>
            </label>
          </div>
        </div>

        <div className="UserDetails__dataTopBox"></div>
        <div className="UserDetails__BtnSecondary">
          <button type="submit" className="BtnSecondary">
            ذخیره اطلاعات
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default UserDetails;
//phone_number: ["Phone number must be entered in the format: '+999999999'."]

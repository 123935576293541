import React, { useState, useEffect } from "react";
import ExampleDashboard from "../components/Example/ExampleDashboard";
import UseWindowSize from "../Sizes/UseWindowSize";
import PhoneNavigation from "../components/share/PhoneNavigation";
import Navigation from "../components/share/Navigation";
import { useLocation } from "react-router-dom";
import useAxios from "@use-hooks/axios";
import { useAuth } from "../context/AuthContext";
import { API_URL } from "../constacts";

function ExampleMain() {
  const [id, setId] = useState("");
  const [exmpid, setExmpid] = useState("");
  const [lan, setLan] = useState("");
  const location = useLocation();
  useEffect(() => {
    setId(location.state.id);
    setExmpid(location.state.exmpid);
    getLan.reFetch();
  }, [location]);
  const { token } = useAuth();

  const windowSize = UseWindowSize();
  const getLan = useAxios({
    url: `${API_URL}/language/${id}/get/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        // console.log(res.data);
       setLan(res.data.language);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  return (
    <div>
      <ExampleDashboard id={id} exmpid={exmpid} lan={lan} />
    </div>
  );
}

export default ExampleMain;

import React from "react";
import box from "../../../img/phone/Union 1.png";
import teacher from "../../../img/courselist/12790.png";
import "../../../css/main.scss";

function PhoneTeacherBox({ name, last, txt, pic }) {
  return (
    <div className="PhoneTeacher">
      <div
        style={{ position: "relative", textAlign: "center" }}
        className="PhoneTeacherBox__container"
      >
        <div className="half__circle"></div>
        <img src={pic} alt={name} className="PhoneTeacher__profile" />
        <div className="PhoneTeacher__info">
          <h5>
            :{name}
            {last} گفته
          </h5>
          <p>{txt}</p>
        </div>
      </div>
    </div>
  );
}

export default PhoneTeacherBox;

export const listReducer = (state, action) => {
  switch (action.type) {
    case 'LISTLOGIN':
      window.localStorage.setItem('list', JSON.stringify(action.list));
      return action.list;
    case 'LISTCLEAR':
      window.localStorage.removeItem('list');
      return null;
    default:
      return state;
  }
};

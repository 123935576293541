import React from "react";
import { NavLink } from "react-router-dom";
import "../../css/main.scss";

const Menu = () => {
  return (
    <div>
      <ul className="list nav__list">
        <li className="nav__list--item active">
          <NavLink
            exact
            activeClassName="is-active"
            to="/"
            className="nav__list--link"
          >
            خانه
          </NavLink>
        </li>
        <li className="nav__list--item">
          <NavLink
            exact
            activeClassName="is-active"
            to="/CourseList"
            className="nav__list--link"
          >
            آموزش
          </NavLink>
        </li>
        <li className="nav__list--item">
          <a href="#" className="nav__list--link">
            بلاگ
          </a>
        </li>
        <li className="nav__list--item">
          <a href="#" className="nav__list--link">
            سوالات متداول
          </a>
        </li>
      </ul>
    </div>
  );
};
export default Menu;

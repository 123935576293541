import React from "react";


const ProgressBar = (props) => {
  const {bgcolor, completed} = props;

  const containerStyles = {
    height: 2,
    width: '18rem',
    backgroundColor: 'rgba(112, 112, 112, 0.5)',
    borderRadius: 50,
    direction: 'rtl',
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right',
  };

  const labelStyles = {
    padding: 5,
    color: 'white',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        {/* <span style={labelStyles}>{`${completed}%`}</span> */}
      </div>
    </div>
  );
};

export default ProgressBar;

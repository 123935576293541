/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import javalogo from '../../../img/courseinfo/JavaScript-logo.png';
import {Properties} from '../../Common/Properties';
import {NavLink} from 'react-router-dom';
import PhoneNavigation from '../../share/PhoneNavigation';
import '../../../css/main.scss';

const curseinfodescription = [
  {
    img: javalogo,
    title: 'دوره آموزشی',
    titr: 'جاوا اسکریپت',
  },
];
const showCaseTextList = [
  {text: 'لورم ایپسوم یا طرح‌نما به متنی آزمایشی ', id: 1},
  {text: 'لورم ایپسوم یا طرح‌نما به متنی آزمایشی', id: 2},
  {text: 'دلورم ایپسوم یا طرح‌نما به متنی آزمایشی', id: 3},
  {text: 'الورم ایپسوم یا طرح‌نما به متنی آزمایشی', id: 4},
];
function PhoneCourseHead({title, properties, picture}) {
  return (
    <div>

      <div
        className="PhoneCourseHead__head-Box"
        // style={{ position: "relative", top: "8rem" }}
      >
        <div className="PhoneCourseHead__head__navBox">
          <div className="PhoneCourseHead__headRed-dot"> </div>
          <ul className="PhoneCourseHead__headTitleBox">
            <li className="PhoneCourseHead__headTitle">
              <p className="PhoneCourseHead__headTitlTxt">خانه</p>
            </li>
            <li className="PhoneCourseHead__headTitle">
              <p className="PhoneCourseHead__headTitlTxt">آموزش</p>
            </li>
            <li className="PhoneCourseHead__headTitle">
              <p className="PhoneCourseHead__headTitlTxt">{title}</p>
            </li>
          </ul>
        </div>
        <div className="PhoneCourseHead__head__contentBox">
          <>
            <img
              src={picture}
              alt={picture}
              className="PhoneCourseHead__javaico"
            ></img>
          </>

          <div className="PhoneCourseHead__head__titleBox">
            <h2 className="PhoneCourseHead__head__title">دوره آموزشی</h2>
            <NavLink
              exact
              to="/courcontent"
              className="PhoneCourseHead__head__titre"
            >
              {title}
            </NavLink>
          </div>
        </div>
        <div className="PhoneCourseHead__head__propertyBox ">
          <svg
            className="PhoneCourseHead__head__firstLine"
            viewPort="0 0 200 300"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line className="stroke" x1="20" y1="30" x2="650" y2="30"></line>
          </svg>
          <p className="PhoneCourseHead__head__propertyTitle">
            ویژگی های این دوره
          </p>
          <svg
            className="PhoneCourseHead__head__SecLine"
            viewPort="0 0 200 300"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line className="stroke" x1="10" y1="30" x2="650" y2="30"></line>
          </svg>
        </div>
        <div className="PhoneCourseHead__head__propertyContent-box">
          <ul className="BoxGrid">
            {properties.map((text) => (
              <Properties key={text.id} text={text.prop} className="svgblack" />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PhoneCourseHead;

import React, {useState, useEffect, useMemo} from 'react';
import '../../css/main.scss';
import CourseDiv from '../share/CourseDiv';

const ShowCourses = ({information,willing}) => {
  return useMemo(() => 
 { return (
    <div className="showcourses">
      {information.map((c, arr) => (
        <>
          <CourseDiv willing={willing} course={c} c />
        </>
      ))}
    </div>
  )}
  , [information])
  
};
export default ShowCourses;

import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import LogIn from "./layout/LogIn";
import Home from "./layout/Home";

const UnAuthenticated = () => {
  return (
    <Switch>
      {/* <Route exact path="/" render={() => <Redirect to="/login" />} /> */}
      {/* <Route path="/login" component={Login} exact /> */}

      <Route exact path="/">
        {() => (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        )}
      </Route>
      <Route exact path="/logIn" component={()=><LogIn form={false} />}  />
      <Route exact path="/signin" component={() => <LogIn form={true} />} />

    </Switch>
  );
};

export default UnAuthenticated;

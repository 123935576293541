import React, { useState } from "react";
import FormHandleChange from "../hooks/FormHandleChange";
import uuid from "uuid/dist/v1";
import "../../css/main.scss";
import { BtnSecondarybig } from "./Btn";
import Circle from "../../img/svg/Circle.svg";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { API_URL } from "../../constacts";
import "react-toastify/dist/ReactToastify.css";
import ClipLoader from "react-spinners/ClipLoader";
import { useForm } from "react-hook-form";
import useAxios from "@use-hooks/axios";
import { css } from "@emotion/core";
import { ToastContainer, toast } from "react-toastify";

function FeedbackForm() {
  let [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState();
  const sendfeedbacksuccess = () => {
    toast.success('پیام شما با موفقیت ثبت شد', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const postfeedback = useAxios({
    url: `${API_URL}/contact/create/`,
    method: "POST",
    options: {
      data: feedback,
    },
    // trigger: resources,
    customHandler: (err, res) => {
      if (res) {
        console.log(res);
        reset()
        setLoading(false);
        sendfeedbacksuccess()
       
      }
      if (err) {
       
       
      }
    },
  });
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: white;
  `;
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm();
  const onSubmit = (info) => {
    setLoading(true);
    setFeedback(info);
    postfeedback.reFetch()
  };
  var Options = [
    { title: "موضوع" },
    { title: "دسته1" },
    { title: "دسته دو" },
    { title: "دسته 3" },
  ];

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: 200,
      },
    },
  }));
  return (
    <section className="feedbacksection">
      <div className="heading center header_topMargin">
        <h3 className="h-3">لطفا بهمون بازخورد دهید</h3>
      </div>

      <form
         onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form__display">
          <div className="footer__form">
            <div className=" footerInput form-input-div field">
              <input
                type="text"
                name="name"
                id="popupform__right-login-name"
               
                required
                {...register("name", { required: true })}
                class="required form-input field-input"
              />
              <label
                for="popupform__right-login-name"
                className="form-label field-label"
              >
                <span>نام </span>
              </label>
            </div>
            <div className=" footerInput form-input-div field">
              <input
                type="email"
                name="email"
                id="popupform__right-login-email"
                {...register("email", { required: true })}

                required
                class="required form-input field-input"
              />
              <label
                for="popupform__right-login-email"
                className="form-label field-label"
              >
                <span>ایمیل </span>
              </label>
            </div>

            <div className=" footerInput form-input-div field">
              <input
                type="text"
                name="title"
                id="popupform__right-login-title"
                {...register("title", { required: true })}

                required
                class="required form-input field-input"
              />
              <label
                for="popupform__right-login-title"
                className="form-label field-label"
              >
                <span>عنوان </span>
              </label>
            </div>
            <div className=" footerInput form-input-div field">
              {/* <select
                value={group}
                className="footer__form__input footer__form__input-2 "
                onChange={updateGroup}
                defaultValue=""
                id="popupform__right-login-group"
                name="group"
                class="required form-input field-input"
              >
                {Options.map((i, index) => (
                  <option
                    className={
                      index === 0 ? "select__center1" : "select__center"
                    }
                  >
                    {i.title}{" "}
                  </option>
                ))}
              </select>
              <label
                for="popupform__right-login-group"
                className="form-label field-label"
              >
                <span>موضوع </span>
              </label> */}
              <select  className="feedbackForm__select " 
             {...register("subject", { required: true })}
                              >
                {Options.map((i, index) => (
                  <option className="select__center">{i.title} </option>
                ))}
              </select>
            </div>
            <div className="text-area-form-input footerInput form-input-div field">
              <textarea
                type="text"
                name="commenet"
                id="popupform__right-login-caption"
                {...register("message", { required: true })}
                required
                class="required text-area-form form-input field-input textarea--footer"
                rows="4"
                cols="50"
                
              />
              <label
                for="popupform__right-login-caption"
                className=" required form-label field-label "
              >
                <span>گوشمون با شماست </span>
              </label>
            </div>
          </div>
          <div className="form__btn">
            <BtnSecondarybig className="FeedbackFormBtn ">
             
              {loading ? (
                <ClipLoader
                  color="#ffffff"
                  loading={loading}
                  css={override}
                  size={15}
                />
              ) : (
                <p>ارسال برای ما</p>
              )}
            </BtnSecondarybig>
          </div>
        </div>
      </form>
      <ToastContainer />

    </section>
  );
}

export default FeedbackForm;

import React, { useState, useEffect, useContext } from "react";
import CodeeditorWithTest from "./CodeeditorWithTest";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { useAuth } from "../../context/AuthContext";
import { useLocation } from "react-router-dom";

function Test(props) {
  const [id, setId] = useState("");
  const [quizid, setQuizid] = useState("");
  const location = useLocation();
  const [lan, setLan] = useState("");
  const { token } = useAuth();
  useEffect(() => {
    setId(location.state.id);
    setQuizid(location.state.quizid);
  }, [location]);

  useEffect(() => {
    if (location) {
      getLan.reFetch();
    }
    // checkUserPassSeason.reFetch();
  }, []);
  const getLan = useAxios({
    url: `${API_URL}/language/${id}/get/`,
    method: "GET",
    options: {
      headers: {
        Authorization: `JWT ${token}`,
      },
    },
    customHandler: (err, res) => {
      if (res) {
        console.log(res.data);
        res.data.language === "py"
          ? setLan("python")
          : res.data.language === "js"
          ? setLan("javascript")
          : res.data.language === "go"
          ? setLan("golang")
          : res.data.language === "c"
          ? setLan("c_cpp")
          : res.data.language === "cpp"
          ? setLan("c_cpp")
          : setLan(res.data.language);
      }
      if (err) {
        console.log(err.response);
      }
    },
  });
  return (
    <div className="Example">
      <CodeeditorWithTest name="code" code={props.code} lan={lan} id={id} />
    </div>
  );
}

export default Test;

import React, {createContext, useState,useEffect} from 'react';
import useAxios from '@use-hooks/axios';
import {API_URL} from '../constacts';
import { useAuth } from '../context/AuthContext';
import { useLocation } from 'react-router-dom';
export const DraftContext = createContext();

const DraftCommentProvider = (props) => {
    const {token} = useAuth();
    const [draftCM, setDraftCM] = useState([])
    const [id, setId] = useState("");
    const location = useLocation();
    useEffect(() => {
      setId(location.state.id);
    }, [location]);    const getDraftcomment = useAxios({
        url: `${API_URL}/comment/course_comments/${id}/draft/`,
        method: 'GET',
        options: {
          headers: {
            Authorization: `JWT ${token}`,
          },
        },
        customHandler: (err, res) => {
          if (res) {
            setDraftCM(res.data.results);
            console.log('resdraft',res.data.results);

          }
          if (err) {
            console.log('errdraft',err.response);
          }
        },
      });
 
  return (
    <DraftContext.Provider 
    value={{getDraftcomment,draftCM}}
    >
      {props.children}
    </DraftContext.Provider>
  );
};


export {DraftCommentProvider};
import React from 'react';
import Test from '../components/codeeditor/Test';
import UseWindowSize from '../Sizes/UseWindowSize';
import PhoneNavigation from '../components/share/PhoneNavigation';
import Navigation from '../components/share/Navigation';

function Codeeditor() {
  const windowSize = UseWindowSize();

  return (
    <div>

      <Test code={true} />
    </div>
  );
}

export default Codeeditor;

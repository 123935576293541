import React, { useContext, useState, useEffect } from "react";
import Menu from "./menu";
import "../../css/main.scss";
import Logo from "../../img/logo web.svg";
import { NavLink } from "react-router-dom";
import { BtnSecondary } from "../share/Btn";
import { useAuth } from "../../context/AuthContext";
import { useUser } from "../../context/UserContext";
import { useList } from "../../context/SeasonList";
import cardIcon from "../../img/menu-profile/Package.svg";
import ringIcon from "../../img/menu-profile/Ring.svg";
import profileIcon from "../../img/defaultprofile.png";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../constacts";
import useAxios from "@use-hooks/axios";
import { GetUserInfoContext } from "../../context/GetUserInfoContext";
const Navigation = (props) => {
  const { authDispatch, token, authrefreshDispatch, refreshtoken } = useAuth();
  const { testtt } = useUser();
  const { userinfo, getUserInfo, summery, info } =
    useContext(GetUserInfoContext);
  const { listDispatch, list } = useList();
  let history = useHistory();
  const logoutpage = useAxios({
    url: `${API_URL}/logout/`,
    method: "POST",
    options: {
      headers: {},
    },
    // trigger: resources,
    customHandler: (err, res) => {
      if (res) {
        console.log("res", res);
        authDispatch({ type: "LOGOUT", token });
        authDispatch({ type: "LOGOUTREFRESH", testtt });
        authrefreshDispatch({ type: "LOGOUT", refreshtoken });
        // listDispatch({type: 'LISTCLEAR', list});
        history.push("/");
      }
      if (err) {
        console.log("error", err);
      }
    },
  });
  const logOut = () => {
    logoutpage.reFetch();
  };
  useEffect(() => {
    if (token !== null) {
      getUserInfo.reFetch();
      summery.reFetch();
    }
  }, [, token]);
  return (
    <div className="">
      <div className="container">
        <nav className="navigation navigation__Back">
          <div className="nav">
            {!token ? (
              <>
                <NavLink
                  exact
                  activeClassName="is-active"
                  to="/signin"
                  className=""
                >
                  <BtnSecondary className="signup-btn">ثبت نام</BtnSecondary>
                </NavLink>
                <NavLink
                  exact
                  activeClassName="is-active"
                  to="/logIn"
                  className="nav__btn sign-up"
                >
                  ورود
                </NavLink>{" "}
              </>
            ) : (
              <div className="Profile__box">
                {summery.response?.data && (
                  <NavLink to="/card" className="navIcon">
                    <div>
                      <img
                        src={cardIcon}
                        alt={cardIcon}
                        className="Profile__box--icon profile__icon cardIcon "
                      />
                      {info !== 0 && (
                        <div className="cardIcon__Notification center">
                          {" "}
                          <p>{info}</p>
                        </div>
                      )}
                    </div>
                  </NavLink>
                )}

                <img
                  src={ringIcon}
                  className="Profile__box--icon profile__icon ringIcon"
                  alt={ringIcon}
                />
                <div className="Profile__box-1">
                  {userinfo !== null ? (
                    <div className="Profile__box--img">
                      {userinfo.cover !== null ? (
                        <img
                          className="Profile__box--icon"
                          src={userinfo.cover}
                          alt={userinfo.cover}
                        />
                      ) : (
                        <img
                          className="Profile__box--icon"
                          src={profileIcon}
                          alt={profileIcon}
                        />
                      )}
                    </div>
                  ) : null}
                  <div className="Profile__submenu">
                    <ul>
                      <li>
                        <NavLink to="/user_setting">پروفایل</NavLink>
                      </li>
                      <li>
                        <NavLink to="/mycourse"> دوره های من</NavLink>
                      </li>
                      <li>
                        <NavLink to="/myfavecourse">دروس مورد علاقه</NavLink>
                      </li>

                      <li onClick={logOut}>خروج</li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
            <a className="nav__teacher">بخش مدرسان </a>
            {props.navsearch ? (
              <div className="search">
                <i className="fas fa-search"></i>
              </div>
            ) : null}
            <Menu />
          </div>
          <div className="navigation__logo">
            <img src={Logo} alt="logo" />
          </div>
        </nav>
      </div>
    </div>
  );
};
export default Navigation;
